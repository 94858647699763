import { Button } from "@mui/material";

export const getHeaderConfig = (editVendor, setDeleteDetails) => {
  const headers = [
    {
      label: "Item Code",
      id: "itemCode"
    },
    {
      label: "Item Description",
      id: "itemDescription"
    },
    {
      label: "Case Size",
      id: "caseSize"
    },
    {
      label: "UOM",
      id: "uom"
    },
    {
      label: "HSN Code",
      id: "hsnCode"
    },
    {
      label: "GST Rate",
      id: "gstRate"
    },
    {
      label: "Base Price",
      id: "basePrice",
      render: (data) => {
        return data.basePrice ? data.basePrice.toFixed(2) : "0.00";
      }
    },
    {
      label: "Full Price",
      id: "fullPrice",
      render: (data) => {
        return data.fullPrice ? data.fullPrice.toFixed(2) : "0.00";
      }
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        return (
          <>
            <Button
              variant='text'
              // disabled={!isUpdateAllowed("master_data")}
              onClick={() => editVendor(data, "EDIT")}
            >
              Edit
            </Button>
            <Button
              variant='text'
              // disabled={!isDeleteAllowed("master_data")}
              onClick={() => setDeleteDetails(data)}
            >
              Delete
            </Button>
          </>
        );
      }
    }
  ];
  return headers;
};

export const populateStage = (stages, activeStep) => {
  return stages.map((element) => {
    if (element.stageName === activeStep.stageName) {
      return {
        stageName: activeStep.stageName,
        approverRole: activeStep.approverRole.value
      };
    }

    return {
      stageName: element.stageName,
      approverRole: element.approverRole.value
        ? element.approverRole.value
        : element.approverRole
    };
  });
};

export const APPROVAL_ROLES_PO = [
  { label: "ADMIN", value: "ADMIN" },
  { label: "SMALLWARE_HOD", value: "SMALLWARE_HOD" },
  { label: "FINANCE", value: "FINANCE" },
  { label: "FINANCE_HOD", value: "FINANCE_HOD" }
];

export const APPROVAL_ROLES_GRN = [
  { label: "ADMIN", value: "ADMIN" },
  { label: "SMALLWARE_HOD", value: "SMALLWARE_HOD" },
  { label: "FINANCE", value: "FINANCE" },
  { label: "FINANCE_HOD", value: "FINANCE_HOD" }
];

//observer 
