import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import "./index.scss";
import ItemList from "./item-list";
import ApprovalConfig from "./approval-config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const MasterData = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='rm-pt budget-list'>
      <div className='tabs-section'>
        <Box>
          <Tabs variant='scrollable' value={value} onChange={handleChange}>
            <Tab label='Items' />
            <Tab label='PO Approval Config' />
            <Tab label='GRN Approval Config' />
          </Tabs>
        </Box>
      </div>
      <TabPanel value={value} index={0}>
        <ItemList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ApprovalConfig docType="SMALLWARE_PO" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApprovalConfig docType="SMALLWARE_GRN" />
      </TabPanel>
    </div>
  );
};

export default MasterData;
