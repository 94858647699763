import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FINANCE_HOSTNAME,
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { createPayloadMapper, mapPOResponseToFormDetails } from "./helper";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import "./index.scss";
import { AddCircle, ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import AddItemModal from "./add-item-modal";
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from "../../../../common-utilities/utils";
import { toast } from "react-toastify";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";

import {
  getPOLineItemAmount,
  getAdvanceAmount,
  createPOavailableBudgetAmount,
  getPOAmountWithoutTax,
  PO_ITEM_MODEL,
} from "../helper";
import { getOfflineData } from "../../../utils/offline-services";
import {
  getUploadAttachmentQueryParams,
  uploadAttachmentTypes,
} from "../../../utils/attachments";
import { setProperty } from "../../../../common-utilities/utils/misc";

const EditPO = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formDetails, setFormDetails] = useState({});
  const [poItems, setPoItems] = useState({});
  const [vendors, setVendors] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [customSites, setCustomSites] = useState([]);
  const [selectedLineItemIds, setSelectedLineItemIds] = useState([]);
  const [loadedPO, setLoadedPO] = useState(false);
  const { vendor = {}, budgetDetails = {} } = formDetails;
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [poDetails, setPoDetails] = useState({});
  const user = getOfflineData("user");
  const [config, setConfig] = useState({
    isItemDeleted: false,
  });

  const { lineItems = [], siteCode, siteName = "" } = budgetDetails;

  useEffect(() => {
    loadData();    
  }, []);

  useEffect(() => {
    if (
      departmentList.length > 0 &&
      vendors.length > 0 &&
      !formDetails.poType &&
      !loadedPO
    ) {
      setLoadedPO(true);
      invokeApi(
        HTTP_METHODS.GET,
        `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}`,
        null,
        {
          page: 1,
          limit: 10,
          _id: id,
        }
      )
        .then((response) => {
          if (response.message) {
          } else if (response.results.length > 0) {
            const {
              mappedResponse,
              mappedAutoCompleteInputValues,
              poItems = {},
            } = mapPOResponseToFormDetails(
              response.results[0],
              departmentList,
              vendors
            );

            setFormDetails(mappedResponse);
            setAutocompleteInputValues(mappedAutoCompleteInputValues);
            setSelectedLineItemIds(mappedResponse.selectedLineItemIds);
            setPoItems(poItems);
            setPoDetails(response.results[0]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [departmentList, vendors]);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${FINANCE_REST_URLS.DEPARTMENTS}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setDepartmentList(response || []);
        }
      })
      .catch((err) => {});

    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`,
      null,
      {
        page: 1,
        limit: 1000,
        additionalFields: "paymentTerms",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setVendors(response || []);
        }
      })
      .catch((err) => {});

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response && response.results.length > 0) {
          setCustomSites(
            response.results.map((r) => {
              return {
                id: r.id,
                restaurantName: `${r.name} | ${r.address}`,
              };
            }) || []
          );
        }
      })
      .catch((err) => {});

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.COMPANY}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setCompanyList(response.results || []);
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    let payload = createPayloadMapper(
      formDetails,
      poItems,
      budgetDetails,
      formDetails.poType,
      attachments
    );
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}/${id}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to update purchase order ");
        } else {
          toast.info("PO Details updated successfully");
        }
      })
      .catch((err) => {});
  };

  const inputChange = (event) => {
    setFormDetails({ ...formDetails, [event.target.name]: event.target.value });
  };

  const onDropDownChange = (key, value) => {
    let additionalDetails = {};
    switch (key) {
      case "vendor":
        additionalDetails.paymentTerms = `${value?.paymentTerms || ""}`;
        break;
      default:
        break;
    }

    setFormDetails({ ...formDetails, [key]: value, ...additionalDetails });
  };

  const updateItem = (item, index) => {
    poItems[item.lineItemId][index] = {
      ...poItems[index],
      ...item,
    };
    setPoItems({ ...poItems });
  };

  const onDeleteRow = (id, index) => {
    setProperty("isItemDeleted", true, setConfig);
    poItems[id].splice(index, 1);
    setPoItems({ ...poItems });
    setTimeout(() => {
      setProperty("isItemDeleted", false, setConfig);
    }, 200);
  };

  const onRowAdd = (lineItem) => {
    let items = poItems[lineItem.id] || [];
    poItems[lineItem.id] = [...items, PO_ITEM_MODEL];
    setPoItems({ ...poItems });
  };

  const getAddressList = () => {
    if (customSites.length > 0) {
      return customSites;
    } else {
      return [];
    }
  };

  const onDelete = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  const sendForApproval = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SEND_FOR_APPROVAL}${id}`,
      null
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to send for approval");
        } else {
          toast.info("PO sent for approval");
          navigate(`/subway-finance/purchase-order/${id}`);
        }
      })
      .catch((err) => {});
  };

  const resendForApproval = () => {
    const { approvalId = {} } = formDetails;
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RESEND_FOR_APPROVAL}${approvalId.id}`,
      null
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to send for approval");
        } else {
          toast.info("PO sent for approval");
          navigate(`/subway-finance/purchase-order/${id}`);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="edit-po-container">
      <div className="po-edit-header">
        <div className="left-container">
          <div className="back-title">
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=1");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className="title">
              Edit PO {formDetails.poNumber}
            </Typography>
          </div>
          <div className="info-section">
            <div className="info-box">
              <span>Site Name </span>
              <span>{siteName || ""}</span>
            </div>
            <div className="info-box">
              <span>Site Code </span>
              <span>{siteCode || ""}</span>
            </div>
            <div className="info-box">
              <span>PO Type </span>
              <span>
                {convertToCamelCaseFromUnderScore(formDetails.poType)}
              </span>
            </div>
            <div className="info-box">
              <span>PO Date </span>
              <span>
                {formatDate(formDetails.poDate, DATE_FORMATS["DD-MM-YYYY"])}
              </span>
            </div>
            <div className="info-box">
              <span>Created By</span>
              <span>{formDetails.creatorName || "-"}</span>
            </div>
            <div className="info-box">
              <span>Available Budget Amt </span>
              <span>
                {createPOavailableBudgetAmount(lineItems, selectedLineItemIds)}
              </span>
            </div>
          </div>
        </div>        
      </div>
      <div className="form-container">
        <div className="form-row">
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("company", val);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Company" />
            )}
            inputValue={autocompleteInputValues.company || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  company: newInputValue,
                });
              }
            }}
            getOptionLabel={(op) => op.name || ""}
            options={companyList}
          />

          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("vendor", val);
            }}
            getOptionLabel={(op) => `${op.code} - ${op.name}`}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Vendor" />
            )}
            value={formDetails.vendor || ""}
            inputValue={autocompleteInputValues.vendor || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  vendor: newInputValue,
                });
              }
            }}
            options={vendors}
          />
          <TextField
            value={vendor?.gst || ""}
            disabled
            size="small"
            label="Vendor GST No"
            fullWidth
          />
          <TextField
            value={vendor?.address || ""}
            disabled
            size="small"
            label="Vendor Address"
            fullWidth
          />
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("agreement", val);
            }}
            value={formDetails.agreement || ""}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Agreement" />
            )}
            options={["Yes", "No"]}
          />
        </div>
        <div className="form-row">
          <TextField
            value={formDetails.remarks || ""}
            onChange={inputChange}
            size="small"
            name="remarks"
            label="Agreement Remarks"
            fullWidth
          />

          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("deliveryLocation", val);
            }}
            value={formDetails.deliveryLocation || ""}
            inputValue={autocompleteInputValues.deliveryLocation || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  deliveryLocation: newInputValue,
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Delivery Location" />
            )}
            getOptionLabel={(op) => op.restaurantName || ""}
            options={getAddressList()}
          />
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("billingLocation", val);
            }}
            value={formDetails.billingLocation || ""}
            inputValue={autocompleteInputValues.billingLocation || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  billingLocation: newInputValue,
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Billing Location" />
            )}
            getOptionLabel={(op) => op.restaurantName || ""}
            options={getAddressList()}
          />
          <TextField
            value={formDetails.paymentTerms || ""}
            onChange={inputChange}
            size="small"
            name="paymentTerms"
            label="Payment terms(Master vendor)"
            fullWidth
          />
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("department", val);
            }}
            disabled
            getOptionLabel={(op) => op.name}
            value={formDetails.department || ""}
            inputValue={
              (formDetails.department && formDetails.department.name) || ""
            }
            renderInput={(params) => (
              <TextField {...params} size="small" label="Department" />
            )}
            options={departmentList}
          />
        </div>
        <div className="form-row">
          <TextField
            value={formDetails.advancePercent || ""}
            onChange={inputChange}
            size="small"
            type="number"
            name="advancePercent"
            label="Advance Percent*"
            fullWidth
          />
          <TextField
            value={
              getAdvanceAmount(
                formDetails.advancePercent || 0,
                getPOAmountWithoutTax(poItems)
              ) || ""
            }
            onChange={inputChange}
            size="small"
            type="number"
            name="advanceAmount"
            label="Advance Amount"
            disabled
            fullWidth
          />
          <TextField
            value={formDetails.termsAndConditions || ""}
            onChange={inputChange}
            size="small"
            name="termsAndConditions"
            label="Terms and conditions"
            fullWidth
          />
          <Autocomplete
            onChange={(e, val) => {
              onDropDownChange("currency", val);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Currency" />
            )}
            inputValue={autocompleteInputValues.currency || ""}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setAutocompleteInputValues({
                  ...autocompleteInputValues,
                  currency: newInputValue,
                });
              }
            }}
            options={currencyOptions}
          />
          <TextField
            value={formDetails.forex || ""}
            onChange={inputChange}
            type="number"
            size="small"
            name="forex"
            label="Forex Rate"
            fullWidth
          />
        </div>
      </div>
      {vendor && vendor.code ? (
        <>
          <div className="po-additional-attachment-container">
            <FileUpload
              uploadText="Additional Documents"
              clsName="add-additional-doc"
              id="additional-documents"
              url={`${FINANCE_HOSTNAME}${
                FINANCE_REST_URLS.UPLOAD_DOCUMENT
              }${getUploadAttachmentQueryParams(
                {
                  id: poDetails.id,
                  poNumber: poDetails.poNumber,
                  attachmentKey: "documentLinks",
                  status: poDetails.status,
                },
                uploadAttachmentTypes.poUpdate
              )}`}
              callBack={(response) => {
                const { data } = response;
                setAttachments([...attachments, data]);
              }}
              formDataKey="file"
            />
            {attachments.map((attachment, index) => {
              return (
                <Chip
                  label={attachment.originalName}
                  onDelete={() => onDelete(index)}
                  onClick={() => window.open(attachment?.url, "_blank")}
                />
              );
            })}
          </div>
          <div className="po-add-items-container">
            <div className="added-po-items-list">
              {lineItems.map((lineItem) => {
                if (!selectedLineItemIds.includes(lineItem.id)) {
                  return;
                }
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="po-accordion-summary"
                    >
                      <div className="accordion-header">
                        <div className="left-container">
                          <Typography>
                            {lineItem.name} (
                            {(poItems[lineItem.id] || []).length})
                          </Typography>

                          {formDetails.vendor && formDetails.vendor.id && (
                            <IconButton
                              disabled={
                                lineItem.availableAmount <= 0 ||
                                !(
                                  (formDetails.vendor &&
                                    formDetails.vendor.id) ||
                                  ""
                                )
                              }
                              onClick={(event) => {
                                onRowAdd(lineItem);
                                event.stopPropagation();
                              }}
                            >
                              <AddCircle color="primary" />
                            </IconButton>
                          )}
                        </div>
                        <div className="right-container">
                          <span>
                            Available Amount :{" "}
                            {Math.round(lineItem.availableAmount).toFixed(2)}
                          </span>
                          <span
                            className={
                              getPOLineItemAmount(
                                poItems[lineItem.id] || [],
                                "taxPercentage"
                              ) > lineItem.availableAmount
                                ? "error"
                                : ""
                            }
                          >
                            Order Amount :{" "}
                            {getPOLineItemAmount(
                              poItems[lineItem.id] || [],
                              "taxPercentage"
                            )}
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {(poItems[lineItem.id] || []).map((poItem, index) => {
                        return (
                          <AddItemModal
                            poItem={poItem}
                            lineItem={lineItem}
                            vendorCode={
                              formDetails.vendor && formDetails.vendor.code
                            }
                            isItemDeleted={config.isItemDeleted}
                            updateItem={(item) => updateItem(item, index)}
                            onDelete={() => onDeleteRow(lineItem.id, index)}
                          />
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="po-attachment-form-container">
          <span>Please select vendor to add items</span>
        </div>
      )}
    </div>
  );
};

export default EditPO;
