import { Button, TextField } from "@mui/material";
import { checkUserRight, formatIndianRupee } from "../../../utils/utils";

export const getHeaderConfig = (
  onFormChange,
  saveChange,
  viewHistory,
  budgetDetails
) => {
  const headers = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Available Amount",
      id: "availableAmount",
      render: (data) => {
        return formatIndianRupee(data?.availableAmount);
      },
    },
    {
      label: "Initial Amount",
      id: "initialAmount",
      render: (data) => {
        return formatIndianRupee(data?.initialAmount);
      },
    },
    {
      label: "Current Amount",
      id: "currentAmount",
      render: (data) => (
        <TextField
          type="number"
          size="small"
          disabled={
            !checkUserRight("budget.update") ||
            ["inactive", "approval_in_progress"].includes(data.status) ||
            budgetDetails.status === "inactive"
          }
          sx={{
            maxWidth: 120,
          }}
          value={data.currentAmount}
          onChange={(event) => onFormChange(event, data)}
          name="currentAmount"
        />
      ),
    },
    {
      label: "History",
      id: "name",
      render: (data) => {
        return (
          <Button variant="text" onClick={() => viewHistory(data, "HISTORY")}>
            View History
          </Button>
        );
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        const { status } = data;
        if (status === "approval_in_progress") {
          return <span>Approval In Progress</span>;
        }
        return (
          <>
            <Button
              variant="text"
              onClick={() => saveChange(data, "SAVE")}
              disabled={
                !checkUserRight("budget.update") ||
                ["inactive", "approval_in_progress"].includes(status) ||
                budgetDetails.status === "inactive"
              }
            >
              Save
            </Button>
            <Button
              variant="text"
              onClick={() => saveChange(data, "HOLD")}
              disabled={
                !checkUserRight("budget.update") ||
                status === "approval_in_progress" ||
                budgetDetails.status === "inactive"
              }
            >
              {status === "inactive" ? "Approve" : "Hold/Drop"}
            </Button>
          </>
        );
      },
    },
  ];
  return headers;
};

export const getAvailableBudgetCost = (lineItems) => {
  let sum = 0;
  lineItems.forEach((item) => {
    sum = sum + +item.availableAmount;
  });

  return sum.toFixed(2);
};

export const getInitialBudgetCost = (lineItems) => {
  let sum = 0;
  lineItems.forEach((item) => {
    sum = sum + +item.initialAmount;
  });

  return sum.toFixed(2);
};

export const getModifiedBudgetCost = (lineItems) => {
  let sum = 0;
  lineItems.forEach((item) => {
    sum = sum + +item.currentAmount;
  });

  return sum.toFixed(2);
};
