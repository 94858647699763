import { Button } from "@mui/material";
const POInfo = ({ poDetails, siteCode, siteName, onOk }) => {
  return (
    <div className='po-info-container'>
      <div className='po-info-section'>
        <span>Site Name </span>
        <span>{siteName}</span>
      </div>

      <div className='po-info-section'>
        <span>Site Code </span>
        <span>{siteCode}</span>
      </div>
      <div className='po-info-section'>
        <span>GRN Number </span>
        <span>{poDetails.grnNo}</span>
      </div>
      <div className='po-info-section'>
        <span>Total Tax Amount </span>
        <span>{poDetails.taxAmount}</span>
      </div>
      <div className='po-info-section'>
        <span>Total Amount </span>
        <span>{poDetails.totalAmount}</span>
      </div>
      <Button fullWidth variant='outlined' onClick={onOk} size='small'>
        Ok
      </Button>
    </div>
  );
};

export default POInfo;
