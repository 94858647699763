import React, { useState, useEffect } from "react";
import CustomModal from "../../../common-utilities/core/modal";
import "./index.scss";
import { useParams } from "react-router";
import { formatDate } from "../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../common-utilities/utils/constants";
import { IconButton, Button, TextareaAutosize } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { invokeApi } from "../../utils/http-service";
import { technetEndPoint } from "../../../common-utilities/utils/end-points/tech-net";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokenMainKeys } from "../../../../utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import SelectComponent from "../../../common-utilities/core/select-component";
import CommonBtn from "../../../common-utilities/core/common-button";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";

const BasicInfo = ({
  ticketDetails,
  reloadData,
  onDateChange,
  storeDetails,
}) => {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const user = getOfflineData(tokenMainKeys.technet, "user");
  const [adminAndTechNetUsersList, setadminAndTechNetUsersList] = useState([]);
  const [engineer, setEngineer] = useState(null);
  const [reAssignedComment, setReAssignedComment] = useState("");
  const [reassign, setReassign] = useState(false);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    if (user.type === "AM") {
      invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.AM_STORE_USER_LIST}`)
        .then((response) => {
          if (response?.message) {
            return;
          }
          const allStoreUsers = response?.storeUsers?.map((item) => {
            return { label: item.fullName, value: item.id };
          });
          setadminAndTechNetUsersList(allStoreUsers);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.ADMINANDTECHNETUSERSLIST}`,
        null,
        {
          getAgent: true,
        }
      )
        .then((response) => {
          if (response?.message) {
            return;
          }
          const allTechNetAndAdminUsers = response.map((item) => {
            return { label: item.name, value: item.id };
          });
          setadminAndTechNetUsersList(allTechNetAndAdminUsers);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const reAssignEnginner = () => {
    setReassign(false);
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.REASSIGNENGINEER}/${id}`,
      { assignedTo: engineer.value, assignedToComment: reAssignedComment }
    )
      .then((response) => {
        if (response.success === true) {
          toast.success("Engineer reassigned successfully");
          navigate(`${technetEndPoint}/tickets`);
          loadData();
          if (onDateChange) {
            onDateChange();
          }
          reloadData();
        } else {
          toast.error("Sorry something went wrong");
        }
        setReassign(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid container md={12} xs={12} className="basic-info-container">
      <Grid md={user && user.type !== "STORE_OWNER" ? 2 : 3} xs={12}>
        <div className="inputs">
          <div className="info-section">
            <div>Ticket id: </div>
            <div className="value"> {id.substr(0, 8)}</div>
          </div>
          <div className="info-section">
            <span>Created At:</span>
            <div className="value">
              {formatDate(
                ticketDetails.createdAt,
                DATE_FORMATS["MMM DD YYYY hh:MM"]
              )}
            </div>
          </div>
          <div className="info-section">
            <span> Contact Person:</span>
            <div className="value">
              {storeDetails &&
                storeDetails?.contactPerson &&
                `${storeDetails.contactPerson}`}
            </div>
          </div>
        </div>
      </Grid>
      <Grid md={user && user.type !== "STORE_OWNER" ? 2 : 3} xs={12}>
        <div className="inputs">
          <div className="info-section">
            <div>Ticket Status:</div>
            <div className="value">
              {ticketDetails &&
              ticketDetails.ticket &&
              ticketDetails.ticket.status
                ? ticketDetails.ticket.status
                : ""}
            </div>
          </div>

          <div className="info-section">
            <span>Department:</span>
            <div className="value">
              {ticketDetails &&
                ticketDetails.ticket &&
                `${ticketDetails.ticket.dept.name}`}
            </div>
          </div>
        </div>
        <div className="info-section">
          <span>Contact Number:</span>
          <div className="value">
            {storeDetails &&
              storeDetails?.storeContact &&
              `${storeDetails.storeContact}`}
          </div>
        </div>
      </Grid>
      <Grid md={user && user.type !== "STORE_OWNER" ? 2 : 3} xs={12}>
        <div className="inputs">
          <div className="info-section">
            <span>Category:</span>
            <div className="value">
              {ticketDetails &&
                ticketDetails.ticket &&
                `${ticketDetails.ticket.category.name}`}
            </div>
          </div>
          <div className="info-section">
            <span>SLA Expiry:</span>
            <div className="value">
              {ticketDetails &&
                ticketDetails.ticket &&
                `${formatDate(
                  ticketDetails.ticket.slaExpiry,
                  DATE_FORMATS["MMM DD YYYY hh:MM"]
                )}`}
            </div>
          </div>
        </div>
      </Grid>

      <div className="inputs">
        {user && user.type !== "STORE_OWNER" && (
          <>
            <Grid md={user && user.type !== "STORE_OWNER" && 12} xs={12}>
              <div className="info-section">
                <span>Support Person:</span>
                <div className="value space-between">
                  {ticketDetails &&
                    ticketDetails.ticket &&
                    ticketDetails.ticket.assignedTo &&
                    ticketDetails.ticket.assignedTo.name}
                  {(ticketDetails?.updateRights || user.type === "AM") && (
                    <IconButton
                      size="small"
                      fontSize="12"
                      onClick={() => setReassign(true)}
                    >
                      <EditIcon fontSize="12" color="primary" />
                    </IconButton>
                  )}
                </div>
              </div>

              <div className="info-section">
                <span>Store Number:</span>
                <div className="value">
                  {storeDetails &&
                    storeDetails?.storeNumber &&
                    `${storeDetails.storeNumber}`}
                </div>
              </div>
            </Grid>
          </>
        )}

        {reassign && (
          <CustomModal
            classesToPreventClosing={[
              "MuiButtonBase-root",
              "MuiMenuItem-root",
              "MuiAutocomplete-option",
              "Mui-focused",
            ]}
            title="Reassign"
            onClose={() => setReassign(false)}
          >
            <div className="reassign-container">
              <div>Support Person</div>
              <AutocompleteComponent
                onChange={(e, val) => {
                  const engr = adminAndTechNetUsersList.find(
                    (item) => item.value === val?.value
                  );
                  setEngineer(engr || null);
                }}
                label="Select Support Person"
                value={engineer || null}
                options={adminAndTechNetUsersList}
                getOptionLabel={(option) => option?.label}
              />
              {/* <SelectComponent
                onChange={(e, val) => {
                  const engr = adminAndTechNetUsersList.find(
                    (item) => item.value === e.target.value
                  );
                  setEngineer(engr || null);
                }}
                label="Select Support Person"
                value={engineer?.value || null}
                options={adminAndTechNetUsersList}
              /> */}
              <TextareaAutosize
                placeholder="Comment"
                minRows={5}
                fullWidth
                onChange={(e) => setReAssignedComment(e.target.value)}
              ></TextareaAutosize>
              <CommonBtn
                fullWidth
                variant="contained"
                className="button-color"
                size="small"
                disabled={!(engineer && engineer.value)}
                onClick={reAssignEnginner}
              >
                Update details
              </CommonBtn>
            </div>
          </CustomModal>
        )}
      </div>
    </Grid>
  );
};

export default BasicInfo;
