import { TextField } from "@mui/material";

export const getHeaderConfig = (receivedItems, onChange) => {
  const headers = [
    {
      label: "Item Code ",
      id: "itemCode"
    },
    {
      label: "Items Description",
      id: "itemDescription"
    },
    // {
    //   label: 'UOM',
    //   id: 'uom',
    // },
    {
      label: "PO Qty",
      id: "quantity"
    },
    {
      label: "Inwarded Qty",
      id: "inwardedQuantity"
    },
    {
      label: "Received Qty",
      id: "receivedItems",
      render: (data) => {
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return (
          <TextField
            size='small'
            value={item.receivedQuantity || ""}
            name={item.smPoItemId}
            label='Received Quantity'
            onChange={(e) => onChange(e, data.item)}
            error={
              Number(item.receivedQuantity) + Number(data.inwardedQuantity) >
              data.quantity
            }
            helperText={
              Number(item.receivedQuantity) + Number(data.inwardedQuantity) >
              data.quantity
                ? `Should be less than or equal to ${
                    data.quantity - Number(data.inwardedQuantity)
                  }`
                : ""
            }
          />
        );
      }
    },
    {
      label: "Base Amount",
      id: "receivedAmount",
      render: (data) => {
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return (item && item.amount) || "";
      }
    },
    {
      label: "Tax Amount",
      id: "taxAmount",
      render: (data) => {
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return getGRNOrderTaxAmount([data], [item]);
      }
    },
    {
      label: "Total Amount",
      id: "totalAmount",
      render: (data) => {
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return getGRNOrderAmountWithTax([data], [item]);
      }
    }
  ];
  return headers;
};

export const getGRNOrderAmount = (items, receivedItems) => {
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.smPoItemId === item.item);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      // let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + amount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNOrderTaxAmount = (items, receivedItems) => {
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.poItemId === item._id);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + taxAmount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNOrderAmountWithTax = (items, receivedItems) => {
  let sum = 0;
  items?.forEach?.((item) => {
    let receivedItem = receivedItems?.find?.((r) => r.poItemId === item._id);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + amount + taxAmount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const createUpdatePayloadMapper = (grnDetails) => {
  let payload = {
    smPoId: grnDetails?.smPoId?.id,
    receivedItems: grnDetails.receivedItems.map((item) => {
      return {
        smPoItemId: item.smPoItemId,
        receivedQuantity: +item.receivedQuantity
      };
    }),
    inwardDate: grnDetails.inwardDate,
    invoiceNumber: grnDetails.invoiceNumber,
    invoiceDate: grnDetails.invoiceDate,
    remarks: grnDetails.remarks
  };
  return payload;
};
