import { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import DateRangeFilter from "../../../../common-utilities/core/date-range-filter";
import ClearIcon from "@mui/icons-material/Clear";

export const NumberRange = ({ setFilterValue }) => {
  const [val, setVal] = useState({ minVal: 0 });
  useEffect(() => {
    setFilterValue(val);
  }, [val]);
  return (
    <div>
      <TextField
        error={val.minVal < 0 ? true : false}
        label="From"
        size="small"
        type="number"
        sx={{ mr: 1 }}
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
        defaultValue={0}
        onChange={(e) =>
          setVal((prevVal) => ({ ...prevVal, minVal: e.target.value }))
        }
      />
      <TextField
        label="To"
        error={val?.maxVal < 0 ? true : false}
        size="small"
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
        onChange={(e) =>
          setVal((prevVal) => ({ ...prevVal, maxVal: e.target.value }))
        }
      />
    </div>
  );
};

export const TextFieldKeyVal = ({ setFilterValue, placeHolder = "Search" }) => {
  return (
    <TextField
      placeholder={placeHolder}
      size="small"
      onChange={(e) => setFilterValue(e.target.value)}
    />
  );
};
export function getLast15DaysDateRange() {
  // Get the current date
  const currentDate = new Date();

  // Calculate the start date by subtracting 15 days from the current date
  const startDate = new Date(currentDate);
  startDate.setDate(startDate.getDate() - 15);

  // Format the start date and current date as strings
  const startDateString = startDate.toISOString().split("T")[0];
  const currentDateString = currentDate.toISOString().split("T")[0];

  // Return the date range
  return {
    startDate: startDateString,
    endDate: currentDateString,
  };
}
export const DateRange = ({ setFilterValue }) => {
  const [val, setVal] = useState({});
  useEffect(() => {
    setFilterValue(val);
  }, [val]);
  return (
    <DateRangeFilter
      startDate={val?.startDate}
      endDate={val?.endDate}
      setDateRange={setVal}
    />
  );
};
export const AutoCompleteSelect = ({
  setFilterValue,
  optionsList,
  filterLabel,
}) => {
  const [label, setLabel] = useState(filterLabel);
  useEffect(() => {
    if (filterLabel !== label) {
      setFilterValue("");
      setLabel(filterLabel);
    }
  }, [filterLabel]);
  return (
    <>
      {filterLabel === label && (
        <Autocomplete
          options={optionsList}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Select"
              sx={{ width: "15rem" }}
            />
          )}
          onChange={(e, val) => {
            if (val) {
              setFilterValue(val.value || val);
            } else {
              setFilterValue("");
            }
          }}
        />
      )}
    </>
  );
};
export const RegexAutoComplete = ({
  getOptionsListFun,
  setFilterValue,
  field,
  type,
  filterLabel,
}) => {
  const [options, setOptions] = useState([]);
  const [val, setVal] = useState("");
  const [label, setLabel] = useState(filterLabel);

  const onInputChange = (e, value) => {
    if (value?.trim()) {
      getOptionsListFun(value, field, setOptions);
      setVal(value);
    } else {
      setVal("");
      setOptions([]);
    }
  };
  useEffect(() => {
    if (filterLabel !== label) {
      setVal("");
      setOptions([]);
      setLabel(filterLabel);
    }
  }, [filterLabel]);
  useEffect(() => {
    setFilterValue(val);
  }, [val]);
  return (
    <>
      {label === filterLabel && (
        <Autocomplete
          getOptionLabel={(option) => {
            return option ? option.toString() : "";
          }}
          clearIcon={
            <ClearIcon
              fontSize="small"
              onClick={() => {
                setVal("");
                setOptions([]);
              }}
            />
          }
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Select"
              sx={{ width: "15rem" }}
              type={type ? type : "text"}
            />
          )}
          inputValue={val + "" || ""}
          onInputChange={onInputChange}
          onChange={(e, val) => {
            if (val) {
              setVal(val?.storeNo);
            } else {
              setVal("");
            }
          }}
        />
      )}
    </>
  );
};
