import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate } from "react-router-dom";
import DateRangeFilter from "../../../../common-utilities/core/date-range-filter";
import {
  clearProperty,
  setProperty,
} from "../../../../common-utilities/utils/misc";
import {
  Autocomplete,
  Button,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import PoFilters from "../../purchase-order/po-filters";
import { BUDGET_TYPES } from "../../../utils/constants";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../../common-utilities/utils/time";
import CustomModal from "../../../../common-utilities/core/modal";
import { toast } from "react-toastify";
import FinancialYearDropdown from "../../home/financial-year-dropdown";

const BudgetApprovalList = ({
  status,
  usedIn,
  searchPlaceHolder = "Search",
}) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState([]);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [bulkComment, setBulkComment] = useState("");
  const [filters, setFilters] = useState({
    approved: false,
    rejected: false,
    approvalType: status,
    sortBy: "-createdAt",
    populateSite: true,
  });

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (providedFilters) => {
    const params = providedFilters ? { ...providedFilters } : { ...filters };
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          setBudgetList(response);
        }
      })
      .catch((err) => {});
  };
  const handleRowSelection = (event, data, type) => {
    const isChecked = event.target.checked;
    if (type === "selectAll") {
      let selectedBudgets = [];

      if (isChecked) {
        selectedBudgets = [...budgetList];
      }

      setSelectedBudget(selectedBudgets);
    } else {
      let updatedSelectedBudgets = [...selectedBudget];
      if (isChecked) {
        updatedSelectedBudgets.push(data);
      } else {
        updatedSelectedBudgets = updatedSelectedBudgets.filter(
          (ele) => ele.id !== data.id
        );
      }
      setSelectedBudget(updatedSelectedBudgets);
    }
  };
  const viewBudget = (data) => {
    const { document = {} } = data;
    navigate(
      `approve-budget-details/${document.budgetId.id}?approvalId=${data.id}`
    );
  };
  const bulkApprove = () => {
    const approvalIds = selectedBudget?.map((ele) => parseInt(ele.id));
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_APPROVE}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedBudget([]);
          loadData(filters);
          setBulkComment("");
          setIsBulkModalOpen(false);
          toast.success("Approved Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };

  const bulkReject = () => {
    const approvalIds = selectedBudget?.map((ele) => ele.id);
    const payload = {
      approvalIds,
    };
    const params = {};
    if (bulkComment) {
      params["comment"] = bulkComment;
    } else {
      return toast.error("Comment is required", { autoClose: 2000 });
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.BULK_REJECT}`,
      payload,
      params
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setSelectedBudget([]);
          setBulkComment("");
          loadData(filters);
          setIsBulkModalOpen(false);
          toast.success("Rejected Successfully", { autoClose: 2000 });
        }
      })
      .catch((err) => toast.error(err, { autoClose: 2000 }));
  };
  return (
    <div className="approval-list">
      <div className="filter-row">
        <TextField
          placeholder={`${searchPlaceHolder}`}
          value={filters?.searchKey || ""}
          size="small"
          onChange={(e) => setProperty("searchKey", e.target.value, setFilters)}
        />
        <Autocomplete
          value={
            filters?.budgetType
              ? BUDGET_TYPES.find((item) => item.value === filters.budgetType)
              : null
          }
          onChange={(e, val) => {
            if (val) {
              setProperty("budgetType", val?.value, setFilters);
            } else {
              clearProperty("budgetType", setFilters);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Budget Type"
              sx={{
                minWidth: 220,
              }}
            />
          )}
          options={BUDGET_TYPES}
        />
        <PoFilters
          filters={filters}
          showVendorDropDown={false}
          setFilters={setFilters}
        />
        <FinancialYearDropdown filters={filters} setFilters={setFilters} />
        <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        />

        <Button
          variant="outlined"
          onClick={() => {
            loadData({
              ...filters,
            });
          }}
        >
          Search
        </Button>
        {selectedBudget.length > 0 && (
          <Button variant="outlined" onClick={() => setIsBulkModalOpen(true)}>
            Bulk Approval
          </Button>
        )}
      </div>
      <MuiTable
        columnsList={getHeaderConfig(
          viewBudget,
          handleRowSelection,
          selectedBudget,
          budgetList
        )}
        nestedValue={true}
        dataList={
          budgetList.slice(
            ((filters.page || 1) - 1) * 10,
            ((filters.page || 1) - 1) * 10 + 10
          ) || []
        }
        filters={filters}
        pageCount={Math.ceil(budgetList.length / 10)}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
        }}
      />

      {isBulkModalOpen && (
        <CustomModal
          title="Bulk Approve/Reject/Reconsider"
          onClose={() => setIsBulkModalOpen(false)}
        >
          <TextareaAutosize
            placeholder="Comments"
            minRows={6}
            value={bulkComment}
            style={{ width: "100%" }}
            onChange={(e) => setBulkComment(e.target.value)}
          />
          <div className="approve-btn-con finance-bulk-btn">
            <Button variant="outlined" sx={{ mr: 2 }} onClick={bulkApprove}>
              Approve
            </Button>

            <Button variant="outlined" color="error" onClick={bulkReject}>
              Reject
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default BudgetApprovalList;
