import { Button } from "@mui/material";
import { isDeleteAllowed, isUpdateAllowed } from "../../../utils/common-utils";
import { render } from "@testing-library/react";

export const getHeaderConfig = (editVendor, setDeleteDetails) => {
  const headers = [
    {
      label: "Item Code",
      id: "itemCode"
    },
    {
      label: "Item Description",
      id: "itemDescription"
    },
    {
      label: "Case Size",
      id: "caseSize"
    },
    {
      label: "UOM",
      id: "uom"
    },
    {
      label: "HSN Code",
      id: "hsnCode"
    },
    {
      label: "GST Rate",
      id: "gstRate"
    },
    {
      label: "Base Price",
      id: "basePrice",
      render: (data) => {
        return data.basePrice ? data.basePrice.toFixed(2) : "0.00";
      }
    },
    {
      label: "Full Price",
      id: "fullPrice",
      render: (data) => {
        return data.fullPrice ? data.fullPrice.toFixed(2) : "0.00";
      }
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        return (
          <>
            <Button
              variant='text'
              // disabled={!isUpdateAllowed("master_data")}
              onClick={() => editVendor(data, "EDIT")}
            >
              Edit
            </Button>
            <Button
              variant='text'
              // disabled={!isDeleteAllowed("master_data")}
              onClick={() => setDeleteDetails(data)}
            >
              Delete
            </Button>
          </>
        );
      }
    }
  ];
  return headers;
};

export const UOM_LIST = [
  { label: "Case", value: "Case" },
  { label: "Pack", value: "Pack" },
  { label: "Pcs", value: "Pcs" },
  { label: "Roll", value: "Roll" }
];

export const ITEM_TYPE = [
  { label: "Smallware", value: "Smallware" },
  { label: "Smallware Imp", value: "Smallware Imp" },
  { label: "HYGIENE", value: "HYGIENE" }
];
