import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { downloadReports } from "./utils";
import {
  getStartOfDay,
  getEndOfDay
} from "../../../../common-utilities/utils/time";
import { toast } from "react-toastify";
import ConfirmApprove from "./confirm-approve";
import { isApprovedAllowed } from "../../../utils/common-utils";
const POList = ({ status }) => {
  const navigate = useNavigate();
  const [bulkSelectIds, setBulkSelectIds] = useState([]);
  const [budgetList, setBudgetList] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [approvedPODetails, setApprovedPODetails] = useState({});
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt"
  });

  useEffect(() => {
    if (status) {
      loadData(filters);
    }
  }, [status]);

  const loadData = (apiFilters, download) => {
    let params = {
      ...apiFilters
    };
    if (status !== "all") {
      params.status = status;
    }
    if (searchKey) {
      params.searchKey = searchKey;
    }

    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }

    if (download) {
      params.limit = budgetList.totalResults;
      params.download = true;
      params.page = 1;
    }
    params.populateSite = true;

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}`,
      null,
      params
    )
      .then((response) => {
        if (download) {
          downloadReports(response || [], status);
          return;
        }
        setBudgetList(response);
        setApprovedPODetails({
          totalResults: response.totalResults,
          totalSum: Math.round(response.totalSum)
        });
      })
      .catch((err) => {});
  };

  const viewBudget = (data, type) => {
    if (type === "GRN") {
      navigate(`grn/create/${data.id}`);
    } else {
      navigate(`purchase-order/${data.id}`);
    }
  };

  const onApprove = (comment) => {
    let payload = { poIds: bulkSelectIds, status: "APPROVED" };
    setShowConfirm(true);
    if (bulkSelectIds.includes("all")) {
      payload.approveAll = true;
      delete payload.poIds;
    }

    if (comment) {
      payload.comment = comment;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}bulk-approve`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          toast.error("Approved successfully");
          loadData(filters);
          setShowConfirm(false);
          setBulkSelectIds([]);
        }
      })
      .catch((err) => {
        toast.error("Failed to approve");
      });
  };

  const onBulkApprove = () => {
    if (bulkSelectIds.includes("all")) {
      setApprovedPODetails({
        totalResults: budgetList.totalResults,
        totalSum: Math.round(budgetList.totalSum).toFixed(2)
      });
    } else {
      let totalSum = 0;
      bulkSelectIds.forEach((poId) => {
        budgetList?.results?.forEach((budget) => {
          if (budget.id === poId) {
            totalSum += budget.totalAmount;
          }
        });
      });

      setApprovedPODetails({
        totalResults: bulkSelectIds.length,
        totalSum: Math.round(totalSum).toFixed(2)
      });
    }

    setShowConfirm(true);
  };

  return (
    <div className='po-list'>
      <div className='filter-row'>
        <TextField
          placeholder='Search by Po Number'
          value={searchKey}
          size='small'
          onChange={(e) => setSearchKey(e.target.value)}
        />

        {/* <PoFilters
          filters={filters}
          setFilters={setFilters}
          showStatus={true}
          statusList={PO_STATUS}
          disableStatus={status !== "all"}
        /> */}
        {/* <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        /> */}
        {/* <Autocomplete
          value={poType || ""}
          onChange={(e, val) => {
            if (val) {
              setPoType(val);
            } else {
              setPoType(null);
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              label="PO Type"
              sx={{
                minWidth: 220
              }}
            />
          )}
          options={PO_TYPES}
        /> */}
      </div>
      <div className='end-btns'>
        {status === "APPROVAL_IN_PROGRESS" && isApprovedAllowed() && (
          <div>
            {bulkSelectIds?.length > 0 ? (
              <Button
                variant='outlined'
                onClick={() => {
                  onBulkApprove();
                }}
              >
                Bulk Approve
              </Button>
            ) : (
              <Button
                variant='outlined'
                onClick={() => {
                  setBulkSelectIds(["all"]);
                  setShowConfirm(true);
                }}
              >
                Approve All
              </Button>
            )}
          </div>
        )}

        <Button
          variant='outlined'
          onClick={() => {
            setFilters({
              ...filters,
              page: 1
            });
            loadData({
              ...filters,
              page: 1
            });
          }}
        >
          Search
        </Button>
        <Button
          variant='outlined'
          disabled={!budgetList.totalResults}
          onClick={() => {
            loadData(filters, true);
          }}
        >
          Download
        </Button>
      </div>

      <MuiTable
        columnsList={getHeaderConfig(
          viewBudget,
          filters,
          setFilters,
          status,
          loadData,
          bulkSelectIds,
          setBulkSelectIds
        )}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page
          });
          loadData({
            ...filters,
            page
          });
        }}
      />
      {showConfirm && (
        <ConfirmApprove
          bulkApprove={onApprove}
          approvedPODetails={approvedPODetails}
          setIsConfirmModalOpen={setShowConfirm}
        />
      )}
    </div>
  );
};

export default POList;
