export const getHeaderConfig = (receivedItems) => {
  const headers = [   
    {
      label: 'Item Code ',
      id: 'itemCode',        
    },
    {
      label: 'Items Description',
      id: 'itemDescription',      
    },
    // {
    //   label: 'UOM',
    //   id: 'uom',
    // },
    {
      label: 'PO Qty',
      id: 'quantity',
    },
    {
      label: 'Inwarded Qty',
      id: 'inwardedQuantity',
    },
    {
      label: 'Received Qty',
      id: 'receivedItems',
      render: (data) => {
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return (item && item.receivedQuantity) || '';
      },
    },
    {
      label: 'Base Amount',
      id: 'amount',
      render: (data) => {        
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return (item && item.amount) || '';
      },
    },
    {
      label: 'Tax Amount',
      id: 'taxAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return (item && item.taxAmount) || '';
      },
    },
    {
      label: 'Total Amount',
      id: 'totalAmount',
      render: (data) => {
        let item = receivedItems.find((r) => r.smPoItemId === data.item) || {};
        return (item && item.totalAmount) || '';
      },
    },
  ];
  return headers;
};

export const getGRNOrderAmount = (items, receivedItems) => {    
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.smPoItemId === item.item);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;      
      sum = sum + amount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNOrderTaxAmount = (items, receivedItems) => {
  let sum = 0;
  items.forEach((item) => {
    let receivedItem = receivedItems.find((r) => r.smPoItemId === item.item);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + taxAmount;
    }
  });

  return Math.round(sum).toFixed(2);
};

export const getGRNOrderAmountWithTax = (items, receivedItems) => {
  let sum = 0;
  items?.forEach?.((item) => {
    let receivedItem = receivedItems?.find?.((r) => r.smPoItemId === item.item);
    if (receivedItem) {
      let amount = receivedItem.receivedQuantity * item.rate;
      let taxAmount = (amount * item.taxRate) / 100;
      sum = sum + amount + taxAmount;
    }
  });

  return Math.round(sum).toFixed(2);
};
