/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./index.scss";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { setOfflineData, getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import { BellCorpStudioLogoContainer } from "../../packages/common-utilities/Bellcorp-Studio-Logo";
import loginLogo from "../../resources/images/white-logo.png";
const Login = () => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (getOfflineData("user")) {
      navigate("/");
    }
  }, []);

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const login = () => {
    let payload = {
      password: loginDetails.password,
    };
    if (loginDetails.email.includes("@")) {
      payload["email"] = loginDetails.email;
    } else {
      payload["userId"] = loginDetails.email;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${INTRANETHOSTNAME}${REST_URLS.LOGIN}`,
      payload
    ).then((response) => {
      if (response.message) {
        toast.error(response.message);
        setLoginDetails({
          ...loginDetails,
          errorMsg: response.message,
        });
      } else {
        response.user && setOfflineData("user", response.user);
        response.tokens && setOfflineData("tokens", response.tokens);
        navigate("/");
      }
    });
  };

  return (
    <div className="login-background">
      <div className="login-con">
        <h1>Welcome to Pulse</h1>
        <TextField
          size="small"
          label="Email / User Id"
          name="email"
          value={loginDetails.email}
          onChange={onInputChange}
          sx={{ mb: 4 }}
        />
        <TextField
          size="small"
          label="Password"
          type={showPassword ? "text" : "password"}
          name="password"
          value={loginDetails.password}
          onChange={onInputChange}
          sx={{ mb: 4 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={login}
          disabled={!loginDetails.email || !loginDetails.password}
          sx={{
            borderRadius: "20px",
            mt: 2,
            width: "12rem",
            fontWeight: "bold",
          }}
        >
          Login
        </Button>
        {loginDetails.errorMsg && (
          <span className="error-msg">{loginDetails.errorMsg}</span>
        )}
      </div>
      <p className="powered-by">
        Powered by <strong>Bellcorp Studio</strong>
      </p>
      <div className="white-logo si-hide-mobile">
        <img src={loginLogo} />
      </div>
    </div>
  );
};

export default Login;
