import { current } from "@reduxjs/toolkit";
import { getOfflineData } from "./offline-services";

// Function for checking that current user logged in has right
// to approve the configuration depending on its additional role
// and stage's allowed role
// True means we show Approve/ Reject Button, otherwise they are disabled
export const checkIsApprover = (approverDetails) => {
  const user = getOfflineData("user");
  const userId = parseInt(user?.id || user?._id);
  const pendingWith = parseInt(approverDetails?.approver);

  return userId === pendingWith;
};

export const checkIsApproverForStage = (stage) => {
  const user = getOfflineData("user");
  return stage?.approverRole?.toLowerCase() === user.role?.toLowerCase();
};

export const hasApproveAccessNow = (grnDetails) => {  
  const { pendingAtStage = 0, stages = [] } = grnDetails;  
  const user = getOfflineData("user");  
  const currentStage = stages?.find((s) => +s.id === +pendingAtStage) || {};
  return currentStage?.approverRole?.toLowerCase() == user.role?.toLowerCase();
};
