import "./index.scss";
import {
  Grid,
  Button,
  TextField,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import Select from "react-select";
import { useState, useEffect } from "react";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { unitOfMeasure, USER_ROLES } from "../../utils";

import { getOfflineData } from "../../utils/offline-services";
import SubwayFileUpload from "../../../common-utilities/core/file-uploader/subway-file-upload";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { SEVERITY_OPTIONS } from "../view-case/helper";
import { CASE_TYPES } from "../create-case/helper";
import {
  checkIfProductInformationIsDisabledForEditing,
  disableSaveButton,
  getCaseCreationUpdationPayload,
  validateFormFieldsBeforeSaving,
  getStoreDetailsPayload,
  COMPLAINT_CATEGORY_LIST,
} from "./config";
import {
  getProductByCode,
  getStoreDataByStoreId,
  updateStoreDetailsById,
} from "./data-apis";

import {
  getCaseDataById,
  setListOfComplaintTypeDropdown,
  setProductListForDropDown,
  setStoreListForDropdown,
} from "./initial-apis";
import {
  countryDropdownList,
  getStateDropdownList,
} from "../../../common-utilities/utils/region-utils";
import { appSlugs, tokenMainKeys } from "../../../../utils/constants";
import { getSelectDcList } from "../../utils/dc";
import { getSelectVendorList } from "../../utils/vendor";

const ComplaintForm = ({
  complaintType,
  curPageStatuses,
  setCurPage,
  loadData,
}) => {
  const user = getOfflineData(null, "user");
  const [originalCaseDetails, setOriginalCasesDetails] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [restaurantDetails, setRestaurantDetails] = useState({});

  const [restaurantList, setRestaurantList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [dcList, setDcList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [complaintTypeList, setComplaintTypeList] = useState([]);
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const { caseId } = useParams();
  const isEditingCase = Number.isInteger(parseInt(caseId));
  const disableEditingProductInformation =
    checkIfProductInformationIsDisabledForEditing(caseId, originalCaseDetails);

  useEffect(() => {
    const user = getOfflineData(null, "user");
    if (!user) {
      navigate("/");
    }
    const isStoreUser = user?.role?.type === USER_ROLES.STORE;
    if (!Number.isInteger(parseInt(caseId))) {
      setStoreListForDropdown(user, setRestaurantList);
    }

    if (Number.isInteger(parseInt(caseId))) {
      getCaseDataById(
        setRestaurantDetails,
        setFormDetails,
        complaintType,
        setOriginalCasesDetails,
        caseId,
        setStateList
      );
    } else if (isStoreUser) {
      getStoreDataByStoreId(
        user?.storeId,
        true,
        setRestaurantDetails,
        setFormDetails,
        complaintType,
        setStateList
      );
    }
  }, [caseId]);

  useEffect(() => {
    const countrySelectList = countryDropdownList();
    setCountryList(countrySelectList);
    setListOfComplaintTypeDropdown(setComplaintTypeList);
    (async () => {
      const dc = await getSelectDcList();
      setDcList(dc);
      const vendor = await getSelectVendorList();
      setVendorList(vendor);
    })();
    setProductListForDropDown(setProductList);
  }, []);

  const updateStoreDetailsAndCreateCase = () => {
    const [storePayload, id] = getStoreDetailsPayload(restaurantDetails);
    const [casePayload] = getCaseCreationUpdationPayload(
      formDetails,
      caseId,
      complaintType,
      files
    );

    const formHasErrors = validateFormFieldsBeforeSaving(
      id,
      isEditingCase,
      casePayload,
      storePayload
    );
    if (formHasErrors) {
      return;
    }

    updateStoreDetailsById(
      id,
      storePayload,
      setRestaurantDetails,
      caseId,
      true,
      {
        caseId,
        casePayload,
        id,
        isEditingCase,
        loadData,
        setCurPage,
        setFormDetails,
        setFiles,
        navigate,
        curPageStatuses,
        location,
      }
    );
  };

  const setFormDetailsOnChange = (e, details) => {
    if (e.target) {
      const { name, value } = e.target;
      const splittedName = name.split(".");
      if (splittedName.length == 2) {
        setFormDetails((prevDetails) => {
          const prevKeyValue = prevDetails?.[splittedName[0]] || {};
          return {
            ...prevDetails,
            [splittedName[0]]: { ...prevKeyValue, [splittedName[1]]: value },
          };
        });
        return;
      }
      setFormDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
      return;
    }
    const { name } = details;
    const splittedName = name.split(".");
    const mainKey = splittedName[0];
    const subKey = splittedName[1];
    if (splittedName.length == 2) {
      setFormDetails((prevDetails) => {
        const prevKeyValue = prevDetails?.[mainKey] || {};
        return {
          ...prevDetails,
          [mainKey]: { ...prevKeyValue, [subKey]: e },
        };
      });
      return;
    }
    setFormDetails((prevDetails) => ({ ...prevDetails, [splittedName[0]]: e }));
  };

  const setRestaurantDetailsOnChange = (e, details) => {
    if (e.target) {
      const { name, value } = e.target;
      const splittedName = name.split(".");
      if (splittedName.length == 2) {
        setRestaurantDetails((prevDetails) => {
          const prevKeyValue = prevDetails?.[splittedName[0]] || {};
          return {
            ...prevDetails,
            [splittedName[0]]: { ...prevKeyValue, [splittedName[1]]: value },
          };
        });
        return;
      }
      setRestaurantDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
      return;
    }

    const { name } = details;
    const splittedName = name.split(".");
    const mainKey = splittedName[0];
    const subKey = splittedName[1];
    if (splittedName.length == 2) {
      setRestaurantDetails((prevDetails) => {
        const prevMainValue = prevDetails?.[mainKey] || {};
        return { ...prevDetails, [mainKey]: { ...prevMainValue, [subKey]: e } };
      });
      return;
    }
    setRestaurantDetails((prevDetails) => {
      if (mainKey === "country") {
        delete prevDetails.city;
        delete prevDetails.state;
        const allStateList = getStateDropdownList(e?.value);
        setStateList(allStateList);
      }

      if (mainKey === "state") {
        delete prevDetails.city;
      }
      return {
        ...prevDetails,
        [mainKey]: e,
      };
    });
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <div className="restaurant-form-container">
      {isEditingCase && (
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={5}>
            <div className="select-restaurant-grid">
              <div>Select case type</div>
              <Select
                options={CASE_TYPES}
                placeholder="Case type"
                classNamePrefix="subway-select"
                className="input caseType-select"
                name="type"
                value={formDetails.type || null}
                onChange={setFormDetailsOnChange}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 3 }),
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item md={5} xs={12}>
          <div className="main-Header">Restaurant Info</div>

          {!Number.isInteger(parseInt(caseId)) && (
            <div className="select-grid">
              <div>
                Select Restaurant <span className="warning-text">*</span>
              </div>
              <Select
                options={restaurantList}
                placeholder="Restaurant"
                classNamePrefix="subway-select"
                name="restaurant"
                isDisabled={user?.role?.type === USER_ROLES.STORE}
                value={restaurantDetails.id || null}
                onChange={(e, details) => {
                  setRestaurantDetailsOnChange(e, details);
                  getStoreDataByStoreId(
                    e?.value,
                    true,
                    setRestaurantDetails,
                    setFormDetails,
                    complaintType,
                    setStateList
                  );
                }}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 3 }),
                }}
              />
            </div>
          )}

          <div className="sub-header">Primary Contact</div>
          <div className="select-grid">
            <span>
              Name <span className="warning-text">*</span>{" "}
            </span>
            <TextField
              className="input"
              size="small"
              label=""
              disabled={Number.isInteger(parseInt(caseId))}
              name="primaryContact.name"
              value={restaurantDetails?.primaryContact?.name || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <span>
              Email <span className="warning-text">*</span>{" "}
            </span>
            <TextField
              size="small"
              className="input"
              label=""
              disabled={Number.isInteger(parseInt(caseId))}
              name="primaryContact.email"
              type="email"
              value={restaurantDetails?.primaryContact?.email || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <span>
              Phone <span className="warning-text">*</span>{" "}
            </span>
            <TextField
              size="small"
              label=""
              name="primaryContact.phone"
              type="number"
              onKeyDown={blockInvalidChar}
              disabled={Number.isInteger(parseInt(caseId))}
              className="input"
              value={restaurantDetails?.primaryContact?.phone || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="sub-header">Address</div>
          <div className="select-grid">
            <div>Address 1</div>
            <TextField
              size="small"
              label=""
              disabled={Number.isInteger(parseInt(caseId))}
              name="address"
              className="input"
              value={restaurantDetails?.address || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <div>Address 2</div>
            <TextField
              size="small"
              label=""
              name="address2"
              disabled={Number.isInteger(parseInt(caseId))}
              className="input"
              value={restaurantDetails?.address2 || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <div>Select country</div>
            <Select
              options={countryList}
              placeholder="Country"
              classNamePrefix="subway-select"
              className="input"
              name="country"
              isDisabled={Number.isInteger(parseInt(caseId))}
              value={restaurantDetails?.country || null}
              onChange={setRestaurantDetailsOnChange}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </div>

          <div className="select-grid">
            <div>Select City</div>
            <TextField
              size="small"
              label=""
              className="input"
              disabled={Number.isInteger(parseInt(caseId))}
              name="city"
              value={restaurantDetails?.city || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <div>Select state</div>
            <Select
              options={stateList}
              placeholder="State"
              classNamePrefix="subway-select"
              className="input"
              isDisabled={Number.isInteger(parseInt(caseId))}
              name="state"
              value={restaurantDetails?.state || null}
              onChange={setRestaurantDetailsOnChange}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </div>

          <div className="select-grid">
            <div>Select Postal Code</div>
            <TextField
              size="small"
              label=""
              type="number"
              onKeyDown={blockInvalidChar}
              name="pinCode"
              disabled={Number.isInteger(parseInt(caseId))}
              className="input"
              value={restaurantDetails?.pinCode || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="sub-header">Alternate Contact</div>
          <div className="select-grid">
            <span>
              Name <span className="warning-text">*</span>{" "}
            </span>
            <TextField
              size="small"
              label=""
              className="input"
              disabled={Number.isInteger(parseInt(caseId))}
              name="alternateContact.name"
              value={restaurantDetails?.alternateContact?.name || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <span>
              Email <span className="warning-text">*</span>{" "}
            </span>
            <TextField
              size="small"
              label=""
              name="alternateContact.email"
              disabled={Number.isInteger(parseInt(caseId))}
              type="email"
              className="input"
              value={restaurantDetails?.alternateContact?.email || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <span>
              Phone <span className="warning-text">*</span>{" "}
            </span>
            <TextField
              size="small"
              label=""
              name="alternateContact.phone"
              type="number"
              onKeyDown={blockInvalidChar}
              disabled={Number.isInteger(parseInt(caseId))}
              className="input"
              value={restaurantDetails?.alternateContact?.phone || ""}
              onChange={setRestaurantDetailsOnChange}
            />
          </div>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={5} xs={12}>
          {(complaintType
            ? complaintType === "PRODUCT"
            : formDetails?.type?.value === "PRODUCT") && (
            <div>
              <div className="select-grid">
                <div>Select subway product code</div>
                <Select
                  options={productList}
                  placeholder="Subway Product Code"
                  className="input"
                  isDisabled={disableEditingProductInformation}
                  classNamePrefix="subway-select"
                  name="productInfo.code"
                  value={formDetails?.productInfo?.code || null}
                  onChange={setFormDetailsOnChange}
                />
              </div>

              <div className="select-grid">
                <div>
                  Units Affected <span className="warning-text">*</span>
                </div>
                <TextField
                  size="small"
                  type="number"
                  className="input"
                  onKeyDown={blockInvalidChar}
                  // disabled={disableEditingProductInformation}
                  label=""
                  name="productInfo.unitsAffected"
                  value={formDetails?.productInfo?.unitsAffected || ""}
                  onChange={setFormDetailsOnChange}
                />
              </div>

              <div className="select-grid">
                <div>
                  Select Unit Of Measure <span className="warning-text">*</span>
                </div>
                <Select
                  options={unitOfMeasure}
                  placeholder="Unit of Measure"
                  className="input"
                  // isDisabled={disableEditingProductInformation}
                  classNamePrefix="subway-select"
                  name="productInfo.unitOfMeasure"
                  value={formDetails?.productInfo?.unitOfMeasure || null}
                  onChange={setFormDetailsOnChange}
                />
              </div>
              {!isEditingCase && (
                <div className="select-grid">
                  <div>Select DC</div>
                  <div>
                    <Select
                      options={dcList}
                      placeholder="DC"
                      classNamePrefix="subway-select"
                      className="input"
                      name="productInfo.dc"
                      isDisabled={restaurantDetails.id ? true : false}
                      value={formDetails?.productInfo?.dc || null}
                      onChange={setFormDetailsOnChange}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 3 }),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="sub-header">Additional Information</div>
          <div className="select-grid">
            <div>
              Lot Code
              <span className="warning-text">*</span>
            </div>
            <TextField
              size="small"
              label=""
              className="input"
              disabled={Number.isInteger(parseInt(caseId))}
              name="additionalInformation.lotCode"
              value={formDetails?.additionalInformation?.lotCode || ""}
              onChange={setFormDetailsOnChange}
            />
          </div>
          <div className="select-grid">
            <div>
              DC Invoice
              <span className="warning-text">*</span>
            </div>
            <div>
              <TextField
                size="small"
                label=""
                className="input"
                disabled={Number.isInteger(parseInt(caseId))}
                type="number"
                onKeyDown={blockInvalidChar}
                name="additionalInformation.dcInvoice"
                value={formDetails?.additionalInformation?.dcInvoice || ""}
                onChange={setFormDetailsOnChange}
              />
              {!formDetails?.additionalInformation?.dcInvoice && (
                <p className="helper-text">DC Invoice should be Number</p>
              )}
            </div>
          </div>
          <div className="select-grid">
            <div>
              Select Delivery Date
              <span className="warning-text">*</span>{" "}
            </div>
            <TextField
              size="small"
              type="date"
              disabled={Number.isInteger(parseInt(caseId))}
              InputLabelProps={{ shrink: true }}
              className="input"
              label="Delivery Date"
              name="additionalInformation.deliveryDate"
              value={formDetails?.additionalInformation?.deliveryDate || ""}
              onChange={setFormDetailsOnChange}
            />
          </div>
          <div className="select-grid">
            <div>Select Production Date</div>
            <TextField
              size="small"
              type="date"
              disabled={Number.isInteger(parseInt(caseId))}
              InputLabelProps={{ shrink: true }}
              label="Production Date"
              className="input"
              name="additionalInformation.productionDate"
              value={formDetails?.additionalInformation?.productionDate || ""}
              onChange={setFormDetailsOnChange}
            />
          </div>
          <div className="select-grid">
            <div>Select Expiration Date</div>
            <TextField
              size="small"
              type="date"
              InputLabelProps={{ shrink: true }}
              disabled={Number.isInteger(parseInt(caseId))}
              label="Expiration Date"
              className="input"
              name="additionalInformation.expirationDate"
              value={formDetails?.additionalInformation?.expirationDate || ""}
              onChange={setFormDetailsOnChange}
            />
          </div>
          <div className="select-grid">
            <div>Select Pack Date</div>
            <TextField
              size="small"
              type="date"
              label="Pack Date"
              disabled={Number.isInteger(parseInt(caseId))}
              className="input"
              InputLabelProps={{ shrink: true }}
              name="additionalInformation.packDate"
              value={formDetails?.additionalInformation?.packDate || ""}
              onChange={setFormDetailsOnChange}
            />
          </div>

          <div className="sub-header">Complaint Info</div>

          <div className="select-grid">
            <div>
              Complaint Type
              <span className="warning-text">*</span>
            </div>
            <Select
              options={complaintTypeList}
              placeholder="Complaint Type"
              classNamePrefix="subway-select"
              className="input"
              name="complaintType"
              value={formDetails?.complaintType || null}
              onChange={setFormDetailsOnChange}
            />
          </div>
          <div className="select-grid">
            <div>
              Complaint Category
              <span className="warning-text"></span>
            </div>
            <Select
              options={COMPLAINT_CATEGORY_LIST}
              placeholder="Complaint Category"
              classNamePrefix="subway-select"
              className="input"
              name="complaintCategory"
              value={formDetails?.complaintCategory || null}
              onChange={setFormDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <div>
              Complaint Notes
              <span className="warning-text">*</span>
            </div>
            <TextareaAutosize
              aria-label="ComplaintNotes"
              minRows={3}
              className="input"
              placeholder="Complaint Notes"
              name="complaintNotes"
              value={formDetails?.complaintNotes || ""}
              onChange={setFormDetailsOnChange}
            />
          </div>

          <div className="select-grid">
            <div>
              Select Vendor{" "}
              {(complaintType === "PRODUCT" ||
                formDetails?.type?.value === "PRODUCT") && (
                <span>
                  Name <span className="warning-text">*</span>{" "}
                </span>
              )}
            </div>
            <Select
              options={vendorList}
              placeholder="Vendor"
              className="input"
              classNamePrefix="subway-select"
              name="vendor"
              value={formDetails.vendor || null}
              onChange={setFormDetailsOnChange}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </div>

          {Number.isInteger(parseInt(caseId)) && (
            <div className="select-grid">
              <div>Select severity</div>
              <Select
                options={SEVERITY_OPTIONS}
                placeholder="Select severity"
                classNamePrefix="subway-select"
                className="input"
                name="severity"
                value={formDetails.severity || null}
                onChange={setFormDetailsOnChange}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 3 }),
                }}
              />
            </div>
          )}

          {!isEditingCase && (
            <div className="select-grid">
              <div>Select DC</div>
              <Select
                options={dcList}
                placeholder="DC"
                classNamePrefix="subway-select"
                name="dc"
                className="input"
                isDisabled={restaurantDetails.id ? true : false}
                value={formDetails.dc || null}
                onChange={setFormDetailsOnChange}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 3 }),
                }}
              />
            </div>
          )}
          <div className="select-grid">
            <div>
              Select details
              <span className="warning-text">*</span>
            </div>
            <TextareaAutosize
              minRows={6}
              size="small"
              placeholder="Details"
              value={formDetails.details || ""}
              onChange={setFormDetailsOnChange}
              name="details"
              className="input"
            />
          </div>

          {!Number.isInteger(parseInt(caseId)) && (
            <div>
              <SubwayFileUpload
                title="Attachment"
                url={`${HOSTNAME}${REST_URLS.UPLOAD}`}
                tokenKey={tokenMainKeys.qnet}
                slug={appSlugs.qnet}
                callBack={(newFile) => {
                  setFiles([
                    ...files,
                    {
                      url: newFile[0].url,
                      title: newFile[0].originalname,
                    },
                  ]);
                }}
              />
              {files.map((f, index) => {
                return (
                  <div key={f.title}>
                    <span>{f.title}</span>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        files.splice(index, 1);
                        setFiles([...files]);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>
      {disableSaveButton(
        restaurantDetails,
        formDetails,
        originalCaseDetails,
        files
      ) && (
        <p className="helper-text" style={{ textAlign: "end" }}>
          Please fill out all required fields.
        </p>
      )}
      <div className="save-and-cancel-button">
        <Button
          onClick={() => {
            if (typeof loadData === "function") {
              loadData();
            }

            if (curPageStatuses && setCurPage) {
              setCurPage(curPageStatuses.home);
            }

            if (Number.isInteger(parseInt(caseId))) {
              navigate(-1);
            }
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={disableSaveButton(
            restaurantDetails,
            formDetails,
            originalCaseDetails,
            files
          )}
          onClick={updateStoreDetailsAndCreateCase}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ComplaintForm;
