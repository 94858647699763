import moment from "moment";
import { exportToExcel } from "../../../../common-utilities/utils";

export const downloadReports = (data) => {
  if (!data?.length) {
    return;
  }
  const structuredData = data.flatMap((budget) =>
    budget.lineItems.map((lineItem) => ({
      "Budget Id": budget?.id || "",
      "Financial Year": budget?.financialYear || null,
      "Site Name": budget.site?.name || null,
      "Site Address": budget.site?.address || null,
      City: budget.site?.city || null,
      State: budget.site?.state || null,
      "GST Number": budget.site?.gstNumber || null,
      "Budget Type": budget.budgetType || null,
      "Budget Status": budget.status || null,
      "Line Item Name": lineItem.name || null,
      "Initial Amount": lineItem.initialAmount || null,
      "Current Amount": lineItem.currentAmount || null,
      "Available Amount": lineItem.availableAmount || null,
      "Category Name": lineItem.category?.name || null,
      "Category Code": lineItem.category?.categoryCode || null,
      "Category Capex": lineItem.category?.capex ? "Yes" : "No",
      "Line Item Status": lineItem.status || null,
      "Creator Name": lineItem.creatorName || null,
      "Created At":
        (lineItem.createdAt &&
          moment(lineItem?.createdAt).format("DD-MM-YYYY")) ||
        null,
      "Updated At":
        (lineItem.updatedAt &&
          moment(lineItem?.updatedAt).format("DD-MM-YYYY")) ||
        null,
    }))
  );

  exportToExcel(structuredData, "budget_reports");
};
