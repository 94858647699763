import {
  competitorInformationConfig,
  nearbySubwayDataConfig,
  stageGateConfig,
} from "../config";
import { stageGatesDatesInOrder } from "../../../utils/constants";
import { exportToExcel } from "../../../../common-utilities/utils";
import { processExcelData } from "../utils/misc.utils";
import { getSortedStagGates } from "../../../utils";
export const fmtDwndRpt = (res) => {
  const competitorInformationData = [];
  const nearbySubwayDataForSheet = [];
  const stageGatesSheet = [];
  let competitorConfig = competitorInformationConfig();
  competitorConfig = competitorConfig.slice(0, competitorConfig.length - 1);
  let nearBySubwayConfig = nearbySubwayDataConfig();
  nearBySubwayConfig = nearBySubwayConfig.slice(
    0,
    nearBySubwayConfig.length - 1
  );
  let stageGatesConfig = stageGateConfig();
  stageGatesConfig = stageGatesConfig.slice(0, stageGatesConfig.length - 1);
  let sortedStagesGates;
  const results =
    res?.results?.map?.((item, index) => {
      const convertedItem = {};
      let createdBy = "";
      const {
        commercials = {},
        locationInfo = {},
        competitorInformation = {},
        nearbySubway = [],
        stageGates = [],
        id,
        storeNumber,
      } = item;
      convertedItem["Lead Id"] = id;
      convertedItem["Store Number"] = storeNumber;
      convertedItem["Created by"] = item?.createdBy?.name;
      createdBy = item?.createdBy?.name;
      {
        const { catchment, city, region, state, format } = locationInfo;
        convertedItem["Region"] = region;
        convertedItem["Catchment"] = catchment;
        convertedItem["City"] = city;
        convertedItem["State"] = state;
        convertedItem["Format"] = format;
        convertedItem["Status"] = locationInfo?.status?.currentStatus;
      }

      const allDates = {};
      {
        stageGates?.forEach?.((item) => {
          const name = item?.stage?.stageName;
          const date = item?.actualDate;
          let finalName = name?.toLowerCase?.()?.endsWith?.("date")
            ? name
            : `${name} Date`;
          allDates[finalName] = date || "";
        });
      }

      if (
        (!sortedStagesGates && stageGates?.length) ||
        stageGates?.length > sortedStagesGates?.length
      ) {
        sortedStagesGates = getSortedStagGates(stageGates);
      }
      if (index === 0) {
        stageGatesDatesInOrder.forEach((stageDate) => {
          convertedItem[stageDate] = null;
        });
      }

      sortedStagesGates?.forEach?.((item) => {
        const name = item?.stage?.stageName;

        const finalName = name?.toLowerCase?.()?.endsWith?.("date")
          ? name
          : `${name} Date`;
        convertedItem[finalName] = allDates[finalName];
      });

      {
        const { cam, del, dineIn, expectedRent, mg } = commercials;
        convertedItem["Expected Rent(In Rs)"] = expectedRent;
        convertedItem["MG(In Rs)"] = mg;
        convertedItem["RS% Dine in"] = dineIn;
        convertedItem["RS% Del"] = del;
        convertedItem["CAM"] = cam;
      }
      convertedItem["Built-Up Area (Sq. Ft.)"] = locationInfo?.builtArea;
      convertedItem["Carpet Area (Sq. Ft.)"] = locationInfo?.carperArea;

      {
        Object.keys(competitorInformation).forEach((item) => {
          const competitorData = competitorInformation[item];
          if (
            !Array.isArray(competitorData) &&
            typeof competitorData === "object"
          ) {
            const allData = {};
            allData["Lead Id"] = id;
            allData["Created by"] = createdBy;
            competitorConfig.forEach((competitorLabelKeyPair) => {
              allData[competitorLabelKeyPair.label] =
                competitorData[competitorLabelKeyPair.key];
            });
            competitorInformationData.push(allData);
          } else {
            competitorData.forEach((competitor) => {
              const allData = {};
              allData["Lead Id"] = id;
              allData["Created by"] = createdBy;
              competitorConfig.forEach((competitorLabelKeyPair) => {
                allData[competitorLabelKeyPair.label] =
                  competitor[competitorLabelKeyPair.key];
              });
              competitorInformationData.push(allData);
            });
          }
        });
      }

      {
        nearbySubway.forEach((item) => {
          const allData = {};
          allData["Lead Id"] = id;
          allData["Created by"] = createdBy;
          nearBySubwayConfig.forEach((nearbysubwayDataKeyValue) => {
            allData[nearbysubwayDataKeyValue.label] =
              item[nearbysubwayDataKeyValue.key];
          });
          nearbySubwayDataForSheet.push(allData);
        });
      }
      {
        stageGates.forEach((stageDateData) => {
          const allData = {};
          allData["Lead Id"] = id;
          allData["Created by"] = createdBy;
          stageGatesConfig.forEach((stageGateKeyValue) => {
            // if (stageGateKeyValue.value) {
            //   allData[stageGateKeyValue.label] =
            //     stageGateKeyValue.value(stageDateData);
            // } else {
            //   allData[stageGateKeyValue.label] =
            //     stageDateData[stageGateKeyValue.key];
            // }
            allData[stageGateKeyValue.label] =
              stageDateData[stageGateKeyValue.key];
          });
          stageGatesSheet.push(allData);
        });
      }

      return convertedItem;
    }) || [];
  const allReportsData = [
    { sheetName: "data", details: results },
    {
      sheetName: "Competitor Information",
      details: competitorInformationData,
    },
    {
      sheetName: "Nearby Subway Data",
      details: nearbySubwayDataForSheet,
    },
    {
      sheetName: "Stage Gates",
      details: stageGatesSheet,
    },
  ];
  exportToExcel(allReportsData, "Reports", true, null, processExcelData);
};
