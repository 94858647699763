import React from "react";
import CustomModal from "../../../../common-utilities/core/modal";
import { Typography, Button, Chip, Box } from "@mui/material";
import { trackPromise } from "react-promise-tracker";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import {
  INTRANETHOSTNAME,
  REST_URLS,
} from "../../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { getFileName } from "../../../utils/common-utils";
import DownloadIcon from "@mui/icons-material/Download";

const GrnDeclarationModal = ({
  formDetails,
  setFormDetails,
  setIsDeclarationModal,
  onSave,
}) => {
  const docUrl =
    "https://idev.blob.core.windows.net/idev1/Declaration%20from%20MSME%20Vendor%20for%20waiver%20of%20claim%20-%201742541195130.docx";
  const downloadDeclarationForm = () => {
    trackPromise(
      fetch(docUrl).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download =
            "Declaration from MSME Vendor for waiver of claim.docx";
          alink.click();
        });
      })
    );
  };
  return (
    <div>
      {" "}
      <CustomModal
        title="GRN Declaration"
        onClose={() => {
          delete formDetails?.msmeDeclaration;
          setIsDeclarationModal(false);
        }}
      >
        <Typography variant="h6">
          We need signed & stamped declaration from vendor on his letter head.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
          <Button
            variant="outlined"
            onClick={downloadDeclarationForm}
            color="warning"
            endIcon={<DownloadIcon />}
          >
            Download Declaration
          </Button>
          <FileUpload
            url={`${INTRANETHOSTNAME}${FINANCE_REST_URLS.UPLOAD_API}`}
            callBack={(response) => {
              const url = response?.data?.url;
              if (url) {
                setFormDetails((prevVal) => ({
                  ...prevVal,
                  msmeDeclaration: url,
                }));
              }
            }}
          />
        </Box>
        <Box sx={{ mt: 2, mb: 5 }}>
          {formDetails?.msmeDeclaration && (
            <Chip
              label={getFileName(formDetails?.msmeDeclaration)}
              onClick={() =>
                window.open(formDetails?.msmeDeclaration, "_blank")
              }
              sx={{ maxWidth: "50%" }}
            />
          )}
        </Box>

        <Button
          variant="contained"
          onClick={() => {
            setIsDeclarationModal(false);
            onSave({ sendForApproval: true });
          }}
          color="info"
          fullWidth
          disabled={!formDetails?.msmeDeclaration}
        >
          Submit GRN With Declaration
        </Button>
      </CustomModal>
    </div>
  );
};

export default GrnDeclarationModal;
