import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  getHeaderConfig,
  getGRNOrderAmount,
  getGRNOrderTaxAmount,
  getGRNOrderAmountWithTax
} from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Button,
  IconButton,
  Link,
  TextareaAutosize,
  TextField,
  Typography
} from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate
} from "../../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { isApprovedAllowed } from "../../../utils/common-utils";
import { hasApproveAccessNow } from "../../../utils/utils";
import ApprovalStageDetails from "../../approval-stage-details";

const GRNDetails = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rejectionDetails, setRejectionDetails] = useState(null);
  const [reconsiderDetails, setReconsiderDetails] = useState(null);
  const [grnDetails, setGRNDetails] = useState({});
  const [poDetails, setPoDetails] = useState({});
  const [filters, setFilters] = useState({
    approved: false,
    rejected: false
  });

  const { stages = [], receivedItems = [] } = grnDetails;
  const {
    site,
    items = [],
    vendorName = "",
    vendorCode = "",
    vendorGst = "",
    vendorPancard = ""
  } = poDetails;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_GRN}${id}`,
      null
    )
      .then((response) => {
        if (response.message) {
        } else if (response.id) {
          setGRNDetails(response || {});
          setPoDetails(response.smPoId || {});
        }
      })
      .catch((err) => {});
  };

  const onApprove = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_GRN}approval/${id}`,
      { status: rejectionDetails.status, comment: rejectionDetails.comment }
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to update details");
        } else {
          toast.success("Approved successfully");
          loadData();
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className='grn-details-container'>
      <div className='grn-details-header'>
        <div className='left-container'>
          <div className='back-title'>
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=2");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className='title'>GRN Details</Typography>
          </div>
          <div className='grn-form-container'>
            <div className='info-section'>
              <div className='info-box'>
                <span>GRN Number </span>
                <span>{grnDetails.grnNo}</span>
              </div>
              <div className='info-box'>
                <span>PO Number </span>
                <span>{poDetails.poNumber}</span>
              </div>
              <div className='info-box'>
                <span>Site Code</span>
                <span>{site?.code || ""}</span>
              </div>
              <div className='info-box'>
                <span>Site Name </span>
                <span>{site?.name || ""}</span>
              </div>
              <div className='info-box'>
                <span>Attachment </span>
                <Link href={grnDetails.attachment} target='_blank'>
                  {grnDetails.attachment ? "GRN DOC" : "NA"}
                </Link>
              </div>
              <div className='info-box'>
                <span> PO Date </span>
                <span>
                  {formatDate(poDetails.poDate, DATE_FORMATS["DD-MM-YYYY"])}
                </span>
              </div>
              <div className='info-box'>
                <span> GRN Status </span>
                <span>
                  {convertToCamelCaseFromUnderScore(grnDetails.status)}
                </span>
              </div>
            </div>
          </div>
        </div>
        {grnDetails.status === "APPROVAL_IN_PROGRESS" &&
        hasApproveAccessNow(grnDetails) ? (
          <div className='right-section'>
            <Button
              variant='outlined'
              onClick={() => setRejectionDetails({ status: "APPROVED" })}
            >
              Approve
            </Button>
            <Button
              variant='outlined'
              color='error'
              onClick={() => setRejectionDetails({ status: "REJECTED" })}
            >
              Reject
            </Button>
            <Button
              variant='outlined'
              color='error'
              onClick={() => setRejectionDetails({ status: "RECONSIDER" })}
            >
              Reconsider
            </Button>
          </div>
        ) : null}
      </div>
      <div className='po-detail-approval-stage'>
        <div className='po-details'>
          <div className='form-container'>
            <div className='info-section'>
              <div className='info-box'>
                <span>Vendor Code </span>
                <span>{vendorCode || ""}</span>
              </div>
              <div className='info-box'>
                <span>Vendor Name </span>
                <span>{vendorName || ""}</span>
              </div>
              <div className='info-box'>
                <span>Vendor GST </span>
                <span>{vendorGst || ""}</span>
              </div>
              <div className='info-box'>
                <span>Vendor PAN Number</span>
                <span>{vendorPancard || ""}</span>
              </div>
              <div className='info-box'>
                <span> GRN Amount </span>
                <span>
                  {getGRNOrderAmount(items, receivedItems)}
                  {poDetails?.currency?.name || "INR"}
                </span>
              </div>
              <div className='info-box'>
                <span> GRN Tax Amount </span>
                <span>
                  {getGRNOrderTaxAmount(items, receivedItems)}
                  {poDetails?.currency?.name || "INR"}
                </span>
              </div>
              <div className='info-box'>
                <span> GRN Total Amount</span>
                <span>
                  {getGRNOrderAmountWithTax(items, receivedItems)}
                  {poDetails?.currency?.name || " INR"}
                </span>
              </div>
              <div className='info-box'>
                <span>Material/Service Received date </span>
                <span>
                  {(grnDetails &&
                    formatDate(
                      grnDetails.inwardDate,
                      DATE_FORMATS["DD-MM-YYYY"]
                    )) ||
                    ""}
                </span>
              </div>
              <div className='info-box'>
                <span>Invoice date </span>
                <span>
                  {(grnDetails &&
                    formatDate(
                      grnDetails.invoiceDate,
                      DATE_FORMATS["DD-MM-YYYY"]
                    )) ||
                    ""}
                </span>
              </div>
              <div className='info-box'>
                <span>Invoice Number </span>
                <span>
                  {grnDetails.invoiceNumber || " -"} 
                </span>
              </div>

              <div className='info-box'>
                <span>Creator Name </span>
                <span>{(grnDetails && grnDetails.creatorName) || ""}</span>
              </div>
            </div>
          </div>
          <div className='form-container'>
            <MuiTable
              columnsList={getHeaderConfig(receivedItems)}
              dataList={
                items?.slice?.(
                  ((filters?.page || 1) - 1) * 10,
                  ((filters?.page || 1) - 1) * 10 + 10
                ) || []
              }
              pageCount={Math.ceil(items.length / 10)}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page
                });
              }}
            />
          </div>
        </div>

        <div className='approval-details'>
          <ApprovalStageDetails approvalDetails={grnDetails} />
        </div>
      </div>
      {rejectionDetails && (
        <CustomModal
          title={
            rejectionDetails.status === "APPROVED"
              ? "Approve GRN"
              : rejectionDetails.status === "REJECTED"
              ? "Reject GRN"
              : "Reconsider GRN"
          }
          disabledOutsideClick={true}
          onClose={() => {
            setRejectionDetails(null);
          }}
        >
          <div className='b-modal-form-container'>
            <TextareaAutosize
              label='Comments'
              placeholder='Enter your comments here'
              value={rejectionDetails.comment}
              onChange={(e) =>
                setRejectionDetails({
                  ...rejectionDetails,
                  comment: e.target.value
                })
              }
              minRows={4}
              fullWidth
              style={{ width: "100%", marginTop: 10 }}
            />

            <Button
              variant='outlined'
              onClick={onApprove}
              fullWidth
              disabled={!rejectionDetails.comment}
            >
              {rejectionDetails.status === "APPROVED"
                ? "Approve"
                : rejectionDetails.status === "REJECTED"
                ? "Reject"
                : "Reconsider"}
            </Button>
          </div>
        </CustomModal>
      )}

      {reconsiderDetails && (
        <CustomModal
          title={
            rejectionDetails.status === "APPROVED"
              ? "Approve GRN"
              : rejectionDetails.status === "REJECTED"
              ? "Reject GRN"
              : "Reconsider GRN"
          }
          disabledOutsideClick={true}
          onClose={() => {
            setReconsiderDetails(null);
          }}
        >
          <div className='b-modal-form-container'>
            <TextField
              value={reconsiderDetails.comment}
              onChange={(e) =>
                setReconsiderDetails({
                  ...reconsiderDetails,
                  comment: e.target.value
                })
              }
              size='small'
              name='comment'
              label='comment'
              fullWidth
            />
            <Button
              color='error'
              variant='outlined'
              onClick={onApprove}
              fullWidth
              disabled={!reconsiderDetails.comment}
            >
              {reconsiderDetails.status}
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default GRNDetails;
