import { Button } from "@mui/material";

export const getHeaderConfig = editVendor => {
  const headers = [
    {
      label: "Name",
      id: "name"
    },
    {
      label: "Site",
      id: "site"
    },
    {
      label: "PO Type",
      id: "poType"
    },
    {
      label: "Status",
      id: "status"
    },
    {
      label: "Actions",
      id: "actions",
      render: data => {
        return (
          <Button variant="text" onClick={() => editVendor(data, "EDIT")}>
            View
          </Button>
        );
      }
    }
  ];
  return headers;
};

export const PO_TYPES = [
  { label: "Capex", value: "capex_po" },
  { label: "Opex", value: "opex_po" }
];

export const getPOAmount = lineItems => {
  let sum = 0;
  Object.keys(lineItems).forEach(key => {
    lineItems[key].forEach(item => {
      sum = +sum + +getTotalAmount(item);
    });
  });

  return sum?.toFixed?.(2);
};

const getTotalAmount = (item, taxKey = "taxRate") => {
  const { rate = 0, quantity = 0 } = item;
  const taxRate = item?.[taxKey] || 0;
  let taxAmount = rate * quantity * (taxRate / 100);
  return rate * quantity + +taxAmount;
};

export const getPOLineItemAmount = (lineItems, taxKey = "taxRate") => {
  let sum = 0;
  lineItems.forEach(item => {
    sum = +sum + +getTotalAmount(item, taxKey);
  });

  return sum?.toFixed?.(2);
};

export const getPOAmountWithoutTax = lineItems => {
  let sum = 0;
  Object.keys(lineItems).forEach(key => {
    lineItems[key].forEach(item => {
      const { rate = 0, quantity = 0 } = item;
      sum = +sum + rate * quantity;
    });
  });

  return sum?.toFixed?.(2);
};
export const getPOTaxAmount = lineItems => {
  let sum = 0;
  Object.keys(lineItems).forEach(key => {
    lineItems[key].forEach(item => {
      const { rate = 0, quantity = 0, taxRate = 0 } = item;
      let taxAmount = rate * quantity * (taxRate / 100);
      sum = +sum + taxAmount;
    });
  });

  return sum?.toFixed?.(2);
};

export const getAdvanceAmount = (poAmount, percentage) => {
  if (percentage > 0) {
    return (percentage * poAmount) / 100;
  } else {
    return 0;
  }
};

export const availableBudgetAmount = lineItems => {
  let sum = 0;
  lineItems
    .filter(i => ["APPROVAL_IN_PROGRESS", "approved"].includes(i.status))
    .forEach(item => {
      sum = sum + +item.availableAmount;
    });

  return sum?.toFixed?.(2);
};

export const createPOavailableBudgetAmount = (lineItems, includeItems = []) => {
  let sum = 0;
  lineItems
    ?.filter?.(
      i =>
        ["APPROVAL_IN_PROGRESS", "approved"].includes(i.status) &&
        includeItems.includes(i.id)
    )
    ?.forEach(item => {
      sum = sum + +item.availableAmount;
    });

  return sum?.toFixed?.(2);
};

export const getPurchaseOrderAmount = lineItems => {
  let sum = 0;
  lineItems?.forEach?.(item => {
    sum += item.quantity * (1 + item.taxRate / 100) * item.rate;
  });

  return sum?.toFixed?.(2);
};

export const getPurchaseOrderWithoutTaxAmount = lineItems => {
  let sum = 0;
  lineItems?.forEach?.(item => {
    sum += item.quantity * item.rate;
  });
  return sum?.toFixed?.(2);
};

export const getPurchaseOrderTaxAmount = lineItems => {
  let sum = 0;
  lineItems?.forEach?.(item => {
    sum += (item.rate * item.quantity * item.taxRate) / 100;
  });

  return sum?.toFixed?.(2);
};

export const getHeaderPOItemsDetailsConfig = () => {
  const headers = [
    {
      label: "Item Code",
      id: "itemCode"
    },
    {
      label: "Item Description",
      id: "itemDescription"
    },
    {
      label: "HSN Code",
      id: "hsnCode"
    },
    {
      label: "UOM",
      id: "uom"
    },
    {
      label: "Qty",
      id: "quantity"
    },
    {
      label: "Case Size",
      id: "caseSize"
    },
    {
      label: "Type",
      id: "itemType"
    },
    {
      label: "Price",
      id: "rate"
    },
    {
      label: "Tax rate",
      id: "taxRate"
    },
    {
      label: "Amount",
      id: "amount",
      render: data => {
        const { amount = 0 } = data;
        return Math.round(amount).toFixed(2);
      }
    },
    {
      label: "Tax Amt",
      id: "taxAmount",
      render: data => {
        const { taxAmount = 0 } = data;
        return Math.round(taxAmount).toFixed(2);
      }
    },
    {
      label: "Total Amt",
      id: "totalAmount",
      render: data => {
        const { totalAmount = 0 } = data;
        return Math.floor(totalAmount).toFixed(2);
      }
    }
  ];
  return headers;
};

export const PO_ITEM_MODEL = {
  id: Math.random(),
  mainCategoryName: "",
  mainCategoryId: "",
  subcategoryName: "",
  subcategoryId: "",
  name: "",
  uom: "",
  quantity: 0,
  rate: 0,
  taxRate: 0,
  specification: "",
  itemId: "",
  lineItemId: "",
  refRate: 0,
  lineItemName: "",
  availableAmount: 0
};

export const UOM_LIST = [
  { label: "Unit", value: "UNIT" },
  { label: "MTR", value: "MTR" },
  {
    label: "Kg",
    value: "KG"
  },
  {
    label: "Ltr",
    value: "LTR"
  },
  {
    label: "Yard",
    value: "YARD"
  },
  {
    label: "Pack And Pack Pack Size",
    value: "PACK_AND_PACK_SIZE"
  },
  {
    label: "Set",
    value: "SET"
  },
  {
    label: "Square Feet",
    value: "SQUARE_FEET"
  },
  {
    label: "Nos",
    value: "NOS"
  }
];

export const PO_STATUS = [
  { label: "Created", value: "created" },
  { label: "Draft", value: "draft" },
  { label: "Approval In Progress", value: "approval_in_progress" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Inactive", value: "inactive" },
  { label: "Not Initiated", value: "not_initiated" },
  { label: "Reconsider", value: "reconsider" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Short Close", value: "short_close" }
];
