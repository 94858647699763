import React, { useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Button, Stack } from "@mui/material";
import "./index.scss";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import FinancialYearDropdown from "../../home/financial-year-dropdown";
import { toast } from "react-toastify";

const BudgetBulkHold = ({ onSuccess }) => {
  const [formDetails, setFormDetails] = useState({});

  const onSubmit = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.HOLD_BUDGETS}`,
      {
        financialYear: formDetails?.financialYear
      }
    )
      .then((response) => {
        if (response?.status === "inactive") {
          onSuccess(response);
        } else {
          toast.error("Error in holding budgets");
        }
      })
      .catch((err) => {});
  };

  const { budgetType = "", selectedSite = {} } = formDetails;

  return (
    <div className='upload-budget'>
      <FinancialYearDropdown
        filters={formDetails}
        setFilters={setFormDetails}
        isFullWidth={true}
      />
      <Stack direction={"row"} spacing={2} className='upload-budget'>
        <Button variant='contained' onClick={onSubmit}>
          Hold All in {formDetails.financialYear}
        </Button>
      </Stack>
    </div>
  );
};
export default BudgetBulkHold;
