import { Button, Checkbox } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { checkIsApprover, hasApproveAccessNow } from "../../../utils/utils";
import { convertToCamelCaseFromUnderScore } from "../../../../common-utilities/utils";
import { isApprovedAllowed } from "../../../utils/common-utils";

export const getHeaderConfig = (
  editVendor,
  filters,
  setFilters,
  status,
  loadData,
  bulkSelectIds,
  setBulkSelectIds
) => {
  const headers = [
    {
      label: "GRN Number",
      id: "grnNo",
      headerRender: () => {
        return (
          <div className='header-column-arrow'>
            GRN Number
            {filters?.sortBy === "-createdAt" ? (
              <KeyboardArrowDownIcon
                onClick={() => {
                  loadData({ ...filters, page: 1, sortBy: "createdAt" });
                  setProperty("sortBy", "createdAt", setFilters);
                }}
                className='download-icon'
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={() => {
                  loadData({ ...filters, page: 1, sortBy: "-createdAt" });
                  setProperty("sortBy", "-createdAt", setFilters);
                }}
                className='download-icon'
              />
            )}
          </div>
        );
      }
    },
    {
      label: "PO Number",
      id: "poNumber"
    },
    {
      label: "Amount",
      id: "amount",
      render: (data) => {
        return Math.round(data.amount).toFixed(2);
      }
    },
    {
      label: "Tax Amt (₹)",
      id: "taxAmount",
      render: (data) => {
        return Math.round(data.taxAmount).toFixed(2);
      }
    },
    {
      label: "Total Amt (₹)",
      id: "totalAmount",
      render: (data) => {
        return Math.round(data.totalAmount).toFixed(2);
      }
    }
  ];

  if (status === "all" || status === "APPROVAL_IN_PROGRESS") {
    headers.push({
      label: "Pending With",
      // label: "Approver",
      id: "pendingWithRole"
    });
  }

  if (status === "all" || status === "APPROVAL_IN_PROGRESS") {
    headers.push({
      label: "Status",
      id: "status",
      render: (data) => {
        return convertToCamelCaseFromUnderScore(data.status);
      }
    });
  }

  if (status === "APPROVAL_IN_PROGRESS" && isApprovedAllowed()) {
    headers.unshift({
      label: "",
      headerRender: () => {
        return (
          <Checkbox
            color='primary'
            checked={bulkSelectIds.includes("all")}
            onChange={() => {
              if (bulkSelectIds.includes("all")) {
                setBulkSelectIds(bulkSelectIds?.filter((id) => id !== "all"));
              } else {
                setBulkSelectIds(["all"]);
              }
            }}
          />
        );
      },
      id: "checknox",
      render: (data) => {
        return (
          <>
            <Checkbox
              checked={
                hasApproveAccessNow(data)
                  ? bulkSelectIds.includes(data.id) ||
                    bulkSelectIds.includes("all")
                  : false
              }
              disabled={!hasApproveAccessNow(data)}
              color='primary'
              onChange={() => {
                if (bulkSelectIds.includes(data.id)) {
                  setBulkSelectIds(
                    bulkSelectIds?.filter((id) => id !== data.id)
                  );
                } else {
                  setBulkSelectIds([data.id, ...bulkSelectIds]);
                }
              }}
            />
          </>
        );
      }
    });
  }

  headers.push(
    {
      label: "Created By",
      id: "creatorName"
    },
    {
      label: "View",
      id: "view",
      render: (data) => {
        return (
          <>
            <div className='po-action-btns'>
              {/* <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  downloadPO(data);
                }}
              >
                <DownloadIcon
                  color='primary'
                  className='download-icon'
                  fontSize='medium'
                />
              </IconButton> */}
              {status === "RECONSIDER" && (
                <Button variant='text' onClick={() => editVendor(data, "EDIT")}>
                  Edit
                </Button>
              )}

              <Button variant='text' onClick={() => editVendor(data, "VIEW")}>
                View
              </Button>
            </div>
          </>
        );
      }
    }
  );

  return headers;
};
