import React, { useEffect, useState } from "react";
import {
  FINANCE_HOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  createPayloadMapper,
  getGRNReceivedQuantityAmount,
  getGRNReceivedQuantityBaseAmount,
  getGRNReceivedQuantityTaxAmount,
  getGRNCreateHeaderConfig,
} from "./helper";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Button, Chip, IconButton, TextField, Typography } from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from "../../../../common-utilities/utils";
import { toast } from "react-toastify";
import MuiTable from "../../../../common-utilities/core/mui-table";
import DownloadTemplateButton from "../../master-data/common-components/upload-template";
import { uploadTemplates } from "../../master-data/constants";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import {
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
  getPurchaseOrderWithoutTaxAmount,
} from "../../purchase-order/helper";
import GrnCreatedModal from "../modal/grn-created.modal";
import {
  getUploadAttachmentQueryParams,
  uploadAttachmentTypes,
} from "../../../utils/attachments";
import GrnDeclarationModal from "../modal/grn-declaration-modal";

const CreateGRN = ({}) => {
  const navigate = useNavigate();
  const { poId } = useParams();
  const [grnItems, setGRNItems] = useState([]);
  const [poDetails, setPODetails] = useState([]);
  const [grnModalDetails, setGrnModalDetails] = useState(null);
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
  });

  const [formDetails, setFormDetails] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [invoiceUrls, setInvoiceUrls] = useState([]);
  const [isDeclarationModal, setIsDeclarationModal] = useState(false);
  const onAttachmentsDelete = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };

  const onInvoiceUrlsDelete = (index) => {
    invoiceUrls.splice(index, 1);
    setInvoiceUrls([...invoiceUrls]);
  };

  const onInvoiceInputChange = (e) => {
    setFormDetails({
      ...formDetails,
      [e.target.name]: e.target.value,
    });
  };

  const {
    budget = {},
    company = {},
    poType = "",
    items = [],
    poNumber = "",
    vendorName = "",
    vendorCode = "",
    vendorGst = "",
    vendorPancard = "",
  } = poDetails;
  const { siteName = "", siteCode } = budget || {};

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.PO}`,
      null,
      {
        page: 1,
        limit: 10,
        _id: poId,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.results.length > 0) {
          setPODetails(response.results[0] || {});
          let items = (response.results[0].items || []).reduce((list, item) => {
            if (item.allowInward) {
              list.push(item);
            }
            return list;
          }, []);
          setGRNItems(items);
          const { poDate } = response.results[0];
          if (poDate)
            setFormDetails({
              financialyear: new Date(poDate).getFullYear(),
            });
        }
      })
      .catch((err) => {});
  };
  const isMsmeVendor = async (vendorCode) => {
    try {
      const res = await invokeApi(
        HTTP_METHODS.GET,
        `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.CHECK_MSME_VENDOR}`,
        null,
        { code: vendorCode }
      );
      return res?.message;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  function checkInvoiceDateIsGreaterThan30Days() {
    const invoiceDate = new Date(formDetails.invoiceDate);
    const currentDate = new Date();

    const differenceInTime = currentDate - invoiceDate;
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
    if (differenceInDays > 30) {
      return true;
    } else {
      return false;
    }
  }
  const createGRNHandler = (query) => {
    let payload = createPayloadMapper(
      poDetails,
      grnItems,
      attachments,
      invoiceUrls,
      formDetails
    );
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GRN_LIST}`,
      payload,
      query
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed Create grn");
        } else {
          setGrnModalDetails({
            title: "GRN Created Successfully",
            data: { ...response, poDetails },
            onOk: () => {
              navigate(`/subway-finance/grn-order/${response.id}`);
            },
          });
        }
      })
      .catch((err) => {});
  };

  const onSave = async (query) => {
    const checkInvoiceDateDays = checkInvoiceDateIsGreaterThan30Days() || false;

    if (vendorCode) {
      const isMsme = await isMsmeVendor(vendorCode);
      if (isMsme && checkInvoiceDateDays) {
        setIsDeclarationModal(true);
      } else {
        createGRNHandler(query);
      }
    } else {
      createGRNHandler(query);
    }
  };

  const onInputChange = (event, data) => {
    const index = grnItems.findIndex((d) => d._id === data._id);
    if (index !== -1) {
      grnItems[index][event.target.name] = event.target.value;
    }
    setGRNItems([...grnItems]);
  };
  const onLineItemsUpload = (response) => {
    const { data } = response;
    let uploadedReceivedItems = [];
    items.forEach((element) => {
      const receivedItem = data.find((r) => r._id === element._id);
      receivedItem.hsnCode =
        receivedItem.hsnCode != undefined ? `${receivedItem.hsnCode}` : "";
      if (receivedItem) {
        if (!receivedItem?.hasOwnProperty?.("receivedQuantity")) {
          delete element?.receivedQuantity;
        }
        if (!receivedItem?.hasO)
          uploadedReceivedItems.push({
            ...element,
            ...receivedItem,
            _id: receivedItem._id,
          });
      }
    });
    setGRNItems(uploadedReceivedItems);
  };

  const isDisabled = (submitPo) => {
    return (
      !formDetails.invoiceDate ||
      !formDetails.invoiceNumber ||
      !formDetails.invoiceAmount ||
      formDetails.invoiceAmount != getGRNReceivedQuantityAmount(grnItems)
    );
    // ||
    // submitPo
    // ? !invoiceUrls?.length
    // : false;
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <div className="create-grn-container">
      <div className="grn-create-header">
        <div className="left-container">
          <div className="back-title">
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=2");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className="title">Create GRN</Typography>
          </div>
          <div className="grn-info-section">
            <div className="info-box">
              <span>GRN Base Amt </span>
              <span>{getGRNReceivedQuantityBaseAmount(grnItems || [])}</span>
            </div>

            <div className="info-box">
              <span>GRN Tax Amt </span>
              <span>{getGRNReceivedQuantityTaxAmount(grnItems || [])}</span>
            </div>
            <div className="info-box">
              <span>GRN Total Amt </span>
              <span>{getGRNReceivedQuantityAmount(grnItems || [])}</span>
            </div>
          </div>
        </div>

        <div className="right-container">
          <Button
            variant="outlined"
            onClick={() => onSave()}
            disabled={isDisabled()}
          >
            Save As Draft
          </Button>
          <Button
            variant="outlined"
            onClick={() => onSave({ sendForApproval: true })}
            disabled={isDisabled(true) || invoiceUrls?.length === 0}
          >
            Submit GRN
          </Button>
        </div>
      </div>
      <div className="form-container">
        <div className="grn-info-section">
          <div className="info-box">
            <span>PO Number </span>
            <span>{poNumber || ""}</span>
          </div>
          <div className="info-box">
            <span>Site Code </span>
            <span>{siteCode || ""}</span>
          </div>
          <div className="info-box">
            <span>Site Name</span>
            <span>{siteName || ""}</span>
          </div>
          <div className="info-box">
            <span>Company Name </span>
            <span>{company.name || ""}</span>
          </div>
          <div className="info-box">
            <span>PO Type </span>
            <span>{convertToCamelCaseFromUnderScore(poType)}</span>
          </div>
          <div className="info-box">
            <span>PO Base Amt </span>
            <span>{getPurchaseOrderWithoutTaxAmount(items || [])}</span>
          </div>
          <div className="info-box">
            <span>PO GST Amt </span>
            <span>{getPurchaseOrderTaxAmount(items || [])}</span>
          </div>
          <div className="info-box">
            <span>PO Total value </span>
            <span>{getPurchaseOrderAmount(items || [])}</span>
          </div>
          <div className="info-box">
            <span>Vendor Code </span>
            <span>{vendorCode || ""}</span>
          </div>
          <div className="info-box">
            <span>Vendor Name </span>
            <span>{vendorName || ""}</span>
          </div>
          <div className="info-box">
            <span>Vendor GST </span>
            <span>{vendorGst || ""}</span>
          </div>
          <div className="info-box">
            <span>Vendor PAN Number </span>
            <span>{vendorPancard || ""}</span>
          </div>
          <div className="info-box">
            <span>Financial Year</span>
            <span>{formDetails.financialyear || ""}</span>
          </div>
        </div>
      </div>
      <div className="grn-invoice-details-container">
        <TextField
          label="Material/Service Received date *"
          fullWidth
          size="small"
          type="date"
          name="inwardDate"
          // inputProps={{ max: "2123-05-31" }}
          inputProps={{ max: getCurrentDate() }}
          value={formDetails.inwardDate || ""}
          InputLabelProps={{ shrink: true }}
          onChange={onInvoiceInputChange}
        />
        <TextField
          label="Invoice Date *"
          fullWidth
          size="small"
          type="date"
          name="invoiceDate"
          // inputProps={{ max: "2123-05-31" }}
          inputProps={{ max: getCurrentDate() }}
          value={formDetails.invoiceDate || ""}
          InputLabelProps={{ shrink: true }}
          onChange={onInvoiceInputChange}
        />
        <TextField
          label="Invoice Number *"
          fullWidth
          size="small"
          name="invoiceNumber"
          value={formDetails.invoiceNumber || ""}
          onChange={onInvoiceInputChange}
        />
        <TextField
          label="Invoice Amount *"
          type="number"
          fullWidth
          size="small"
          name="invoiceAmount"
          error={
            formDetails.invoiceAmount > 0 &&
            formDetails.invoiceAmount != getGRNReceivedQuantityAmount(grnItems)
          }
          value={formDetails.invoiceAmount || ""}
          onChange={onInvoiceInputChange}
          helperText={
            formDetails.invoiceAmount > 0 &&
            formDetails.invoiceAmount != getGRNReceivedQuantityAmount(grnItems)
              ? `Invoice Amt is not same as GRN Total Amt(${getGRNReceivedQuantityAmount(
                  grnItems
                )})`
              : ""
          }
        />
        <TextField
          label="Remarks"
          fullWidth
          size="small"
          name="remarks"
          value={formDetails.remarks || ""}
          onChange={onInvoiceInputChange}
        />
      </div>
      <div className="grn-file-upload-container">
        <div className="grn-uploader-attachments">
          <FileUpload
            uploadText="Upload Invoice Attachments *"
            id="invoice-attachments"
            clsName="invoice-additional-doc"
            url={`${FINANCE_HOSTNAME}${
              FINANCE_REST_URLS.UPLOAD_DOCUMENT
            }${getUploadAttachmentQueryParams(
              {
                poId: parseInt(poId),
                attachmentKey: "invoiceUrls",
                attachmentLabel: "Upload Invoice Attachments",
              },
              uploadAttachmentTypes.createGrn
            )}`}
            callBack={(response) => {
              const { data } = response;
              setInvoiceUrls([...invoiceUrls, data]);
            }}
            formDataKey="file"
          />
          <div className="attached-doc-list">
            {invoiceUrls.map((attachment, index) => {
              return (
                <Chip
                  label={attachment.originalName}
                  onDelete={() => onInvoiceUrlsDelete(index)}
                  onClick={() => window.open(attachment?.docUrl, "_blank")}
                />
              );
            })}
          </div>
        </div>
        <div className="grn-uploader-attachments">
          <FileUpload
            uploadText="Upload Additional Documents"
            id="invoice-additional-doc"
            clsName="invoice-additional-doc"
            url={`${FINANCE_HOSTNAME}${
              FINANCE_REST_URLS.UPLOAD_DOCUMENT
            }${getUploadAttachmentQueryParams(
              {
                poId: parseInt(poId),
                attachmentKey: "additionalDocUrls",
                attachmentLabel: "Upload Additional Documents",
              },
              uploadAttachmentTypes.createGrn
            )}`}
            callBack={(response) => {
              const { data } = response;
              setAttachments([...attachments, data]);
            }}
            formDataKey="file"
          />
          <div className="attached-doc-list">
            {attachments.map((attachment, index) => {
              return (
                <Chip
                  label={attachment.originalName}
                  onDelete={() => onAttachmentsDelete(index)}
                  onClick={() => window.open(attachment?.docUrl, "_blank")}
                />
              );
            })}
          </div>
        </div>
        <FileUpload
          uploadText="Upload Received Items"
          clsName="add-additional-doc"
          id="po-items-excel"
          url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}grn-received-quantity/${poId}`}
          callBack={onLineItemsUpload}
          formDataKey="file"
        />
        <DownloadTemplateButton type={uploadTemplates.grn} data={grnItems} />
      </div>

      <div className="po-add-items-container">
        <div className="added-po-items-list">
          <MuiTable
            columnsList={getGRNCreateHeaderConfig(onInputChange)}
            dataList={grnItems || []}
            pageCount={Math.floor(grnItems.length / 10)}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
            }}
          />
        </div>
      </div>
      <GrnCreatedModal grnDetails={grnModalDetails} />
      {isDeclarationModal && (
        <GrnDeclarationModal
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          setIsDeclarationModal={setIsDeclarationModal}
          onSave={createGRNHandler}
        />
      )}
    </div>
  );
};

export default CreateGRN;
