import { formatDate } from "../../../common-utilities/utils";
import "./index.scss";
import { DATE_FORMATS } from "../../../../utils/constants";

const StageDetailsCard = ({ stageDetails, lastStage }) => {
  return (
    <>
      <div className={`stage-details-card ${stageDetails.stageStatusClass}`}>
        <div className='stage-header'>{stageDetails.status}</div>
        <div className='stage-details'>
          <div className='info-section'>
            <span>Stage </span>
            <span>{stageDetails.stageName}</span>
          </div>
          <div className='info-section'>
            <span>Approval Role </span>
            <span>{stageDetails.approverRole}</span>
          </div>
          <div className='info-section'>
            <span>Create on </span>
            <span>
              {formatDate(
                stageDetails.createdAt,
                DATE_FORMATS["DD.MM.YYYY hh:MM"]
              )}
            </span>
          </div>
          {stageDetails.createdBy && (
            <div className='info-section'>
              <span>Created By </span>
              <span>{stageDetails.createdBy}</span>
            </div>
          )}
          {stageDetails.dateLabel && (
            <div className='info-section'>
              <span>{stageDetails.dateLabel} </span>
              <span>
                {formatDate(
                  stageDetails.updatedAt,
                  DATE_FORMATS["DD.MM.YYYY hh:MM"]
                )}
              </span>
            </div>
          )}
          {stageDetails.comment && (
            <div className='info-section'>
              <span>Comment </span>
              <span>{stageDetails.comment}</span>
            </div>
          )}
           {stageDetails.reconsiderComment && (
            <div className='info-section'>
              <span>Reconsider Comment </span>
              <span>{stageDetails.reconsiderComment}</span>
            </div>
          )}
        </div>
      </div>
      {!lastStage && <div className='stage-details-line'></div>}
    </>
  );
};

export default StageDetailsCard;
