import {
  VENDOR_FORM_STATUS,
  VENDOR_ONBOARD_USERS,
} from "../../utils/constants";

export const VENDOR_FORM_REJECT_EMAIL_TEMPLATE = `
<p>Hi ,</p>
<p>
Dear Sir/Madam,
</p>
<p>Greetings from Eversub India Private Limited!</p>
<p>
Your request for vendor onboarding is rejected due to below reason.
</p>
<p>
Reason for Rejection :
</p>

<p>Thanks,</p>
<p>Subway-Team</p>
`;

export const REJECT_SUBJECT = " REJECTION of Vendor Onboarding Form";

export const isFormValid = (formDetails) => {
  const { email, subject } = formDetails;

  return !email || !subject;
};

export const VENDOR_DOCUMENT_TYPES = {
  PAN_CARD: "PAN_CARD",
  CANCEL_CHEQUE: "CANCEL_CHEQUE",
  GST_DOC: "GST_DOC",
  GST_DECLARATION: "GST_DECLARATION",
  MSME_DOC: "MSME_DOC",
  ATTACHMENT: "ATTACHMENT",
};
const checkFormStatus = (status, stageName, approvers) => {
  if ([VENDOR_FORM_STATUS.OPEN, VENDOR_FORM_STATUS.REOPENED].includes(status)) {
    return {
      status: "Waiting Response",
      color: "warning",
    };
  } else if (status === VENDOR_FORM_STATUS.REJECTED) {
    const rejectedData = approvers.find(
      (ele) => ele.status === VENDOR_FORM_STATUS.REJECTED
    );
    const approverObj = {
      VIVO_CREATOR: "Creator",
      HOD: "HOD",
      FINANCE_USER: "Finance",
    };
    if (rejectedData !== {}) {
      return {
        status: `Rejected by ${approverObj[rejectedData.role]}`,
        color: "error",
      };
    }
  } else {
    return {
      comment: "No comments",
      status: `${stageName} Approval Waiting`,
      color: "warning",
    };
  }
};
export const getCreatorApprovalData = (approvers, status) => {
  const creatorApprovalStatus =
    (approvers || []).find(
      (a) => a.role === VENDOR_ONBOARD_USERS.VIVO_CREATOR && a.status != ""
    ) || checkFormStatus(status, "Creator", approvers);
  if (creatorApprovalStatus.status === "REJECTED") {
    creatorApprovalStatus.color = "error";
  }
  return creatorApprovalStatus;
};
export const getHODApprovalData = (approvers, status) => {
  const creatorApprovalStatus = getCreatorApprovalData(approvers, status);
  const hodApprovalStatus =
    (approvers || []).find(
      (a) => a.role === VENDOR_ONBOARD_USERS.HOD && a.status != ""
    ) || checkFormStatus(status, "HOD", approvers);
  if (creatorApprovalStatus.status === "Creator Approval Waiting") {
    return creatorApprovalStatus;
  } else if (creatorApprovalStatus.status === "REJECTED") {
    return {
      comment: creatorApprovalStatus.comment,
      status: "Rejected by Creator",
      color: "error",
    };
  } else if (hodApprovalStatus.status === "REJECTED") {
    return {
      comment: hodApprovalStatus.comment,
      status: "Rejected by HOD",
      color: "error",
    };
  } else if (hodApprovalStatus.status === "APPROVED") {
    return hodApprovalStatus;
  }

  return hodApprovalStatus;
};

export const getFinanceApprovalData = (approvers, status) => {
  let creatorApprovalStatus = getCreatorApprovalData(approvers, status);
  let hodApprovalStatus = getHODApprovalData(approvers, status);
  let financeStatus =
    (approvers || []).find(
      (a) => a.role === VENDOR_ONBOARD_USERS.FINANCE_USER && a.status != ""
    ) || checkFormStatus(status, "Finance", approvers);
  if (creatorApprovalStatus.status === "Creator Approval Waiting") {
    return creatorApprovalStatus;
  } else if (creatorApprovalStatus.status === "REJECTED") {
    return {
      comment: creatorApprovalStatus.comment,
      status: "Rejected by Creator",
      color: "error",
    };
  } else if (hodApprovalStatus.status === "HOD Approval Waiting") {
    return hodApprovalStatus;
  } else if (hodApprovalStatus.status === "REJECTED") {
    return {
      comment: hodApprovalStatus.comment,
      status: "Rejected by HOD",
      color: "error",
    };
  } else if (financeStatus.status === "REJECTED") {
    return {
      comment: hodApprovalStatus.comment,
      status: "Rejected by Finance",
      color: "error",
    };
  } else if (financeStatus.status === "APPROVED") {
    return financeStatus;
  }

  return financeStatus;
};

export const displayApproverActions = (approvers, role) => {
  const creatorStatus =
    (approvers || []).find(
      (a) => a.role === VENDOR_ONBOARD_USERS.VIVO_CREATOR
    ) || {};
  const hodStatus =
    (approvers || []).find((a) => a.role === VENDOR_ONBOARD_USERS.HOD) || {};
  const financeStatus =
    (approvers || []).find(
      (a) => a.role === VENDOR_ONBOARD_USERS.FINANCE_USER
    ) || {};
  if (role === VENDOR_ONBOARD_USERS.VIVO_CREATOR) {
    return !creatorStatus.status;
  } else if (role === VENDOR_ONBOARD_USERS.HOD) {
    return (
      creatorStatus.status === VENDOR_FORM_STATUS.APPROVED && !hodStatus.status
    );
  } else if (role === VENDOR_ONBOARD_USERS.FINANCE_USER) {
    return (
      creatorStatus.status === VENDOR_FORM_STATUS.APPROVED &&
      hodStatus.status === VENDOR_FORM_STATUS.APPROVED &&
      !financeStatus.status
    );
  }
  return false;
};
