export const allRegions = {
  India: {
    "Andaman and Nicobar Islands": ["Port Blair"],
    "Andhra Pradesh": [
      "Addanki",
      "Akivīdu",
      "Akkarampalle",
      "Amalāpuram",
      "Amudālavalasa",
      "Anakāpalle",
      "Anantapur",
      "Atmakūr",
      "Attili",
      "Avanigadda",
      "Badvel",
      "Banganapalle",
      "Betamcherla",
      "Bhattiprolu",
      "Bhīmavaram",
      "Bhīmunipatnam",
      "Bobbili",
      "Bāpatla",
      "Challapalle",
      "Chemmumiahpet",
      "Chicacole",
      "Chilakalūrupet",
      "Chinnachowk",
      "Chodavaram",
      "Chīpurupalle",
      "Chīrāla",
      "Cuddapah",
      "Cumbum",
      "Dharmavaram",
      "Dhone",
      "Elamanchili",
      "Ellore",
      "Emmiganūr",
      "Erraguntla",
      "Gajuwaka",
      "Gannavaram",
      "Giddalūr",
      "Gudivāda",
      "Guntakal Junction",
      "Guntūr",
      "Gūdūr",
      "Hindupur",
      "Ichchāpuram",
      "Jaggayyapeta",
      "Jammalamadugu",
      "Kadiri",
      "Kaikalūr",
      "Kalyandurg",
      "Kandukūr",
      "Kanigiri",
      "Kanuru",
      "Kondapalle",
      "Kosigi",
      "Kovvūr",
      "Kovūr",
      "Kuppam",
      "Kākināda",
      "Kāvali",
      "Machilīpatnam",
      "Madanapalle",
      "Mandapeta",
      "Mangalagiri",
      "Mācherla",
      "Mārkāpur",
      "Nagari",
      "Nandigāma",
      "Nandikotkūr",
      "Nandyāl",
      "Narasannapeta",
      "Narasapur",
      "Narasaraopet",
      "Narsīpatnam",
      "Nellore",
      "Nidadavole",
      "Nāyudupeta",
      "Nūzvīd",
      "Ongole",
      "Palmaner",
      "Palāsa",
      "Parlākimidi",
      "Pedana",
      "Peddāpuram",
      "Penugonda",
      "Penukonda",
      "Phirangipuram",
      "Pithāpuram",
      "Polavaram",
      "Ponnur",
      "Ponnūru",
      "Proddatūr",
      "Pulivendla",
      "Punganūru",
      "Puttūr",
      "Pākāla",
      "Pālakollu",
      "Pālkonda",
      "Pārvatipuram",
      "Ramanayyapeta",
      "Rampachodavaram",
      "Renigunta",
      "Repalle",
      "Rājahmundry",
      "Rājampet",
      "Rāmachandrapuram",
      "Rāmāpuram",
      "Rāyachoti",
      "Rāyadrug",
      "Rāzām",
      "Sattenapalle",
      "Singarāyakonda",
      "Sompeta",
      "Srīsailain",
      "Sālūr",
      "Sāmalkot",
      "Sūlūru",
      "Tanuku",
      "Tekkali",
      "Tirumala",
      "Tirupati",
      "Tuni",
      "Tādepalle",
      "Tādepallegūdem",
      "Tādpatri",
      "Uravakonda",
      "Vadlapūdi",
      "Venkatagiri",
      "Vepagunta",
      "Vetapālem",
      "Vijayawada",
      "Vinukonda",
      "Visakhapatnam",
      "Vizianagaram",
      "Vuyyūru",
      "Yanam",
      "Yanamalakuduru",
      "Ādoni",
      "Rajamahendravaram",
      "Hyderabad",
      "Guntur",
      "Kakinada",
      "Vishakhapatnam",
    ],
    "Arunachal Pradesh": [
      "Along",
      "Itānagar",
      "Margherita",
      "Naharlagun",
      "Pāsighāt",
    ],
    Assam: [
      "Abhayāpuri",
      "Barpeta",
      "Barpeta Road",
      "Bilāsipāra",
      "Bokajān",
      "Bongaigaon",
      "Chāpar",
      "Dhekiajuli",
      "Dhing",
      "Dhuburi",
      "Dibrugarh",
      "Digboi",
      "Diphu",
      "Duliāgaon",
      "Dum Duma",
      "Gauripur",
      "Golāghāt",
      "Goālpāra",
      "Guwahati",
      "Hailākāndi",
      "Hojāi",
      "Howli",
      "Hāflong",
      "Hājo",
      "Jorhāt",
      "Karīmganj",
      "Khārupatia",
      "Kokrajhar",
      "Lumding Railway Colony",
      "Mangaldai",
      "Marigaon",
      "Mariāni",
      "Mākum",
      "Nahorkatiya",
      "North Guwāhāti",
      "North Lakhimpur",
      "Nāmrup",
      "Rangia",
      "Rangāpāra",
      "Sibsāgar",
      "Silapathar",
      "Silchar",
      "Sonāri",
      "Tezpur",
      "Tinsukia",
      "Udalguri",
    ],
    Bihar: [
      "Amarpur",
      "Arrah",
      "Arāria",
      "Aurangābād",
      "Bagaha",
      "Bahādurganj",
      "Bairāgnia",
      "Bakhtiyārpur",
      "Bangaon",
      "Banmankhi",
      "Bar Bigha",
      "Barauli",
      "Barhiya",
      "Begusarai",
      "Belsand",
      "Bettiah",
      "Bhabhua",
      "Bhawanipur",
      "Bhāgalpur",
      "Bihār",
      "Bihārīganj",
      "Bikramganj",
      "Buddh Gaya",
      "Buxar",
      "Bānka",
      "Bārh",
      "Bāruni",
      "Bīrpur",
      "Chhātāpur",
      "Chākia",
      "Chāpra",
      "Colgong",
      "Dalsingh Sarai",
      "Darbhanga",
      "Daudnagar",
      "Dehri",
      "Dhāka",
      "Dighwāra",
      "Dinapore",
      "Dumra",
      "Dumraon",
      "Fatwa",
      "Forbesganj",
      "Gaya",
      "Gopālganj",
      "Hilsa",
      "Hisuā",
      "Hājīpur",
      "Islāmpur",
      "Jagdīspur",
      "Jahānābād",
      "Jamālpur",
      "Jamūī",
      "Jaynagar",
      "Jhanjhārpur",
      "Jhā Jhā",
      "Jogbani",
      "Kasba",
      "Katihar",
      "Khagaria",
      "Khagaul",
      "Kharagpur",
      "Kishanganj",
      "Koelwār",
      "Koāth",
      "Luckeesarai",
      "Lālganj",
      "Madhipura",
      "Madhubani",
      "Mahārājgani",
      "Mairwa",
      "Maner",
      "Manihāri",
      "Marhaura",
      "Masaurhi Buzurg",
      "Mokameh",
      "Monghyr",
      "Mothīhāri",
      "Murlīganj",
      "Muzaffarpur",
      "Nabīnagar",
      "Naugachhia",
      "Nawāda",
      "Nirmāli",
      "Nāsriganj",
      "Patna",
      "Piro",
      "Pupri",
      "Purnia",
      "Rafiganj",
      "Raxaul",
      "Revelganj",
      "Rusera",
      "Rājgīr",
      "Rāmnagar",
      "Sagauli",
      "Saharsa",
      "Samāstipur",
      "Shahbazpur",
      "Sheikhpura",
      "Sheohar",
      "Sherghāti",
      "Shāhpur",
      "Silao",
      "Siwān",
      "Supaul",
      "Sītāmarhi",
      "Teghra",
      "Tekāri",
      "Thākurganj",
      "Wāris Alīganj",
    ],
    Chandigarh: ["Chandigarh"],
    Chhattisgarh: [
      "Akaltara",
      "Ambikāpur",
      "Arang",
      "Balod",
      "Baloda Bāzār",
      "Bemetāra",
      "Bhilai",
      "Bhānpurī",
      "Bhātāpāra",
      "Bilāspur",
      "Chāmpa",
      "Dhamtari",
      "Dongargarh",
      "Durg",
      "Gumla",
      "Jagdalpur",
      "Jashpurnagar",
      "Jānjgīr",
      "Jūnāgarh",
      "Katghora",
      "Kawardha",
      "Khairāgarh",
      "Kharsia",
      "Kirandul",
      "Kondagaon",
      "Korba",
      "Kotapārh",
      "Kotā",
      "Kumhāri",
      "Kānker",
      "Mahāsamund",
      "Mungeli",
      "Pasān",
      "Pathalgaon",
      "Raigarh",
      "Raipur",
      "Ratanpur",
      "Rāj-Nāndgaon",
      "Saktī",
      "Saraipali",
      "Takhatpur",
      "Umarkot",
      "Bilaspur",
    ],
    "Dadra and Nagar Haveli": ["Silvassa", "Āmli"],
    "Daman and Diu": ["Daman", "Diu"],
    Goa: [
      "Calangute",
      "Cuncolim",
      "Curchorem",
      "Dicholi",
      "Madgaon",
      "Mapusa",
      "Marmagao",
      "Māpuca",
      "Panaji",
      "Ponda",
      "Sancoale",
      "Taleigao",
      "Vāsco Da Gāma",
      "Vasco da Gama",
      "Vasco Da Gama",
      "Calungute",
      "Pune",
      "Alto Porvorim",
      "Anjuna",
      "Goa",
    ],
    Gujarat: [
      "Ahmedabad",
      "Amod",
      "Amreli",
      "Amroli",
      "Anjār",
      "Ankleshwar",
      "Bagasra",
      "Bedi",
      "Bhachāu",
      "Bharūch",
      "Bhuj",
      "Bhānvad",
      "Bhāvnagar",
      "Bhāyāvadar",
      "Bilimora",
      "Borsad",
      "Botād",
      "Bābra",
      "Bāntva",
      "Bārdoli",
      "Chaklāsi",
      "Chalāla",
      "Chhala",
      "Chhota Udepur",
      "Chotila",
      "Chānasma",
      "Dabhoi",
      "Dahegām",
      "Devgadh Bāriya",
      "Dhandhuka",
      "Dhanera",
      "Dharampur",
      "Dholka",
      "Dhorāji",
      "Dhrol",
      "Dhrāngadhra",
      "Dhāri",
      "Dohad",
      "Dwārka",
      "Dākor",
      "Dāmnagar",
      "Dīsa",
      "Gadhada",
      "Gandevi",
      "Gariadhar",
      "Ghandinagar",
      "Godhra",
      "Gondal",
      "Gāndhīdhām",
      "Halvad",
      "Himatnagar",
      "Hālol",
      "Hārij",
      "Jalālpur",
      "Jambusar",
      "Jasdan",
      "Jetpur",
      "Jodhpur",
      "Jodiya Bandar",
      "Jāmnagar",
      "Jūnāgadh",
      "Kadi",
      "Kadod",
      "Kapadvanj",
      "Karamsad",
      "Keshod",
      "Khambhāliya",
      "Khambhāt",
      "Khed Brahma",
      "Kheda",
      "Kherālu",
      "Kodīnar",
      "Kosamba",
      "Kundla",
      "Kutiyāna",
      "Kālol",
      "Kālāvad",
      "Kāndla",
      "Kāthor",
      "Limbdi",
      "Lālpur",
      "Lāthi",
      "Lūnāvāda",
      "Mahemdāvād",
      "Mahudha",
      "Mendarda",
      "Modāsa",
      "Morbi",
      "Morwa",
      "Mundra",
      "Māndvi",
      "Māngrol",
      "Mānsa",
      "Mānāvadar",
      "Nadiād",
      "Okha",
      "Padra",
      "Petlād",
      "Porbandar",
      "Pālanpur",
      "Pālitāna",
      "Pārdi",
      "Pātan",
      "Rādhanpur",
      "Rājkot",
      "Rājpīpla",
      "Rājula",
      "Rānāvāv",
      "Rāpar",
      "Salāya",
      "Sarkhej",
      "Siddhapur",
      "Sihor",
      "Sikka",
      "Sojītra",
      "Songadh",
      "Surendranagar",
      "Sānand",
      "Sāyla",
      "Sūrat",
      "Talāja",
      "Tharād",
      "Thān",
      "Thāsra",
      "Umreth",
      "Un",
      "Una",
      "Unjha",
      "Upleta",
      "Vadnagar",
      "Vadodara",
      "Valabhīpur",
      "Vallabh Vidyanagar",
      "Valsād",
      "Vapi",
      "Vasa",
      "Vejalpur",
      "Verāval",
      "Vijāpur",
      "Visnagar",
      "Vyāra",
      "Vīsāvadar",
      "Wānkāner",
      "Ānand",
      "Karzan",
      "Jamnagar",
      "Navsari",
      "Surat",
      "Gandhinagar",
      "Rajkot",
      "Narmada",
      "Mehsana",
      "Bharuch",
      "Gandhidham",
      "Bhavnagar",
      "Anand",
    ],
    Haryana: [
      "Ambāla",
      "Bahādurgarh",
      "Bara Uchāna",
      "Barwāla",
      "Beri Khās",
      "Bhiwāni",
      "Charkhi Dādri",
      "Dabwāli",
      "Dhāruhera",
      "Ellenabad",
      "Farīdābād",
      "Fatehābād",
      "Fīrozpur Jhirka",
      "Gharaunda",
      "Gohāna",
      "Gorakhpur",
      "Gurgaon",
      "Hisar",
      "Hodal",
      "Hānsi",
      "Indri",
      "Jagādhri",
      "Jhajjar",
      "Jīnd",
      "Kaithal",
      "Kalānaur",
      "Karnāl",
      "Kharkhauda",
      "Kālānwāli",
      "Lādwa",
      "Maham",
      "Mahendragarh",
      "Narwāna",
      "Narāyangarh",
      "Nārnaul",
      "Nārnaund",
      "Nīlokheri",
      "Palwal",
      "Pataudi",
      "Pehowa",
      "Pinjaur",
      "Pānīpat",
      "Pūndri",
      "Pūnāhāna",
      "Ratia",
      "Rewāri",
      "Rohtak",
      "Rānia",
      "Safidon",
      "Samālkha",
      "Shāhābād",
      "Sirsa",
      "Sohna",
      "Sonīpat",
      "Thānesar",
      "Tohāna",
      "Tāoru",
      "Yamunānagar",
      "Āsandh",
      "Sonipat",
      "Rewari",
      "Panipat",
      "Karnal",
      "Kurukshetra",
      "Faridabad",
      "Ambala",
      "Manesar",
      "Gurugram",
      "NCR - Gurgaon",
      "Haryana",
      "Panchkula",
    ],
    "Himachal Pradesh": [
      "Baddi",
      "Chamba",
      "Dharmsāla",
      "Hamīrpur",
      "Kulu",
      "Kālka",
      "Mandi",
      "Nāhan",
      "Pāonta Sāhib",
      "Shimla",
      "Solan",
      "Sundarnagar",
      "Una",
      "Mcleodganj",
      "Manali",
    ],
    Jharkhand: [
      "Barka Kāna",
      "Barki Saria",
      "Bokāro",
      "Būndu",
      "Chakradharpur",
      "Chatra",
      "Chās",
      "Chāībāsa",
      "Daltonganj",
      "Dhanbād",
      "Dugda",
      "Dumka",
      "Garhwa",
      "Ghātsīla",
      "Girīdīh",
      "Gobindpur",
      "Godda",
      "Gomoh",
      "Gumia",
      "Hazārībāg",
      "Husainābād",
      "Jamshedpur",
      "Jasidih",
      "Jharia",
      "Jugsālai",
      "Jumri Tilaiyā",
      "Jāmadoba",
      "Jāmtāra",
      "Khunti",
      "Kodarmā",
      "Kuju",
      "Kānke",
      "Kātrās",
      "Lohārdaga",
      "Lātehār",
      "Madhupur",
      "Mushābani",
      "Noāmundi",
      "Pākaur",
      "Pāthardih",
      "Ranchi",
      "Rāmgarh",
      "Sijua",
      "Simdega",
      "Sāhibganj",
    ],
    Karnataka: [
      "Afzalpur",
      "Aland",
      "Alnāvar",
      "Anekal",
      "Annigeri",
      "Arkalgūd",
      "Arsikere",
      "Athni",
      "Aurād",
      "Bagalkot",
      "Bail-Hongal",
      "Bangārapet",
      "Bannūr",
      "Bantvāl",
      "Basavakalyān",
      "Basavana Bāgevādi",
      "Belgaum",
      "Bellary",
      "Belūr",
      "Bengaluru",
      "Bhadrāvati",
      "Bhatkal",
      "Bhālki",
      "Bijapur",
      "Bilgi",
      "Birūr",
      "Byndoor",
      "Byādgi",
      "Bādāmi",
      "Bāgepalli",
      "Bīdar",
      "Challakere",
      "Chamrajnagar",
      "Channagiri",
      "Channapatna",
      "Channarāyapatna",
      "Chik Ballāpur",
      "Chikmagalūr",
      "Chiknāyakanhalli",
      "Chikodi",
      "Chincholi",
      "Chintāmani",
      "Chitradurga",
      "Chītāpur",
      "Coondapoor",
      "Dandeli",
      "Devanhalli",
      "Dod Ballāpur",
      "French Rocks",
      "Gadag",
      "Gajendragarh",
      "Gangolli",
      "Gangāwati",
      "Gokak",
      "Gokarna",
      "Goribidnūr",
      "Gubbi",
      "Gulbarga",
      "Guledagudda",
      "Gundlupet",
      "Gurmatkāl",
      "Hadagalli",
      "Haliyal",
      "Harihar",
      "Harpanahalli",
      "Hassan",
      "Hirekerūr",
      "Hiriyūr",
      "Holalkere",
      "Hole Narsipur",
      "Homnābād",
      "Honnāli",
      "Honāvar",
      "Hosdurga",
      "Hoskote",
      "Hospet",
      "Hubli",
      "Hukeri",
      "Hungund",
      "Hunsūr",
      "Hāngal",
      "Hāveri",
      "Ilkal",
      "Indi",
      "Jagalūr",
      "Jamkhandi",
      "Jevargi",
      "Kadūr",
      "Kalghatgi",
      "Kampli",
      "Kanakapura",
      "Karwar",
      "Kerūr",
      "Khānāpur",
      "Kollegāl",
      "Kolār",
      "Konnūr",
      "Koppal",
      "Kottūru",
      "Krishnarājpet",
      "Kudachi",
      "Kumta",
      "Kundgol",
      "Kunigal",
      "Kushtagi",
      "Kārkala",
      "Kūdligi",
      "Lakshmeshwar",
      "Lingsugūr",
      "Maddagiri",
      "Maddūr",
      "Madikeri",
      "Mahālingpur",
      "Malavalli",
      "Malpe",
      "Mandya",
      "Mangalore",
      "Muddebihāl",
      "Mudgal",
      "Mudhol",
      "Mulbāgal",
      "Mulgund",
      "Mundargi",
      "Mundgod",
      "Murudeshwara",
      "Mysore",
      "Māgadi",
      "Mālūr",
      "Mānvi",
      "Mūdbidri",
      "Mūlki",
      "Nanjangūd",
      "Naregal",
      "Nargund",
      "Navalgund",
      "Nelamangala",
      "Nāgamangala",
      "Piriyāpatna",
      "Puttūr",
      "Pāvugada",
      "Rabkavi",
      "Robertsonpet",
      "Ron",
      "Rāichūr",
      "Rāmanagaram",
      "Rānībennur",
      "Rāybāg",
      "Sadalgi",
      "Sakleshpur",
      "Sandūr",
      "Sankeshwar",
      "Saundatti",
      "Savanūr",
      "Seram",
      "Shiggaon",
      "Shikārpur",
      "Shimoga",
      "Shirhatti",
      "Shorāpur",
      "Shrīrangapattana",
      "Shāhpur",
      "Shāhābād",
      "Sidlaghatta",
      "Sindgi",
      "Sindhnūr",
      "Sirsi",
      "Siruguppa",
      "Someshwar",
      "Srīnivāspur",
      "Sulya",
      "Sāgar",
      "Sīra",
      "Tarikere",
      "Tekkalakote",
      "Terdāl",
      "Tiptūr",
      "Tumkūr",
      "Tālīkota",
      "Tīrthahalli",
      "Udipi",
      "Ullal",
      "Udupi",
      "Vijayapura",
      "Vīrarājendrapet",
      "Wādi",
      "Yelahanka",
      "Yellāpur",
      "Yādgīr",
      "Basavanagudi",
      "Bangalore",
      "Manipal",
      "Tiruppur",
    ],
    Kashmir: [
      "Anantnag",
      "Bandipura",
      "Bijbiāra",
      "Bāramūla",
      "Doda",
      "Gāndarbal",
      "Jammu",
      "Kathua",
      "Kishtwār",
      "Kulgam",
      "Leh",
      "Padam",
      "Pulwama",
      "Pūnch",
      "Rajaori",
      "Sopur",
      "Soyībug",
      "Srinagar",
      "Sāmba",
      "Udhampur",
    ],
    Kerala: [
      "Adūr",
      "Alleppey",
      "Aluva",
      "Angamāli",
      "Aroor",
      "Arukutti",
      "Attingal",
      "Badagara",
      "Beypore",
      "Cannanore",
      "Changanācheri",
      "Chengannūr",
      "Cherpulassery",
      "Cherthala",
      "Chittūr",
      "Chāvakkād",
      "Cochin",
      "Dharmadam",
      "Elūr",
      "Erāttupetta",
      "Ferokh",
      "Guruvāyūr",
      "Iringal",
      "Irinjālakuda",
      "Kadakkavoor",
      "Kalamassery",
      "Kalavoor",
      "Kalpetta",
      "Kattanam",
      "Kizhake Chālakudi",
      "Kodungallūr",
      "Kollam",
      "Kotamangalam",
      "Kottayam",
      "Kozhikode",
      "Kumbalam",
      "Kunnamangalam",
      "Kunnamkulam",
      "Kutiatodu",
      "Kuttampuzha",
      "Kānnangād",
      "Kāsaragod",
      "Kāyankulam",
      "Mahē",
      "Malappuram",
      "Manjeri",
      "Mannārakkāt",
      "Mattanūr",
      "Mavoor",
      "Muluppilagadu",
      "Munnar",
      "Muvattupuzha",
      "Māvelikara",
      "Mūvattupula",
      "Naduvannūr",
      "Nedumangād",
      "Neyyāttinkara",
      "Nādāpuram",
      "Nīlēshwar",
      "Ottappālam",
      "Palakkad",
      "Panmana",
      "Paravūr",
      "Pariyāpuram",
      "Pathanāmthitta",
      "Payyannūr",
      "Perumbavoor",
      "Perumpāvūr",
      "Piravam",
      "Ponnāni",
      "Punalūr",
      "Pāppinisshēri",
      "Shertallai",
      "Shoranūr",
      "Talipparamba",
      "Tellicherry",
      "Thiruvananthapuram",
      "Tirur",
      "Tiruvalla",
      "Trichūr",
      "Vaikam",
      "Varkala",
      "Vayalār",
      "Vettūr",
      "Calicut",
      "Thrissur",
      "Trivandrum",
    ],
    "Madhya Pradesh": [
      "Agar",
      "Alot",
      "Amarpātan",
      "Ambāh",
      "Amla",
      "Anjad",
      "Anūppur",
      "Ashoknagar",
      "Ashta",
      "Badnāwar",
      "Baihar",
      "Banda",
      "Bargi",
      "Barwāni",
      "Begamganj",
      "Beohāri",
      "Berasia",
      "Betūl",
      "Bhainsdehi",
      "Bhawāniganj",
      "Bhikangaon",
      "Bhind",
      "Bhitarwār",
      "Bhopal",
      "Bhānder",
      "Bhānpura",
      "Biaora",
      "Bijāwar",
      "Burhar",
      "Burhānpur",
      "Bābai",
      "Bālāghāt",
      "Bāmor Kalān",
      "Bāsoda",
      "Chanderi",
      "Chhatarpur",
      "Chhindwāra",
      "Daboh",
      "Dabra",
      "Damoh",
      "Datia",
      "Deori Khās",
      "Depālpur",
      "Dewas",
      "Dhāmnod",
      "Dhār",
      "Dindori",
      "Etāwa",
      "Garhākota",
      "Gohadi",
      "Guna",
      "Gwalior",
      "Gādarwāra",
      "Harda Khās",
      "Harpālpur",
      "Harsūd",
      "Hatta",
      "Hindoria",
      "Hoshangābād",
      "Indore",
      "Itārsi",
      "Jabalpur",
      "Jaorā",
      "Jatāra",
      "Jhābua",
      "Jora",
      "Jāmai",
      "Jāwad",
      "Kailāras",
      "Kaimori",
      "Kannod",
      "Kareli",
      "Karera",
      "Kasrāwad",
      "Katangi",
      "Khajurāho",
      "Khamaria",
      "Khandwa",
      "Khargone",
      "Khilchipur",
      "Khirkiyān",
      "Khurai",
      "Khāchrod",
      "Khātegaon",
      "Kolāras",
      "Korwai",
      "Kotma",
      "Kukshi",
      "Kumbhrāj",
      "Lahār",
      "Lakhnādon",
      "Leteri",
      "Maheshwar",
      "Mahgawān",
      "Maihar",
      "Maksi",
      "Mandideep",
      "Mandlā",
      "Mandsaur",
      "Manāsa",
      "Manāwar",
      "Mau",
      "Mauganj",
      "Mihona",
      "Morena",
      "Morār",
      "Multai",
      "Mungaoli",
      "Murwāra",
      "Māndu",
      "Nagda",
      "Nainpur",
      "Naraini",
      "Narsimhapur",
      "Narsinghgarh",
      "Narwar",
      "Nasrullāhganj",
      "Nepānagar",
      "Nāgod",
      "Palera",
      "Panna",
      "Panāgar",
      "Parāsia",
      "Patharia",
      "Pithampur",
      "Porsa",
      "Punāsa",
      "Pāli",
      "Pāndhurnā",
      "Raisen",
      "Rajpur",
      "Ratlām",
      "Rehli",
      "Rewa",
      "Rāghogarh",
      "Rāhatgarh",
      "Rājgarh",
      "Rāmpura",
      "Sabalgarh",
      "Sanāwad",
      "Satna",
      "Saugor",
      "Sausar",
      "Sehore",
      "Sendhwa",
      "Seondha",
      "Seoni",
      "Seoni Mālwa",
      "Shahdol",
      "Sheopur",
      "Shivpuri",
      "Shujālpur",
      "Shāhpur",
      "Shājāpur",
      "Shāmgarh",
      "Sidhi",
      "Sihorā",
      "Singrauli",
      "Sironj",
      "Sohāgpur",
      "Sārangpur",
      "Tarāna",
      "Teonthar",
      "Tīkamgarh",
      "Udaipura",
      "Ujjain",
      "Umaria",
      "Unhel",
      "Vidisha",
      "Wārāseonī",
      "Āron",
    ],
    Maharashtra: [
      "Achalpur",
      "Ahmadnagar",
      "Ahmadpur",
      "Airoli",
      "Ajra",
      "Akalkot",
      "Akola",
      "Akot",
      "Alandi",
      "Alībāg",
      "Amalner",
      "Amarnāth",
      "Ambad",
      "Ambājogāi",
      "Amrāvati",
      "Anjangaon",
      "Anshing",
      "Ashta",
      "Aurangabad",
      "Ausa",
      "Badlapur",
      "Ballālpur",
      "Basmat",
      "Bhandāra",
      "Bhayandar",
      "Bhiwandi",
      "Bhor",
      "Bhudgaon",
      "Bhusāval",
      "Bhūm",
      "Boisar",
      "Borivli",
      "Buldāna",
      "Bālāpur",
      "Bārsi",
      "Bārāmati",
      "Chicholi",
      "Chikhli",
      "Chiplūn",
      "Chopda",
      "Chākan",
      "Chālisgaon",
      "Chānda",
      "Chāndor",
      "Chāndur",
      "Chāndūr",
      "Chāndūr Bāzār",
      "Daryāpur",
      "Dattāpur",
      "Daund",
      "Deoli",
      "Deolāli",
      "Deūlgaon Rāja",
      "Dharangaon",
      "Dharmābād",
      "Dhārūr",
      "Dhūlia",
      "Digras",
      "Dombivli",
      "Dondaicha",
      "Durgāpur",
      "Dāhānu",
      "Dārwha",
      "Dīglūr",
      "Erandol",
      "Faizpur",
      "Gadhinglaj",
      "Gangākher",
      "Gangāpur",
      "Gevrai",
      "Ghoti Budrukh",
      "Ghugus",
      "Ghātanji",
      "Gondiā",
      "Hadgāon",
      "Hinganghāt",
      "Hingoli",
      "Ichalkaranji",
      "Igatpuri",
      "Indāpur",
      "Jaisingpur",
      "Jalgaon",
      "Jalgaon Jamod",
      "Jintūr",
      "Junnar",
      "Jālna",
      "Kalamb",
      "Kalamnūri",
      "Kalmeshwar",
      "Kalyān",
      "Kankauli",
      "Kannad",
      "Karjat",
      "Karmāla",
      "Karād",
      "Khadki",
      "Kharakvasla",
      "Khetia",
      "Khopoli",
      "Khuldābād",
      "Khāmgaon",
      "Khāpa",
      "Kinwat",
      "Kodoli",
      "Kolhāpur",
      "Kopargaon",
      "Koregaon",
      "Koynanagar",
      "Kurandvād",
      "Kurduvādi",
      "Kāgal",
      "Kāmthi",
      "Kāranja",
      "Kātol",
      "Latur",
      "Lonavla",
      "Lonār",
      "Mahād",
      "Malkāpur",
      "Mangrūl Pīr",
      "Manmād",
      "Mehekar",
      "Mhāsvād",
      "Moram",
      "Morsi",
      "Mudkhed",
      "Mukher",
      "Mumbai",
      "Murbād",
      "Murtajāpur",
      "Mājalgaon",
      "Mālegaon",
      "Mālvan",
      "Mānwat",
      "Mūl",
      "Nagpur",
      "Naldurg",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Navi Mumbai",
      "Neral",
      "Nilanga",
      "Nipāni",
      "Nāndgaon",
      "Nāndūra Buzurg",
      "Osmanabad",
      "Ozar",
      "Paithan",
      "Pandharpur",
      "Panvel",
      "Parbhani",
      "Parli Vaijnāth",
      "Parola",
      "Partūr",
      "Pawni",
      "Pen",
      "Phaltan",
      "Pimpri",
      "Powai",
      "Pulgaon",
      "Pune",
      "Pusad",
      "Pāchora",
      "Pālghar",
      "Pāthardi",
      "Pāthri",
      "Pātūr",
      "Pīpri",
      "Pūrna",
      "Rahimatpur",
      "Ratnagiri",
      "Risod",
      "Roha",
      "Rāhuri",
      "Rājgurunagar",
      "Rājūra",
      "Rāmtek",
      "Rāver",
      "Sangamner",
      "Saoner",
      "Satānā",
      "Selu",
      "Shegaon",
      "Shiraguppi",
      "Shirdi",
      "Shirpur",
      "Shivaji Nagar",
      "Shrīgonda",
      "Shāhāda",
      "Sillod",
      "Sinnar",
      "Sirūr",
      "Solāpur",
      "Soygaon",
      "Srīvardhan",
      "Sāngli",
      "Sāngola",
      "Sāsvad",
      "Sātāra",
      "Sāvantvādi",
      "Sāvda",
      "Talegaon Dābhāde",
      "Taloda",
      "Telhāra",
      "Thāne",
      "Tuljāpur",
      "Tumsar",
      "Tāsgaon",
      "Udgīr",
      "Ulhasnagar",
      "Umarga",
      "Umarkhed",
      "Umred",
      "Uran",
      "Vaijāpur",
      "Varangaon",
      "Vasind",
      "Virār",
      "Vite",
      "Vāda",
      "Wai",
      "Wani",
      "Wardha",
      "Warora",
      "Warud",
      "Wāshīm",
      "Yavatmāl",
      "Yeola",
      "Yāval",
      "Ārangaon",
      "Ārvi",
      "THANE",
      "Palghar",
      "Maharastra",
      "Bengaluru",
      "Goregaon",
      "Kolhapur",
      "Dombivili",
      "Mira Road east",
      "Thane",
    ],
    Manipur: [
      "Churāchāndpur",
      "Imphal",
      "Kakching",
      "Mayāng Imphāl",
      "Moirāng",
      "Phek",
      "Thoubāl",
    ],
    Meghalaya: ["Mankāchar", "Nongstoin", "Shillong", "Tura"],
    Mizoram: ["Aizawl", "Kolasib", "Lunglei", "Saiha", "Serchhīp"],
    NCT: [
      "Alīpur",
      "Bawāna",
      "Delhi",
      "Karol Bāgh",
      "New Delhi",
      "Nāngloi Jāt",
    ],
    Nagaland: [
      "Dimāpur",
      "Kohima",
      "Mokokchūng",
      "Mon",
      "Tuensang",
      "Wokha",
      "Zunheboto",
    ],
    Odisha: [
      "Angul",
      "Bada Barabīl",
      "Balasore",
      "Balāngīr",
      "Barbil",
      "Bargarh",
      "Barpāli",
      "Bauda",
      "Bhadrakh",
      "Bhanjanagar",
      "Bhawānipatna",
      "Bhuban",
      "Bhubaneshwar",
      "Binka",
      "Birmitrapur",
      "Brahmapur",
      "Brājarājnagar",
      "Burla",
      "Bālugaon",
      "Bānapur",
      "Bānki",
      "Bāsudebpur",
      "Chatrapur",
      "Cuttack",
      "Deogarh",
      "Dhenkānāl",
      "Gunupur",
      "Hinjilikatu",
      "Hīrākud",
      "Jagatsinghapur",
      "Jaleshwar",
      "Jatani",
      "Jeypore",
      "Jhārsuguda",
      "Jājpur",
      "Kantābānji",
      "Kendrāparha",
      "Kesinga",
      "Khurda",
      "Konārka",
      "Korāput",
      "Kuchaiburi",
      "Kāmākhyānagar",
      "Malakanagiri",
      "Nayāgarh",
      "Nimāparha",
      "Nowrangapur",
      "Nīlgiri",
      "Padampur",
      "Parādīp Garh",
      "Patnāgarh",
      "Patāmundai",
      "Phulabāni",
      "Pipili",
      "Polasara",
      "Puri",
      "Remuna",
      "Sambalpur",
      "Sonepur",
      "Sorada",
      "Soro",
      "Sundargarh",
      "Titlāgarh",
      "Tālcher",
      "Āsika",
      "Āthagarh",
    ],
    Pondicherry: ["Kāraikāl", "Puducherry", "Pondicherry"],
    Punjab: [
      "Zirakpur",
      "Abohar",
      "Ahmadpur East",
      "Ahmadpur Siāl",
      "Ajnāla",
      "Alīpur",
      "Amritsar",
      "Anandpur",
      "Attock City",
      "Baddomalhi",
      "Bahāwalnagar",
      "Bahāwalpur",
      "Banga",
      "Banūr",
      "Barnāla",
      "Basi",
      "Basīrpur",
      "Batāla",
      "Bhadaur",
      "Bhakkar",
      "Bhalwāl",
      "Bhatinda",
      "Bhawāna",
      "Bhawānīgarh",
      "Bhera",
      "Bhogpur",
      "Bhopālwāla",
      "Bhāi Pheru",
      "Bhīkhi",
      "Budhlāda",
      "Bāgha Purāna",
      "Bālāchor",
      "Būrewāla",
      "Chak Two Hundred Forty-Nine TDA",
      "Chak Āzam Saffo",
      "Chakwāl",
      "Chawinda",
      "Chiniot",
      "Chishtiān Mandi",
      "Choa Saidān Shāh",
      "Chīchāwatni",
      "Chūhar Kāna",
      "Chūniān",
      "Darya Khān",
      "Daska",
      "Dasūya",
      "Dera Ghazi Khan",
      "Dhanaula",
      "Dhanot",
      "Dhāriwāl",
      "Dhūri",
      "Dijkot",
      "Dinga",
      "Dorāha",
      "Dullewāla",
      "Dunga Bunga",
      "Dunyāpur",
      "Dājal",
      "Dāūd Khel",
      "Dīnānagar",
      "Dīpālpur",
      "Eminābād",
      "Faisalābād",
      "Faqīrwāli",
      "Faruka",
      "Farīdkot",
      "Fatehgarh Chūriān",
      "Fazalpur",
      "Ferozepore",
      "Fort Abbās",
      "Fāzilka",
      "Garh Mahārāja",
      "Garhshankar",
      "Giddarbāha",
      "Gojra",
      "Gujrānwāla",
      "Gujrāt",
      "Guru Har Sahāi",
      "Gūjar Khān",
      "Hadāli",
      "Harnoli",
      "Haru Zbad",
      "Hasan Abdāl",
      "Haveli",
      "Hazro",
      "Hujra",
      "Hāfizābād",
      "Hāsilpur",
      "Jagraon",
      "Jahāniān Shāh",
      "Jaito",
      "Jalandhar",
      "Jalālpur",
      "Jalālpur Pīrwāla",
      "Jalālābād",
      "Jand",
      "Jandiāla",
      "Jarānwāla",
      "Jatoi Shimāli",
      "Jauharābād",
      "Jhang Sadr",
      "Jhawāriān",
      "Jhelum",
      "Jhumra",
      "Jāmpur",
      "Kabīrwāla",
      "Kahūta",
      "Kallar Kahār",
      "Kalūr Kot",
      "Kamar Mushāni",
      "Kamoke",
      "Kamālia",
      "Kamīr",
      "Kanganpur",
      "Kapūrthala",
      "Karor",
      "Kartārpur",
      "Kasūr",
      "Khairpur",
      "Khanna",
      "Kharar",
      "Khewra",
      "Khurriānwāla",
      "Khushāb",
      "Khāngarh",
      "Khāngāh Dogrān",
      "Khānpur",
      "Khāriān",
      "Kohror Pakka",
      "Kot Addu",
      "Kot Ghulām Muhammad",
      "Kot Mūmin",
      "Kot Rādha Kishan",
      "Kot Samāba",
      "Kotkapura",
      "Kotli Lohārān",
      "Kundiān",
      "Kunjāh",
      "Kāhna",
      "Kāleke Mandi",
      "Kālābāgh",
      "Kiratpur",
      "Kāmra",
      "Ladhewāla Warāich",
      "Lahore",
      "Laungowāl",
      "Layyah",
      "Lodhrān",
      "Ludhiāna",
      "Lāla Mūsa",
      "Lāliān",
      "Mailsi",
      "Malakwal City",
      "Malakwāl",
      "Malaut",
      "Mandi Bahāuddīn",
      "Mangla",
      "Maur",
      "Minchinābād",
      "Mitha Tiwāna",
      "Miān Channūn",
      "Miānwāli",
      "Moga",
      "Mohali",
      "Morinda",
      "Mukeriān",
      "Muktsar",
      "Multān",
      "Murree",
      "Murīdke",
      "Mustafābād",
      "Muzaffargarh",
      "Māchhīwāra",
      "Māler Kotla",
      "Māmu Kānjan",
      "Mānsa",
      "Mānānwāla",
      "Nakodar",
      "Nankāna Sāhib",
      "Naushahra Virkān",
      "Nawāshahr",
      "Nābha",
      "Nāngal Township",
      "Nārang",
      "Nārowāl",
      "Okāra",
      "Pasrūr",
      "Pathānkot",
      "Patiāla",
      "Patti",
      "Pattoki",
      "Phagwāra",
      "Phillaur",
      "Phālia",
      "Pind Dādan Khān",
      "Pindi Bhattiān",
      "Pindi Gheb",
      "Pākpattan",
      "Pīr Mahal",
      "Qādirpur Rān",
      "Qādiān",
      "Rabwāh",
      "Rawalpindi",
      "Renāla Khurd",
      "Ropar",
      "Rāikot",
      "Rāiwind",
      "Rāja Jang",
      "Rājanpur",
      "Rājpura",
      "Rāmpura",
      "Sahiwal",
      "Sambriāl",
      "Samrāla",
      "Sanaur",
      "Sangla Hill",
      "Sangrūr",
      "Sardulgarh",
      "Sargodha",
      "Sarāi Sidhu",
      "Sarāi Ālamgīr",
      "Shahkot",
      "Shahr Sultān",
      "Shakargarr",
      "Sharqpur",
      "Sheikhupura",
      "Shujāābād",
      "Sialkot",
      "Sillānwāli",
      "Sirhind",
      "Sodhra",
      "Sukheke Mandi",
      "Sultanpur",
      "Sunām",
      "Surkhpur",
      "Sādiqābād",
      "Sāhīwāl",
      "Talagang",
      "Talamba",
      "Talwandi Bhai",
      "Talwāra",
      "Tarn Tāran",
      "Taunsa",
      "Toba Tek Singh",
      "Tāndliānwāla",
      "Vihāri",
      "Warburton",
      "Wazīrābād",
      "Yazman",
      "Zafarwāl",
      "Zira",
      "Zāhir Pīr",
      "Ādampur",
      "Ārifwāla",
      "RupNagar",
      "Faridkot",
      "SAS Nagar, Mohali",
      "Chaklan",
      "Derabassi, Mohali",
      "Khamano",
      "Ludhiana",
      "Fatehgarh Sahib",
      "Hoshiarpur",
      "Zirakpur",
      "Dasuya",
      "New Delhi",
      "Gurdaspur",
      "Sangrur",
      "Rajpura",
      "Ferozepur",
      "Punjab",
      "SBS Nagar",
      "Ludhana",
      "Pathankot",
      "Batala",
      "Patiala",
      "amritsar",
      "Nawanshahr",
      "Phagwara",
      "Kapurthala",
      "Tarn Taran",
      "Iudhiana",
      "Barnala",
      "Zirakhpur",
      "Nabha",
    ],
    Rajasthan: [
      "Ajmer",
      "Aklera",
      "Alwar",
      "Amet",
      "Anta",
      "Anūpgarh",
      "Bagar",
      "Bari Sādri",
      "Basi",
      "Basni",
      "Baswa",
      "Bayāna",
      "Begūn",
      "Behror",
      "Beāwar",
      "Bharatpur",
      "Bhasāwar",
      "Bhindār",
      "Bhiwadi",
      "Bhādra",
      "Bhādāsar",
      "Bhīlwāra",
      "Bhīnmāl",
      "Bilāra",
      "Bissāu",
      "Bāli",
      "Bālotra",
      "Bāndīkūi",
      "Bānswāra",
      "Bāri",
      "Bārmer",
      "Bārān",
      "Bīkaner",
      "Būndi",
      "Chaksu",
      "Chhabra",
      "Chhoti Sādri",
      "Chhāpar",
      "Chidawa",
      "Chittaurgarh",
      "Chūru",
      "Dausa",
      "Deoli",
      "Deshnoke",
      "Devgarh",
      "Dhaulpur",
      "Dīdwāna",
      "Dīg",
      "Dūngarpur",
      "Fatehpur",
      "Gangānagar",
      "Gangāpur",
      "Gulābpura",
      "Hanumāngarh",
      "Hindaun",
      "Jahāzpur",
      "Jaipur",
      "Jaisalmer",
      "Jaitāran",
      "Jalor",
      "Jhunjhunūn",
      "Jhālrapātan",
      "Jhālāwār",
      "Jodhpur",
      "Karanpur",
      "Karauli",
      "Kekri",
      "Keshorai Pātan",
      "Khandela",
      "Khetri",
      "Kishangarh",
      "Kota",
      "Kotputli",
      "Kuchera",
      "Kuchāman",
      "Kāman",
      "Kāpren",
      "Kūmher",
      "Lachhmangarh Sīkar",
      "Losal",
      "Lādnūn",
      "Lākheri",
      "Lālsot",
      "Mahwah",
      "Makrāna",
      "Manoharpur",
      "Merta",
      "Mālpura",
      "Māndal",
      "Māndalgarh",
      "Māngrol",
      "Mūndwa",
      "Nagar",
      "Nainwa",
      "Napāsar",
      "Naraina",
      "Nasīrābād",
      "Nawalgarh",
      "Neem ka Thana",
      "Niwai",
      "Nohar",
      "Nokha",
      "Nādbai",
      "Nāgaur",
      "Nāthdwāra",
      "Nāwa",
      "Nīmbāhera",
      "Nīmāj",
      "Padampur",
      "Parvatsar",
      "Phalodi",
      "Phulera",
      "Pilibangan",
      "Pilāni",
      "Pindwāra",
      "Pokaran",
      "Pratāpgarh",
      "Pushkar",
      "Pāli",
      "Pīpār",
      "Raipur",
      "Ratangarh",
      "Rāisinghnagar",
      "Rājaldesar",
      "Rājgarh",
      "Rājsamand",
      "Rājākhera",
      "Rāmganj Mandi",
      "Rāmgarh",
      "Rāwatbhāta",
      "Rāwatsār",
      "Rīngas",
      "Samdari",
      "Sangariā",
      "Sangod",
      "Sardārshahr",
      "Sarwār",
      "Sawāi Mādhopur",
      "Sheoganj",
      "Shāhpura",
      "Sirohi",
      "Siwāna",
      "Sojat",
      "Sri Dūngargarh",
      "Sri Mādhopur",
      "Suket",
      "Sunel",
      "Sādri",
      "Sālūmbar",
      "Sāmbhar",
      "Sānchor",
      "Sīkar",
      "Sūjāngarh",
      "Sūrajgarh",
      "Sūratgarh",
      "Takhatgarh",
      "Tijāra",
      "Todabhim",
      "Todaraisingh",
      "Tonk",
      "Tārānagar",
      "Udaipur",
      "Wer",
      "Ābu",
      "Ābu Road",
      "Āsind",
      "Sri Ganganagar",
      "Neemrana",
    ],
    Sikkim: ["Gangtok"],
    "Tamil Nadu": [
      "Adirampattinam",
      "Alandur",
      "Ambasamudram",
      "Ambattūr",
      "Ambur",
      "Anaimalai",
      "Annur",
      "Anthiyur",
      "Arakkonam",
      "Arantāngi",
      "Arcot",
      "Ariyalūr",
      "Arumuganeri",
      "Aruppukkottai",
      "Attur",
      "Avinashi",
      "Ayakudi",
      "Bhavāni",
      "Bhuvanagiri",
      "Bodināyakkanūr",
      "Chengalpattu",
      "Chengam",
      "Chennai",
      "Chennimalai",
      "Chetput",
      "Chettipālaiyam",
      "Cheyyar",
      "Chidambaram",
      "Chinna Salem",
      "Chinnamanūr",
      "Coimbatore",
      "Colachel",
      "Cuddalore",
      "Cumbum",
      "Denkanikota",
      "Devakottai",
      "Dharapuram",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Gingee",
      "Gobichettipalayam",
      "Gudalur",
      "Gudiyatham",
      "Gummidipundi",
      "Gūduvāncheri",
      "Harūr",
      "Hosūr",
      "Idappadi",
      "Injambakkam",
      "Irugūr",
      "Jayamkondacholapuram",
      "Jolārpettai",
      "Kadayanallur",
      "Kalakkādu",
      "Kallakkurichchi",
      "Kallidaikurichchi",
      "Kalugumalai",
      "Kangayam",
      "Kanniyākumāri",
      "Karur",
      "Kattivākkam",
      "Kayalpattinam",
      "Keelakarai",
      "Kodaikānāl",
      "Koothanallur",
      "Kotagiri",
      "Kovilpatti",
      "Krishnagiri",
      "Kulittalai",
      "Kumbakonam",
      "Kurinjippādi",
      "Kuzhithurai",
      "Kānchipuram",
      "Kāraikkudi",
      "Kāramadai",
      "Kātpādi",
      "Lalgudi",
      "Madambakkam",
      "Madipakkam",
      "Madukkarai",
      "Madukkūr",
      "Madurai",
      "Madurāntakam",
      "Mallasamudram",
      "Manali",
      "Manamadurai",
      "Manapparai",
      "Mandapam",
      "Mannargudi",
      "Marakkanam",
      "Mayiladuthurai",
      "Melur",
      "Mettupalayam",
      "Mettur",
      "Musiri",
      "Muttupet",
      "Mīnjūr",
      "Nambiyūr",
      "Nattam",
      "Neelankarai",
      "Nellikkuppam",
      "Nilakottai",
      "Nāgappattinam",
      "Nāgercoil",
      "Nāmagiripettai",
      "Nāmakkal",
      "Nāravārikuppam",
      "Ooty",
      "Padmanābhapuram",
      "Palani",
      "Palladam",
      "Pallappatti",
      "Pallikondai",
      "Pallippatti",
      "Pallāvaram",
      "Panruti",
      "Papanasam",
      "Paramagudi",
      "Pattukkottai",
      "Pennādam",
      "Pennāgaram",
      "Perambalur",
      "Peranāmpattu",
      "Peravurani",
      "Periyakulam",
      "Periyanayakkanpalaiyam",
      "Perundurai",
      "Perungudi",
      "Pollachi",
      "Polūr",
      "Ponneri",
      "Poonamalle",
      "Porur",
      "Pudukkottai",
      "Puliyangudi",
      "Punjai Puliyampatti",
      "Pālakkodu",
      "Rajapalaiyam",
      "Ramanathapuram",
      "Rameswaram",
      "Rasipuram",
      "Saint Thomas Mount",
      "Salem",
      "Sathyamangalam",
      "Sattur",
      "Sholinghur",
      "Singānallūr",
      "Sirumugai",
      "Sivaganga",
      "Sivagiri",
      "Sivakasi",
      "Srivaikuntam",
      "Srivilliputhur",
      "Srīperumbūdūr",
      "Sulur",
      "Sīrkāzhi",
      "Sūrandai",
      "Teni",
      "Thanjāvūr",
      "Tharangambadi",
      "Thenkasi",
      "Thiruthani",
      "Thiruvarur",
      "Tindivanam",
      "Tinnanūr",
      "Tiruchchendur",
      "Tiruchengode",
      "Tiruchirappalli",
      "Tirukkoyilur",
      "Tirunelveli",
      "Tirupparangunram",
      "Tiruppur",
      "Tiruppuvanam",
      "Tiruttangal",
      "Tiruvallur",
      "Tiruvannāmalai",
      "Tiruvottiyūr",
      "Tisaiyanvilai",
      "Tondi",
      "Turaiyūr",
      "Tāramangalam",
      "Udankudi",
      "Udumalaippettai",
      "Usilampatti",
      "Thanjavur",
      "Uttamapālaiyam",
      "Uttiramerūr",
      "V.S.K.Valasai (Dindigul-Dist.)",
      "Vadakku Valliyūr",
      "Vadamadurai",
      "Valparai",
      "Vandavāsi",
      "Vaniyambadi",
      "Vattalkundu",
      "Vedaraniyam",
      "Vellore",
      "Velur",
      "Vettaikkaranpudur",
      "Villupuram",
      "Viravanallūr",
      "Virudunagar",
      "Vriddhāchalam",
      "Vādippatti",
      "Vāsudevanallūr",
      "Walajapet",
      "Wellington",
      "Ālangulam",
      "Ālangāyam",
      "Āndippatti",
      "Ārani",
      "Āvadi",
      "Tamil Naidu",
      "Trichy",
      "Kattankulathur",
    ],
    Telangana: [
      "Andol",
      "Asifābād",
      "Bellampalli",
      "Bhadrāchalam",
      "Bhaisa",
      "Bhongīr",
      "Bodhan",
      "Bānswāda",
      "Dasnapur",
      "Devarkonda",
      "Dornakal",
      "Farrukhnagar",
      "Gaddi Annaram",
      "Gadwāl",
      "Ghatkesar",
      "Hyderabad",
      "Jagtiāl",
      "Jangaon",
      "Karīmnagar",
      "Khammam",
      "Kodār",
      "Koratla",
      "Kottagūdem",
      "Kyathampalle",
      "Kāmāreddi",
      "Kūkatpalli",
      "Lal Bahadur Nagar",
      "Mahbūbnagar",
      "Mahbūbābād",
      "Malkajgiri",
      "Mancherāl",
      "Mandamarri",
      "Manthani",
      "Manuguru",
      "Medak",
      "Miriālgūda",
      "Nalgonda",
      "Nirmal",
      "Nizāmābād",
      "Nāgar Karnūl",
      "Nārāyanpet",
      "Nāspur",
      "Palwancha",
      "Patancheru",
      "Peddapalli",
      "Pāloncha",
      "Quthbullapur",
      "Ramagundam",
      "Rāmgundam",
      "Sadāseopet",
      "Sangāreddi",
      "Sathupalli",
      "Secunderabad",
      "Serilingampalle",
      "Siddipet",
      "Singāpur",
      "Sirsilla",
      "Srīrāmnagar",
      "Suriāpet",
      "Tāndūr",
      "Uppal Kalan",
      "Vemalwāda",
      "Vikārābād",
      "Wanparti",
      "Warangal",
      "Yellandu",
      "Zahirābād",
      "Ādilābād",
      "Serilingampally Manda",
      "Hyderbad",
      "Shankarpally Mandal",
    ],
    Tripura: [
      "Agartala",
      "Barjala",
      "Belonia",
      "Dharmanagar",
      "Kailāshahar",
      "Khowai",
      "Udaipur",
    ],
    "Uttar Pradesh": [
      "Achhnera",
      "Afzalgarh",
      "Ahraura",
      "Ajodhya",
      "Akbarpur",
      "Allahābād",
      "Alīganj",
      "Alīgarh",
      "Amroha",
      "Anūpshahr",
      "Aonla",
      "Atarra",
      "Atraulī",
      "Auraiya",
      "Azamgarh",
      "Baberu",
      "Babrāla",
      "Babīna",
      "Bachhraon",
      "Baheri",
      "Bahjoi",
      "Bahraigh",
      "Balrāmpur",
      "Banat",
      "Baraut",
      "Bareilly",
      "Bastī",
      "Behat",
      "Bela",
      "Bewar",
      "Bhadohi",
      "Bharthana",
      "Bharwāri",
      "Bhinga",
      "Bhongaon",
      "Bidhūna",
      "Bijnor",
      "Bilgrām",
      "Bilhaur",
      "Bilsanda",
      "Bilsi",
      "Bilthra",
      "Bilāri",
      "Bindki",
      "Bisauli",
      "Biswān",
      "Budaun",
      "Budhāna",
      "Bulandshahr",
      "Bāghpat",
      "Bāh",
      "Bānda",
      "Bāngarmau",
      "Bānsdīh",
      "Bānsi",
      "Bīlāspur",
      "Bīsalpur",
      "Chandauli",
      "Chanduasi",
      "Charkhāri",
      "Charthāwal",
      "Chhaprauli",
      "Chharra",
      "Chhibrāmau",
      "Chhāta",
      "Chillupār",
      "Chunār",
      "Chāndpur",
      "Colonelganj",
      "Deoband",
      "Deoraniān",
      "Deoria",
      "Dhanaura",
      "Dhaurahra",
      "Dhāmpur",
      "Dibai",
      "Dādri",
      "Dāsna",
      "Dātāganj",
      "Etāwah",
      "Farrukhābād",
      "Farīdpur",
      "Fatehganj West",
      "Fatehpur",
      "Fatehpur Sīkri",
      "Fatehābād",
      "Fyzābād",
      "Fīrozābād",
      "Gajraula",
      "Gangoh",
      "Ganj Dundwāra",
      "Garhmuktesar",
      "Ghazīpur",
      "Ghosī",
      "Ghātampur",
      "Ghāziābād",
      "Gola Gokarannāth",
      "Gondā City",
      "Gorakhpur",
      "Govardhan",
      "Greater Noida",
      "Gulāothi",
      "Gunnaur",
      "Gursahāiganj",
      "Gursarāi",
      "Haldaur",
      "Hamīrpur",
      "Handiā",
      "Hardoī",
      "Hasanpur",
      "Hastināpur",
      "Hāpur",
      "Hāthras",
      "Indergarh",
      "Islāmnagar",
      "Itimādpur",
      "Jagdīshpur",
      "Jahāngīrābād",
      "Jais",
      "Jalesar",
      "Jalālpur",
      "Jalālābad",
      "Jalālābād",
      "Jalālī",
      "Jarwal",
      "Jaswantnagar",
      "Jaunpur",
      "Jewar",
      "Jhinjhāna",
      "Jhālu",
      "Jhānsi",
      "Jhīnjhak",
      "Jhūsi",
      "Jālaun",
      "Jānsath",
      "Kabrāi",
      "Kachhwa",
      "Kaimganj",
      "Kairāna",
      "Kakrāla",
      "Kamalganj",
      "Kannauj",
      "Kanpur",
      "Karhal",
      "Kemrī",
      "Khada",
      "Khair",
      "Khairābād",
      "Khalīlābād",
      "Khatauli",
      "Khekra",
      "Kheri",
      "Khurja",
      "Khūtār",
      "Kiraoli",
      "Kithor",
      "Konch",
      "Kopāganj",
      "Kosi",
      "Kulpahār",
      "Kunda",
      "Kundarkhi",
      "Kākori",
      "Kālpi",
      "Kāndhla",
      "Kānt",
      "Kānth",
      "Kāsganj",
      "Kīratpur",
      "Lakhīmpur",
      "Lalitpur",
      "Lar",
      "Loni",
      "Lucknow",
      "Lāharpur",
      "Lālganj",
      "Lāwar Khās",
      "Machhlīshahr",
      "Maghar",
      "Mahmudābād",
      "Mahobā",
      "Maholi",
      "Mahārāganj",
      "Mainpuri",
      "Malīhābād",
      "Mandāwar",
      "Maniar",
      "Manjhanpur",
      "Mariāhu",
      "Mathura",
      "Mau",
      "Mau Aimma",
      "Maudaha",
      "Mawāna",
      "Meerut",
      "Mehndāwal",
      "Milak",
      "Mirzāpur",
      "Misrikh",
      "Morādābād",
      "Mubārakpur",
      "Mughal Sarāi",
      "Muhammadābād",
      "Murādnagar",
      "Muzaffarnagar",
      "Mārahra",
      "Mīrganj",
      "Mīrānpur",
      "Mīrānpur Katra",
      "Nagīna",
      "Najībābād",
      "Nakūr",
      "Nanauta",
      "Narauli",
      "Naraura",
      "Nautanwa",
      "Nawābganj",
      "Nichlaul",
      "Nihtaur",
      "Noida",
      "Nānpāra",
      "Nūrpur",
      "Obra",
      "Orai",
      "Pachperwa",
      "Padrauna",
      "Pahāsu",
      "Paliā Kalān",
      "Parīchhatgarh",
      "Pawāyan",
      "Phalauda",
      "Phaphūnd",
      "Phulpur",
      "Pihānī",
      "Pilkhua",
      "Pināhat",
      "Pipraich",
      "Pukhrāyān",
      "Purwā",
      "Pīlibhīt",
      "Pūranpur",
      "Raebareli",
      "Rasrā",
      "Renukūt",
      "Reoti",
      "Richha",
      "Robertsganj",
      "Rura",
      "Rāmnagar",
      "Rāmpur",
      "Rānīpur",
      "Rāth",
      "Rāya",
      "Rūdarpur",
      "Sadābād",
      "Safīpur",
      "Sahaspur",
      "Sahaswān",
      "Sahāranpur",
      "Sahāwar",
      "Saidpur",
      "Sambhal",
      "Samthar",
      "Sandīla",
      "Sarauli",
      "Sardhana",
      "Sarāi Mīr",
      "Sarāi Ākil",
      "Seohāra",
      "Shamsābād",
      "Sherkot",
      "Shikohābād",
      "Shikārpūr",
      "Shāhganj",
      "Shāhi",
      "Shāhjānpur",
      "Shāhpur",
      "Shāhābād",
      "Shāmli",
      "Shīshgarh",
      "Sidhaulī",
      "Sikandarpur",
      "Sikandarābād",
      "Sikandra Rao",
      "Sirsi",
      "Sirsāganj",
      "Sisauli",
      "Siswā Bāzār",
      "Soron",
      "Sultānpur",
      "Suriānwān",
      "Suār",
      "Sāndi",
      "Sītāpur",
      "Thakurdwara",
      "Thāna Bhawan",
      "Tilhar",
      "Tulsīpur",
      "Tājpur",
      "Tānda",
      "Tāndā",
      "Tūndla",
      "Ujhāni",
      "Unnāo",
      "Usehat",
      "Utraula",
      "Varanasi",
      "Vrindāvan",
      "Wazīrganj",
      "Zaidpur",
      "Zamānia",
      "Āgra",
      "Ūn",
      "Allahabad",
      "Uttar Pradesh",
      "NCR - Noida",
      "Bilaspur",
      "Greater Noida West",
      "Ghaziabad",
      "Moradabad",
      "Agra",
    ],
    Uttarakhand: [
      "Almora",
      "Bāzpur",
      "Clement Town",
      "Dehradun",
      "Haldwani",
      "Haridwar",
      "Jaspur",
      "Kashipur",
      "Khatīma",
      "Kichha",
      "Kotdwāra",
      "Laksar",
      "Manglaur",
      "Mussoorie",
      "Naini Tāl",
      "Pauri",
      "Pithorāgarh",
      "Rishīkesh",
      "Roorkee",
      "Rāipur",
      "Rāmnagar",
      "Rānikhet",
      "Sitārganj",
      "Srīnagar",
      "Tanakpur",
      "Tehri",
      "Uttarkāshi",
    ],
    "West Bengal": [
      "Adra",
      "Aistala",
      "Arāmbāgh",
      "Ashoknagar Kalyangarh",
      "Badūria",
      "Bagulā",
      "Baharampur",
      "Bahula",
      "Baidyabāti",
      "Baj Baj",
      "Balarāmpur",
      "Bangaon",
      "Bankra",
      "Baranagar",
      "Barddhamān",
      "Beldānga",
      "Bhadreswar",
      "Bhātpāra",
      "Birpara",
      "Bishnupur",
      "Bolpur",
      "Bāghdogra",
      "Bāli",
      "Bālurghāt",
      "Bānkura",
      "Bānsbāria",
      "Bāruipur",
      "Bārākpur",
      "Bārāsat",
      "Canning",
      "Chakapara",
      "Chandannagar",
      "Chandrakona",
      "Chittaranjan",
      "Contai",
      "Dalkola",
      "Dam Dam",
      "Dhulagari",
      "Dhuliān",
      "Dhupgāri",
      "Diamond Harbour",
      "Dubrājpur",
      "Dumjor",
      "Durgapur",
      "Dārjiling",
      "Dīnhāta",
      "Egra",
      "Farakka",
      "Fort Gloster",
      "Fālākāta",
      "Gangārāmpur",
      "Garui",
      "Ghātāl",
      "Gobārdānga",
      "Gosāba",
      "Goyerkāta",
      "Guskhara",
      "Haldia",
      "Hugli",
      "Hābra",
      "Hālīsahar",
      "Hāora",
      "Hāsimāra",
      "Ingrāj Bāzār",
      "Islāmpur",
      "Jaigaon",
      "Jalpāiguri",
      "Jangipur",
      "Jaynagar-Majilpur",
      "Jhalida",
      "Jhārgrām",
      "Jāmuria",
      "Kalyani",
      "Kenda",
      "Kharagpur",
      "Khardah",
      "Khātra",
      "Koch Bihār",
      "Kolkata",
      "Konnagar",
      "Krishnanagar",
      "Kultali",
      "Kulti",
      "Kālimpong",
      "Kāliyāganj",
      "Kālna",
      "Kāmārhāti",
      "Kānchrāpāra",
      "Kāndi",
      "Kārsiyāng",
      "Kātoya",
      "Lakhyabad",
      "Lālgola",
      "Madhyamgram",
      "Mahiari",
      "Mahīshādal",
      "Maināguri",
      "Medinīpur",
      "Memāri",
      "Monoharpur",
      "Murshidābād",
      "Mātābhānga",
      "Nabagrām",
      "Naihāti",
      "Naksalbāri",
      "Nalhāti",
      "Nangi",
      "Navadwīp",
      "Pandua",
      "Pujali",
      "Puruliya",
      "Pānchla",
      "Pānihāti",
      "Raghunathpur",
      "Rampur Hat",
      "Rishra",
      "Rāiganj",
      "Rājmahal",
      "Rāmjībanpur",
      "Rānāghāt",
      "Rānīganj",
      "Sainthia",
      "Shiliguri",
      "Shrīrāmpur",
      "Shyamnagar",
      "Shāntipur",
      "Singur",
      "Siuri",
      "Sonāmukhi",
      "Srirāmpur",
      "Sānkrāil",
      "Tamlūk",
      "Tarakeswar",
      "Titāgarh",
      "Tufānganj",
      "Tāki",
      "Āmlāgora",
      "Āmta",
      "Āsansol",
      "Siliguri",
      "Darjeeling",
      "Howrah",
    ],
    "New Delhi": ["Delhi", "Malviya Nagar", "New Delhi"],
    "Jammu and Kashmir": ["Jammu"],
    Delhi: ["NCR - Delhi", "Delhi", "NCR Delhi", "New Delhi"],
    Uttaranchal: [
      "Dehradun",
      "Haldwani",
      "Mainital",
      "Roorkee",
      "Haridwar",
      "Greater Noida",
    ],
    Mumbai: ["Mumbai"],
    Orissa: ["Bhubaneswar"],
  },
  "Sri Lanka": {
    Central: [
      "Apam",
      "Cape Coast",
      "Capiatá",
      "Colonia Mariano Roque Alonso",
      "Dambulla",
      "Dunkwa",
      "Elmina",
      "Fernando de la Mora",
      "Foso",
      "Gampola",
      "Hatton",
      "Itauguá",
      "Itá",
      "Kabwe",
      "Kandy",
      "Kapiri Mposhi",
      "Kasoa",
      "Lambaré",
      "Letlhakane",
      "Limpio",
      "Mahalapye",
      "Mumbwa",
      "Nemby",
      "Nuwara Eliya",
      "Palapye",
      "Saltpond",
      "San Antonio",
      "San Lorenzo",
      "Selebi-Phikwe",
      "Serowe",
      "Suva",
      "Swedru",
      "Tonota",
      "Villa Elisa",
      "Winneba",
    ],
    "Eastern Province": [
      "Abqaiq",
      "Abū Kabīr",
      "Aketi",
      "Al Baţţālīyah",
      "Al Hufūf",
      "Al Ibrāhīmīyah",
      "Al Jubayl",
      "Al Khafjī",
      "Al Mubarraz",
      "Al Munayzilah",
      "Al Qanāyāt",
      "Al Qayşūmah",
      "Al Qaţīf",
      "Al Qurayn",
      "Ampara",
      "Aţ Ţaraf",
      "Basoko",
      "Batticaloa",
      "Bilbays",
      "Bondo",
      "Bunia",
      "Buta",
      "Dammam",
      "Dhahran",
      "Diyarb Najm",
      "Eravur Town",
      "Fāqūs",
      "Hihyā",
      "Isiro",
      "Kafr Şaqr",
      "Kalmunai",
      "Kenema",
      "Khobar",
      "Kibungo",
      "Kisangani",
      "Koidu",
      "Mashtūl as Sūq",
      "Raḩīmah",
      "Rwamagana",
      "Sayhāt",
      "Segbwema",
      "Shanjeev Home",
      "Trincomalee",
      "Tārūt",
      "Wamba",
      "Watsa",
      "Yangambi",
      "Zagazig",
      "Şafwá",
    ],
    "North Central": ["Anuradhapura"],
    "North Western": ["Chilaw", "Kurunegala", "Puttalam"],
    "Northern Province": [
      "Byumba",
      "Jaffna",
      "Kabala",
      "Lunsar",
      "Makeni",
      "Musanze",
      "Point Pedro",
      "Popondetta",
      "Port Loko",
      "Valvedditturai",
      "Vavuniya",
    ],
    Sabaragamuwa: ["Ratnapura"],
    Southern: [
      "Ambalangoda",
      "Bentota",
      "Choma",
      "Galle",
      "Kanye",
      "Livingstone",
      "Matara",
      "Mazabuka",
      "Monze",
      "Mosopa",
      "Siavonga",
      "Weligama",
    ],
    Uva: ["Badulla"],
    Western: [
      "Axim",
      "Battaramulla South",
      "Beruwala",
      "Bibiani",
      "Brikama",
      "Colombo",
      "Dehiwala-Mount Lavinia",
      "Galkissa",
      "Hanwella Ihala",
      "Hendala",
      "Homagama",
      "Ja Ela",
      "Kalutara",
      "Kandana",
      "Katunayaka",
      "Kelaniya",
      "Kolonnawa",
      "Kotikawatta",
      "Lautoka",
      "Maharagama",
      "Mongu",
      "Moratuwa",
      "Mount Lavinia",
      "Mulleriyawa",
      "Nadi",
      "Negombo",
      "Panadura",
      "Peliyagoda",
      "Pita Kotte",
      "Prestea",
      "Sekondi-Takoradi",
      "Sesheke",
      "Shama Junction",
      "Sri Jayewardenepura Kotte",
      "Sukuta",
      "Takoradi",
      "Tarkwa",
      "Wattala",
      "Welisara",
    ],
  },
  Bangladesh: {
    Barisāl: [
      "Barisāl",
      "Bhola",
      "Bhāndāria",
      "Burhānuddin",
      "Gaurnadi",
      "Lālmohan",
      "Mathba",
      "Mehendiganj",
      "Nālchiti",
      "Pirojpur",
    ],
    Chittagong: [
      "Bāndarban",
      "Chhāgalnāiya",
      "Chittagong",
      "Comilla",
      "Cox’s Bāzār",
      "Fatikchari",
      "Feni",
      "Hājīganj",
      "Khagrachhari",
      "Lakshmīpur",
      "Lākshām",
      "Manikchari",
      "Nabīnagar",
      "Patiya",
      "Raojān",
      "Rāipur",
      "Rāmganj",
      "Sandwīp",
      "Sātkania",
      "Teknāf",
    ],
    Dhaka: [
      "Azimpur",
      "Bhairab Bāzār",
      "Bājitpur",
      "Char Bhadrāsan",
      "Dhaka",
      "Dohār",
      "Farīdpur",
      "Gafargaon",
      "Jamālpur",
      "Kishorganj",
      "Mirzāpur",
      "Muktāgācha",
      "Mymensingh",
      "Mādārīpur",
      "Narsingdi",
      "Netrakona",
      "Nāgarpur",
      "Nārāyanganj",
      "Paltan",
      "Pālang",
      "Sakhipur",
      "Sarishābāri",
      "Sherpur",
      "Tungi",
      "Tungipāra",
      "Tāngāil",
    ],
    Khulna: [
      "Bherāmāra",
      "Bhātpāra Abhaynagar",
      "Jessore",
      "Jhingergācha",
      "Kesabpur",
      "Khulna",
      "Kushtia",
      "Kālia",
      "Kālīganj",
      "Morrelgonj",
      "Narail",
      "Phultala",
      "Sarankhola",
      "Sātkhira",
      "Uttar Char Fasson",
    ],
    "Rangpur Division": [
      "Badarganj",
      "Chilmāri",
      "Dinājpur",
      "Lalmanirhat",
      "Nageswari",
      "Panchagarh",
      "Parbatipur",
      "Pīrgaaj",
      "Rangpur",
      "Thākurgaon",
    ],
    Rājshāhi: [
      "Bera",
      "Bogra",
      "Ishurdi",
      "Joypur Hāt",
      "Nawābganj",
      "Pābna",
      "Pār Naogaon",
      "Rājshāhi",
      "Saidpur",
      "Shibganj",
      "Shāhzādpur",
      "Sirājganj",
    ],
    Sylhet: ["Baniachang", "Chhātak", "Habiganj", "Maulavi Bāzār", "Sylhet"],
  },
};
