import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Button,
  Chip,
  IconButton,
  TextareaAutosize,  
  Typography
} from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate
} from "../../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { getFileName } from "../../../utils/common-utils";
import { hasApproveAccessNow } from "../../../utils/utils";
import {
  getHeaderPOItemsDetailsConfig,
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
  getPurchaseOrderWithoutTaxAmount
} from "../helper";
import ApprovalStageDetails from "../../approval-stage-details";

const PODetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [approveRejectDetails, setApproveRejectionDetails] = useState(null);
  const [poDetails, setPODetails] = useState({});
  const [filters, setFilters] = useState({
    approved: false,
    rejected: false
  });

  const {
    site = {},
    items = [],
    documentLinks = [],
    currency = {}
  } = poDetails;

  const { name: siteName = "", code } = site || {};
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}${id}`,
      null,
      null
    )
      .then((response) => {
        if (response.message) {
        } else if (response.id) {
          setPODetails(response || {});
        }
      })
      .catch((err) => {});
  };

  const onApprove = (status) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}bulk-approve`,
      {
        poIds: [id],
        comment: approveRejectDetails.comment || "",
        status
      }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          loadData();
          setApproveRejectionDetails(null);
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const onReject = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}reject`,
      null,
      { comment: approveRejectDetails.comment || "", id: [id] }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reject");
        } else {
          setApproveRejectionDetails(null);
          loadData();
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className='sw-po-details-container'>
      <div className='po-details-header'>
        <div className='back-title'>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography className='title'>
            PO Details for {poDetails.poNumber}
          </Typography>
        </div>
        <div className='right-section'>
          {/* <Button variant="outlined" onClick={getPOasPdf}>
            Print PO
          </Button> */}
          {hasApproveAccessNow(poDetails) &&
            ["APPROVAL_IN_PROGRESS"].includes(poDetails.status) && (
              <div className='right-section'>
                <Button
                  variant='outlined'
                  onClick={() =>
                    setApproveRejectionDetails({
                      ...poDetails,
                      toApprove: true
                    })
                  }
                >
                  Approve
                </Button>
                <Button
                  variant='outlined'
                  color='error'
                  onClick={() => setApproveRejectionDetails(poDetails)}
                >
                  Reject
                </Button>
              </div>
            )}
        </div>
      </div>
      <div className={"po-detail-approval-stage"}>
        <div className='po-details'>
          <div className='form-container'>
            <div className='info-section'>
              <div className='info-box'>
                <span>Site Name</span>
                <span>{siteName || ""}</span>
              </div>
              <div className='info-box'>
                <span>Site Code</span>
                <span>{code || ""}</span>
              </div>
              <div className='info-box'>
                <span>Base Amt </span>
                <span>
                  {getPurchaseOrderWithoutTaxAmount(items) || "0"}{" "}
                  {currency.name}
                </span>
              </div>
              <div className='info-box'>
                <span>GST Amt </span>
                <span>
                  {getPurchaseOrderTaxAmount(items) || "0"} {currency.name}
                </span>
              </div>
              <div className='info-box'>
                <span>Total Amt </span>
                <span>
                  {getPurchaseOrderAmount(items) || "0"} {currency.name}
                </span>
              </div>
              <div className='info-box'>
                <span> PO Date </span>
                <span>
                  {formatDate(poDetails.poDate, DATE_FORMATS["DD-MM-YYYY"])}
                </span>
              </div>
              <div className='info-box'>
                <span> Vendor </span>
                <span>{poDetails.vendorCode}</span>
              </div>
              <div className='info-box'>
                <span> Vendor GST</span>
                <span>{poDetails.vendorGst}</span>
              </div>
              <div className='info-box'>
                <span> Vendor Name</span>
                <span>{poDetails.vendorName}</span>
              </div>
              <div className='info-box'>
                <span>Status </span>
                <span>
                  {convertToCamelCaseFromUnderScore(poDetails.status) || "-"}
                </span>
              </div>
              <div className='info-box'>
                <span>Created By </span>
                <span>{poDetails.creatorName || "-"}</span>
              </div>
              <div className='info-box'>
                <span>Additional Documents </span>
                <span>
                  {documentLinks.map((attachment) => {
                    return (
                      <Chip
                        label={getFileName(attachment)}
                        onClick={() => window.open(attachment, "_blank")}
                      />
                    );
                  })}
                  {documentLinks.length === 0 && "-"}
                </span>
              </div>
            </div>
          </div>
          <div className='form-container'>
            <MuiTable
              columnsList={getHeaderPOItemsDetailsConfig()}
              dataList={
                items?.slice(
                  ((filters?.page || 1) - 1) * 10,
                  ((filters?.page || 1) - 1) * 10 + 10
                ) || []
              }
              pageCount={Math.ceil(items.length / 10)}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page
                });
              }}
            />
          </div>
        </div>

        <div className='approval-details'>
          <ApprovalStageDetails approvalDetails={poDetails} />
        </div>
      </div>
      {approveRejectDetails && (
        <CustomModal
          title={`${
            approveRejectDetails.toApprove ? "Approve PO" : "Reject PO"
          } `}
          disabledOutsideClick={true}
          onClose={() => {
            setApproveRejectionDetails(null);
          }}
        >
          <div className='b-modal-form-container'>
            <TextareaAutosize
              value={approveRejectDetails.comment}
              style={{ width: "100%", marginTop: 10 }}
              onChange={(e) =>
                setApproveRejectionDetails({
                  ...approveRejectDetails,
                  comment: e.target.value
                })
              }
              placeholder='Enter your comments here'
              minRows={4}
              size='small'
              name='comment'
              label='Comment'
              fullWidth
            />

            <Button
              color={`${approveRejectDetails.toApprove ? "primary" : "error"}`}
              variant='outlined'
              onClick={() => {
                if (approveRejectDetails.toApprove) {
                  onApprove("APPROVED");
                  return;
                }
                onApprove("REJECTED");
              }}
              disabled={!approveRejectDetails.comment}
              fullWidth             
            >
              {approveRejectDetails.toApprove ? "Approve" : "Reject"}
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default PODetails;
