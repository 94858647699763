import moment from "moment";
import { Button } from "@mui/material";

export const getBudgetHeadersConfig = () => {
  const headers = [
    {
      label: "Site Code",
      id: "Site Code",
    },
    {
      label: "Location",
      id: "Location",
    },
    {
      label: "Item Main Category India",
      id: "Item Main Category India",
    },
    {
      label: "Status",
      id: "Current Status",
    },
    {
      label: "Budget No.",
      id: "Budget No",
    },
    {
      label: "Budget Name",
      id: "Budget Name",
    },
    {
      label: "Budget Amount",
      id: "Budget Amount",
    },
    {
      label: "Available Budget Amount",
      id: "Available Budget Amount",
    },
    {
      label: "Last Action Date",
      id: "Last Action Date",
    },
    {
      label: "Creation Date",
      id: "Creation Date",
    },
    {
      label: "Created By",
      id: "Creator Name",
    },
  ];
  return headers;
};
export const budgetFilters = [
  { label: "Budget Amount", value: "totalAmount" },
  { label: "Creation Date", value: "createdAt" },
  { label: "Current Status", value: "status" },
  { label: "Category", value: "category" },
  { label: "Last Action Date", value: "updatedAt" },
  { label: "Location Name", value: "siteName" },
  { label: "Financial Year", value: "financialYear" },
];
export const budgetStatus = [
  { label: "Created", value: "created" },
  { label: "Draft", value: "draft" },
  { label: "Approval In Progress", value: "approval_in_progress" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Inactive", value: "inactive" },
];
export const masterPoFilters = [
  { label: "Store Code", value: "siteCode" },
  { label: "Store Name", value: "restaurantName" },
  { label: "Store Format", value: "format" },
  { label: "Vendor Code", value: "vendorCode" },
  { label: "Vendor Name", value: "vendorName" },
];
export const getMasterPoHeadersConfig = () => {
  const headers = [
    {
      label: "Store Code",
      id: "Store Code",
    },
    {
      label: "Store Name",
      id: "Store Name",
    },
    {
      label: "Store Address",
      id: "Store Address",
    },
    {
      label: "Store Format",
      id: "Store Format",
    },
    {
      label: "Store Type",
      id: "Store Type",
    },
    {
      label: "Area",
      id: "Area",
    },
    {
      label: "Region",
      id: "Region",
    },
    {
      label: "Item Main Category",
      id: "Item Main Category",
    },
    {
      label: "Status",
      id: "Status",
    },
    {
      label: "Original Uploaded Budget",
      id: "Original Uploaded Budget",
    },
    {
      label: "Budget as of Date (Current)",
      id: "Budget as of Date (Current)",
    },
    {
      label: "PO No.",
      id: "PO No.",
    },
    {
      label: "GRN No",
      id: "GRN No",
    },
    {
      label: "Invoice No",
      id: "Invoice No",
    },
    {
      label: "Invoice Date",
      id: "Invoice Date",
    },
    {
      label: "PO Amount",
      id: "PO Amount",
    },
    {
      label: "Vendor Code",
      id: "Vendor Code",
    },
    {
      label: "Vendor Name",
      id: "Vendor Name",
    },
    {
      label: "Total Invoice Amount for the PO",
      id: "Total Invoice Amount for the PO",
    },
    {
      label: "Total Po Line Item Amount",
      id: "Total Po Line Item Amount",
    },
    {
      label: "Balance PO Amount",
      id: "Balance PO Amount",
    },
    {
      label: "Balance Budget Amount",
      id: "Balance Budget Amount",
    },
  ];
  return headers;
};

export const getPoCapexHeadersConfig = (approverHeaders) => {
  const headers = [
    {
      label: "Doc ID",
      id: "DocID",
    },
    {
      label: "Site Code",
      id: "Store Code",
    },
    {
      label: "Store GSTIN",
      id: "Store GSTIN",
    },
    {
      label: "Vendor Code",
      id: "Vendor Code",
    },
    {
      label: "Vendor Name",
      id: "Vendor Name",
    },
    {
      label: "Vendor GSTIN",
      id: "Vendor GSTIN",
    },
    {
      label: "Uom",
      id: "Uom",
    },
    {
      label: "Total PO Amount",
      id: "Total PO Amount",
    },
    {
      label: "Total Amount",
      id: "Total Amount",
    },
    {
      label: "Tax Rate",
      id: "Tax Rate",
    },
    {
      label: "Rate",
      id: "Rate",
    },
    {
      label: "Quantity",
      id: "Quantity",
    },
    {
      label: "Payment Terms (Vendor Master)",
      id: "Payment Terms (Vendor Master)",
    },
    {
      label: "PO Type",
      id: "PO Type",
    },
    {
      label: "PO Status",
      id: "PO Status",
    },
    {
      label: "PO Number",
      id: "PO Number",
    },
    {
      label: "PO Date",
      id: "PO Date",
    },
    {
      label: "Item Name",
      id: "Item Name",
    },
    {
      label: "Item Main Category India",
      id: "Item Main Category India",
    },
    {
      label: "Item Sub Category India",
      id: "Item Sub Category India",
    },
    {
      label: "HSN No",
      id: "HSN No",
    },
    {
      label: "Forex Rate",
      id: "Forex Rate",
    },
    {
      label: "Department Hod",
      id: "Department Hod",
    },
    {
      label: "Department",
      id: "Department",
    },
    {
      label: "Delivery Location",
      id: "Delivery Location",
    },
    {
      label: "Billing Location",
      id: "Billing Location",
    },
    {
      label: "Current User Name",
      id: "Current User Name",
    },
    {
      label: "Currency",
      id: "Currency",
    },
    {
      label: "Company",
      id: "Company",
    },
    {
      label: "Billing Address",
      id: "Billing Address",
    },
    {
      label: "Terms and Conditions",
      id: "Terms and Conditions",
    },
    {
      label: "Balance PO Amount",
      id: "Balance PO Amount",
    },
    {
      label: "Bal PO Quantity",
      id: "Bal PO Quantity",
    },
    {
      label: "Available Budget Amount",
      id: "Available Budget Amount",
    },
    {
      label: "Amount",
      id: "Amount",
    },
    {
      label: "Agreement Remarks",
      id: "Agreement Remarks",
    },
    {
      label: "Agreement",
      id: "Agreement",
    },
    {
      label: "Advance Percent",
      id: "Advance Percent",
    },
    {
      label: "Advance Amount",
      id: "Advance Amount",
    },
    {
      label: "Advance Processed Amt",
      id: "Advance Processed Amt",
    },
    {
      label: "Created By",
      id: "Creator Name",
    },
    {
      label: "Last Action Date",
      id: "updatedAt",
      render: (data) => {
        return (
          <div>{moment(data?.["Last Action Date"]).format("DD-MM-YYYY")}</div>
        );
      },
    },
    {
      label: "Creation Date",
      id: "createdAt",
      render: (data) => {
        return (
          <div>{moment(data?.["Creation Date"]).format("DD-MM-YYYY")}</div>
        );
      },
    },
    ...approverHeaders,
    {
      label: "Payment Details",
      id: "Payment Details",
      render: (data, onClick) => {
        return (
          <>
            {data?.["Payment Details"]?.length ? (
              <Button
                onClick={() => {
                  onClick(data, "viewPaymentDetails");
                }}
                variant="text"
              >
                View
              </Button>
            ) : (
              <> </>
            )}
          </>
        );
      },
    },
  ];

  return headers;
};
export const poFilters = [
  { label: "Site Code - Store GSTIN", value: "site" },
  { label: "Vendor Name - Vendor Code - Vendor GSTIN", value: "vendorCode" },
  { label: "PO Type", value: "poType" },
  { label: "PO Number", value: "poNumber" },
  { label: "PO Status", value: "status" },
  { label: "PO Date", value: "poDate" },
  { label: "Item Name", value: "itemId" },
  { label: "Item Main Category", value: "category" },
  { label: "Item Sub Category", value: "subCategory" },
  { label: "Department - HOD", value: "department" },
  { label: "Delivery location", value: "deliveryLocationId" },
  { label: "Currency", value: "currency" },
  { label: "Billing location", value: "billingLocationId" },
  { label: "Creator", value: "createdBy" },
  { label: "Financial Year", value: "financialYear" },
  { label: "Creation Date", value: "creationDate" },
];
export const PO_STATUS = [
  { label: "Created", value: "created" },
  { label: "Draft", value: "draft" },
  { label: "Approval In Progress", value: "approval_in_progress" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Inactive", value: "inactive" },
  { label: "Not Initiated", value: "not_initiated" },
  { label: "Reconsider", value: "reconsider" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Short Close", value: "short_close" },
];
export const getGrnHeadersConfig = (approverHeaders) => {
  const headers = [
    {
      label: "Doc ID",
      id: "Doc Id",
    },
    {
      label: "Site Code",
      id: "Store Code",
    },
    {
      label: "GRN No",
      id: "GRN No",
    },
    {
      label: "Store GSTIN",
      id: "Store GSTIN",
    },
    {
      label: "Vendor Code",
      id: "Vendor Code",
    },
    {
      label: "Vendor Name",
      id: "Vendor Name",
    },
    {
      label: "Vendor GSTIN",
      id: "Vendor GSTIN",
    },
    {
      label: "Uom",
      id: "Uom",
    },
    {
      label: "UTR No",
      id: "UTR No",
    },
    {
      label: "Status",
      id: "Current Status",
    },
    {
      label: "Current User",
      id: "Current User Name",
    },
    {
      label: "Payment Date",
      id: "Payment Date",
    },
    {
      label: "PO Amount",
      id: "PO Amount",
    },
    {
      label: "Po Quantity",
      id: "Po Quantity",
    },
    {
      label: "Tax Amount",
      id: "Tax Amount",
    },
    {
      label: "Total Amount",
      id: "Total Amount",
    },
    {
      label: "Tax Rate",
      id: "Tax Rate",
    },
    {
      label: "Rate",
      id: "Rate",
    },
    {
      label: "Payment Terms (Vendor Master)",
      id: "Payment Terms (Vendor Master)",
    },
    {
      label: "Paid Amount",
      id: "Paid Amount",
    },
    {
      label: "PO Type",
      id: "PO Type",
    },
    {
      label: "PO Number",
      id: "Po Number",
    },
    {
      label: "PO Date",
      id: "PO Date",
    },
    {
      label: "Item Name",
      id: "Item Name",
    },
    {
      label: "Item Description",
      id: "Item Description",
    },
    {
      label: "Item Main Category India",
      id: "Item Main Category India",
    },
    {
      label: "Item Sub Category India",
      id: "Item Sub Category India",
    },
    {
      label: "HSN No",
      id: "HSN No",
    },
    {
      label: "Department Hod",
      id: "Dept Hod",
    },
    {
      label: "Department",
      id: "Department",
    },
    {
      label: "Delivery Location",
      id: "Delivery Location",
    },
    {
      label: "Received Date",
      id: "Received Date",
    },
    {
      label: "Company Name",
      id: "Company Name",
    },
    {
      label: "Billing Location",
      id: "Billing Location",
    },
    {
      label: "Balance PO Amount",
      id: "Balance PO Amount",
    },
    {
      label: "Po Balance Qty",
      id: "Po Bal Qty",
    },
    {
      label: "Amount",
      id: "Amount",
    },
    {
      label: "Invoice Date",
      id: "Invoice Date",
    },
    {
      label: "Invoice Description",
      id: "Invoice Description",
    },
    {
      label: "Invoice No",
      id: "Invoice No",
    },
    {
      label: "Invoice Qty",
      id: "Invoice Qty",
    },
    {
      label: "Invoice Qty",
      id: "Invoice Qty",
    },
    {
      label: "Last Action Date",
      id: "Last Action Date",
    },
    {
      label: "Creation Date",
      id: "Creation Date",
    },
    ...approverHeaders,
  ];
  return headers;
};
export const getPaymentHeadersConfig = (approverHeaders) => {
  const headers = [
    {
      label: "Doc ID",
      id: "Doc Id",
    },
    {
      label: "Billing Location",
      id: "Billing Location",
    },
    {
      label: "Company Name",
      id: "Company Name",
    },
    {
      label: "Creation Date",
      id: "Creation Date",
    },
    {
      label: "Vendor Code",
      id: "Vendor Code",
    },
    {
      label: "Vendor Name",
      id: "Vendor Name",
    },
    {
      label: "Vendor GSTIN",
      id: "Vendor GSTIN",
    },
    {
      label: "Creator",
      id: "Creator",
    },
    {
      label: "Currency",
      id: "Currency",
    },
    {
      label: "Status",
      id: "Current Status",
    },
    {
      label: "Current User",
      id: "Current User Name",
    },
    {
      label: "Delivery Location",
      id: "Delivery Location",
    },
    {
      label: "Department",
      id: "Department",
    },
    {
      label: "Dept Hod",
      id: "Dept Hod",
    },
    {
      label: "Invoice No",
      id: "Invoice No",
    },
    {
      label: "Invoice Amount",
      id: "Invoice Amount",
    },
    {
      label: "Invoice Date",
      id: "Invoice Date",
    },
    {
      label: "Invoice Document Number",
      id: "Invoice Document Number",
    },
    {
      label: "Last Action Date",
      id: "Last Action Date",
    },
    {
      label: "PO Date",
      id: "PO Date",
    },
    {
      label: "PO Type",
      id: "PO Type",
    },
    {
      label: "Payment Terms (Vendor Master)",
      id: "Payment Terms (Vendor Master)",
    },
    {
      label: "Po Number",
      id: "Po Number",
    },
    {
      label: "Sent Amount",
      id: "Sent Amount",
    },
    {
      label: "Store Code",
      id: "Store Code",
    },
    {
      label: "Store GSTIN",
      id: "Store GSTIN",
    },
    {
      label: "TDS Amount",
      id: "TDS Amount",
    },
    {
      label: "UTR No",
      id: "UTR No",
    },
    ...approverHeaders,
  ];
  return headers;
};
export const grnFilters = [
  { label: "Site Code - Store GSTIN", value: "site" },
  { label: "Vendor Name - Vendor Code - Vendor GSTIN", value: "vendorCode" },
  { label: "Payment Terms(Vendor Master)", value: "paymentTerm" },
  { label: "PO Type", value: "poType" },
  { label: "PO Number", value: "poNumber" },
  { label: "Category", value: "category" },
  { label: "Sub Category", value: "subCategory" },
  { label: "Item Name", value: "itemId" },
  { label: "Department - HOD", value: "department" },
  { label: "Delivery location", value: "deliveryLocationId" },
  { label: "Billing location", value: "billingLocationId" },
  { label: "Company Name", value: "company" },
  { label: "Financial Year", value: "financialYear" },
  { label: "Creation Date", value: "creationDate" },
];
export const paymentFilters = [
  { label: "Site Code - Store GSTIN", value: "site" },
  { label: "Vendor Name - Vendor Code - Vendor GSTIN", value: "vendorCode" },
  { label: "Payment Terms(Vendor Master)", value: "paymentTerm" },
  { label: "PO Number", value: "poNumber" },
  { label: "Department - HOD", value: "department" },
  { label: "Creation Date", value: "creationDate" },
  { label: "Invoice Number", value: "invoiceNumber" },
  { label: "Financial Year", value: "financialYear" },
  { label: "Invoice Document Number", value: "invoiceDocumentNumber" },
];
