import React, { useEffect, useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const getFinancialYears = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;

  let currentFY, previousFY, nextFY;

  if (month >= 4) {
    // If April or later, FY is "currentYear-nextYear"
    currentFY = `${year}-${year + 1}`;
    previousFY = `${year - 1}-${year}`;
    nextFY = `${year + 1}-${year + 2}`;
  } else {
    // If Jan, Feb, or March, FY is "previousYear-currentYear"
    currentFY = `${year - 1}-${year}`;
    previousFY = `${year - 2}-${year - 1}`;
    nextFY = `${year}-${year + 1}`;
  }

  return {
    financialYears: [previousFY, currentFY, nextFY],
    defaultFY: currentFY,
  };
};

const FinancialYearDropdown = ({
  filters,
  setFilters,
  isFullWidth = false,
}) => {
  const { financialYears, defaultFY } = useMemo(() => getFinancialYears(), []);

  useEffect(() => {
    if (!filters.financialYear) {
      setFilters((prev) => ({ ...prev, financialYear: defaultFY }));
    }
  }, [filters, setFilters, defaultFY]);

  return (
    <FormControl
      size="small"
      sx={{ minWidth: "150px" }}
      fullWidth={isFullWidth ? true : false}
    >
      <InputLabel>Financial Year</InputLabel>
      <Select
        value={filters.financialYear || defaultFY}
        onChange={(event) => {
          setFilters((prev) => ({
            ...prev,
            financialYear: event.target.value,
          }));
        }}
        label="Financial Year"
      >
        {financialYears.map((fy) => (
          <MenuItem key={fy} value={fy}>
            {fy}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FinancialYearDropdown;
