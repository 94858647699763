import React, { useEffect, useState } from "react";
import {
  FINANCE_HOSTNAME,
  HOSTNAME
} from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  createPayloadMapper,
  getGrnLineAmount,
  getTotalGrnOrderAmount,
  getTotalGrnOrderTaxAmount
} from "./helper";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../../common-utilities/utils";
import { toast } from "react-toastify";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import { getPOAmount } from "../helper";
import CustomModal from "../../../../common-utilities/core/modal";
import POInfo from "./po-info";
import FileUpload from "../../../../common-utilities/core/file-uploader";

const CreateGRN = ({}) => {
  const navigate = useNavigate();
  const { poId } = useParams();
  const [receivedItems, setReceivedItems] = useState({});
  const [poDetails, setPODetails] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [createdPoDetails, setCreatedPoDetails] = useState(null);

  const { site = { name: "", code: "" }, items = [] } = poDetails;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}${poId}`,
      null
    )
      .then((response) => {
        if (response?.id) {
          setPODetails(response);
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    let payload = createPayloadMapper(formDetails, receivedItems, poId);
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_GRN}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed Create purchase order ");
        } else {
          setCreatedPoDetails(response);
        }
      })
      .catch((err) => {});
  };

  const inputChange = (event) => {
    setFormDetails({ ...formDetails, [event.target.name]: event.target.value });
  };

  const isDisabled = () => {
    return (
      !formDetails.inwardDate ||
      !formDetails.invoiceDate ||
      !formDetails.invoiceNumber ||
      Object.keys(receivedItems).length === 0 ||
      !formDetails.attachment
    );
  };


  return (
    <div className='create-po-container'>
      <div className='po-create-header'>
        <div className='left-container'>
          <div className='back-title'>
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=1");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className='title'>Create GRN</Typography>
          </div>
          <div className='info-section'>
            <div className='info-box'>
              <span>Site Name </span>
              <span>{site.name || ""}</span>
            </div>
            <div className='info-box'>
              <span>Site Code </span>
              <span>{site.code || ""}</span>
            </div>
            <div className='info-box'>
              <span>PO Number </span>
              <span>{poDetails?.poNumber}</span>
            </div>
            <div className='info-box'>
              <span>PO Amount </span>
              <span>{poDetails.totalAmount}</span>
            </div>
            <div className='info-box'>
              <span>PO Date </span>
              <span>{formatDate(new Date(), DATE_FORMATS["DD-MM-YYYY"])}</span>
            </div>
            <div className='info-box'>
              <span>PO Status </span>
              <span>{poDetails.status}</span>
            </div>
            <div className='info-box'>
              <span>PO Amount </span>
              <span>{getPOAmount(items)}</span>
            </div>
            <Stack spacing={2}>
              <span>Total GRN Tax Amount </span>
              <span>{getTotalGrnOrderTaxAmount(items, receivedItems)}</span>
            </Stack>
            <Stack spacing={2}>
              <span>Total GRN Amount </span>
              <span>{getTotalGrnOrderAmount(items, receivedItems)}</span>
            </Stack>
          </div>
        </div>

        <div className='right-container'>
          {/* <Button
            variant='outlined'
            disabled={isDisabled()}
            onClick={() => {
              onSave();
            }}
          >
            Save as draft
          </Button> */}
          <Button
            variant='outlined'
            disabled={isDisabled()}
            onClick={() => {
              onSave({ createApproval: true });
            }}
          >
            Create order
          </Button>
        </div>
      </div>
      <Card sx={{ padding: 2, marginTop: 2, paddingBottom: 0 }}>
        <Stack spacing={2} direction={"row"}>
          <TextField
            type='date'
            size='small'
            value={formDetails.inwardDate}
            InputLabelProps={{ shrink: true }}
            label='Inward Date'
            name='inwardDate'
            onChange={(e) => {
              inputChange(e);
            }}
          />
          <TextField
            type='date'
            size='small'
            value={formDetails.invoiceDate}
            InputLabelProps={{ shrink: true }}
            label='Invoice Date *'
            name='invoiceDate'
            onChange={(e) => {
              inputChange(e);
            }}
          />
          <TextField
            size='small'
            value={formDetails.invoiceNumber}
            label='Invoice Number *'
            name='invoiceNumber'
            onChange={(e) => {
              inputChange(e);
            }}
          />

          {formDetails.attachment ? (
            <Chip
              label={formDetails?.attachment?.url}
              onDelete={() =>
                setFormDetails({ ...formDetails, attachment: null })
              }
              onClick={() =>
                window.open(formDetails?.attachment?.url, "_blank")
              }
            />
          ) : (
            <FileUpload
              uploadText='Upload Documents'
              clsName='add-additional-doc'
              id='po-items-excel'
              url={`${HOSTNAME}${FINANCE_REST_URLS.APP_DOCUMENT}`}
              callBack={(response) => {
                const { data } = response;
                setFormDetails({ ...formDetails, attachment: data });
              }}
              formDataKey='upload'
            />
          )}
        </Stack>
      </Card>
      <Card sx={{ padding: 2, paddingTop: 0 }}>
        {items.map((lineItem) => {
          return (
            <Grid container xs={12} spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={1}>
                <TextField
                  value={lineItem.itemCode}
                  label='Item Code'
                  fullWidth
                  disabled
                  size='small'
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={lineItem.itemDescription}
                  label='Item Description'
                  disabled
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  value={lineItem.quantity}
                  label='PO Qty'
                  disabled
                  size='small'
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  value={lineItem.inwardedQuantity}
                  label='Inwarded Qty'
                  disabled
                  size='small'
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  value={lineItem.amount}
                  label='Amount'
                  disabled
                  size='small'
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  value={lineItem.taxAmount}
                  label='Tax'
                  disabled
                  size='small'
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  value={lineItem.totalAmount}
                  label='Total Amount'
                  disabled
                  size='small'
                />
              </Grid>
              <Grid item xs={1.2}>
                <TextField
                  value={receivedItems?.[lineItem.item] || ""}
                  label='Received Qty'
                  type='number'
                  size='small'
                  error={
                    receivedItems?.[lineItem.item] &&
                    (Number(receivedItems[lineItem.item]) >
                      lineItem.quantity - lineItem.inwardedQuantity ||
                      Number(receivedItems[lineItem.item]) < 0)
                  }
                  helperText={
                    receivedItems?.[lineItem.item] &&
                    (Number(receivedItems[lineItem.item]) >
                      lineItem.quantity - lineItem.inwardedQuantity ||
                      Number(receivedItems[lineItem.item]) < 0)
                      ? `Should be less than or equal to ${
                          lineItem.quantity - lineItem.inwardedQuantity
                        }`
                      : ""
                  }
                  onChange={(e) => {
                    setReceivedItems({
                      ...receivedItems,
                      [lineItem.item]: e.target.value
                    });
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  value={getGrnLineAmount(lineItem, receivedItems)}
                  label='GRN Amount'
                  disabled
                  size='small'
                />
              </Grid>
            </Grid>
          );
        })}
      </Card>
      {createdPoDetails && (
        <CustomModal
          title='GRN Successfully created'
          disabledOutsideClick={true}
        >
          <POInfo
            poDetails={createdPoDetails}
            siteCode={site.code}
            siteName={site.name}
            onOk={() => {
              navigate("/smallware-ordering?index=1&subIndex=0");
            }}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default CreateGRN;
