/** @format */
const commonPrefix = "/v1/finance-service/";
const intranetService = `/v1/central-service/`;
export const FINANCE_REST_URLS = {
  LOGIN: `${intranetService}auth/login`,
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  LOGOUT: `${intranetService}auth/logout`,
  GET_BUDGETS: `${commonPrefix}budget/query`,
  GET_APPROVALS: `${commonPrefix}approval/find`,
  APPROVE: `${commonPrefix}approval/approve`,
  UPLOAD_BUDGET: `${commonPrefix}budget/upload/bulk`,
  UPLOAD_BUDGET_BULK: `${commonPrefix}budget/bulk-upload`,
  UPLOAD_BUDGET_LINE_ITEMS: `${commonPrefix}budget/upload/line-items/bulk/`,
  GET_MY_APPROVALS: `${commonPrefix}approval/findByRoles`,
  MODIFY_LINE_ITEM: `${commonPrefix}line-item`,
  REJECT: `${commonPrefix}approval/reject`,
  RECONSIDER: `${commonPrefix}approval/reconsider`,
  CATEGORY: `${commonPrefix}category`,
  SUBCATEGORY: `${commonPrefix}subcategory`,
  GET_SITE_LIST: "/v1/cf-approval-service/form/all-approved-list",
  ITEMS: `${commonPrefix}item`,
  GET_ITEMS_BY_VENDOR: `${commonPrefix}item/find-by-vendor`,
  VENDOR_ITEM_MAPPING: `${commonPrefix}vendor-mapping`,
  COMPANY: `${commonPrefix}company`,
  PO: `${commonPrefix}purchase-order`,
  DEPARTMENTS: `/v1/central-service/department/all-department-list`,
  VENDOR_LIST: `/v1/fininv-service/vendor/all-vendor-list`,
  QUERY_VENDORS: `/v1/fininv-service/vendor/query-vendors-without-slug`,
  UPLOAD_DOC: `${commonPrefix}purchase-order/upload-po-documents`,
  GRN_LIST: `${commonPrefix}grn`,
  UPLOAD_INVOICE: `${commonPrefix}grn/upload-invoice`,
  LINE_ITEM_HISTORY: `${commonPrefix}line-item/price-history`,
  SHORT_CLOSE_ITEM: `${commonPrefix}purchase-order/short-close-po-item`,
  SHORT_CLOSE_PO: `${commonPrefix}purchase-order/short-close-po`,
  LOCATIONS: `${commonPrefix}location`,
  INITIATE_ADV_PAYMENT: `${commonPrefix}purchase-order/process-po-advance/`,
  UPLOAD_MASTER_DATA: `${commonPrefix}excel/`,
  GET_PO_PDF: `${commonPrefix}purchase-order/po-pdf/`,
  DOWNLOAD_UPLOAD_MASTER: `${commonPrefix}excel/download-upload-master/`,
  BUDGET_STATUS: `${commonPrefix}budget/update-status/`,
  UPLOAD_DOCUMENT: `${commonPrefix}file-uploader/upload`,
  SEND_FOR_APPROVAL: `${commonPrefix}purchase-order/send-po-for-approval/`,
  SEND_GRN_FOR_APPROVAL: `${commonPrefix}grn/send-invoice-approval/`,
  INVOICE_DATA: `${commonPrefix}invoice-data`,
  ENTER_PAYMENT_DETAILS_FOR_INVOICE_DATA: `${commonPrefix}invoice-data/enter-payment-details`,
  FOREIGN_CURRENCY: `${commonPrefix}foreign-currency`,
  GET_USERS_LIST: `${intranetService}users/user-list`,
  REPORTS: `${commonPrefix}reports/`,
  GET_CATEGORY_LIST: `${commonPrefix}/category/list`,
  GET_LOCATION_LIST: `${commonPrefix}/location/list`,
  GET_ALL_STORES: `${intranetService}stores`,
  APPROVE_PAYMENT: `${commonPrefix}invoice-data/mark-payment-complete`,
  RESEND_FOR_APPROVAL: `${commonPrefix}approval/resend-approval/`,
  HOLD_BUDGET_LINE_ITEM: `${commonPrefix}line-item/update-line-item-status/`,
  UPLOAD_PAYMENT_DETAILS: `${commonPrefix}excel/upload-payment-details`,
  STORE_DATA_LIST: `${commonPrefix}location/list`,
  GET_PO_NUMBER_LIST: `${commonPrefix}purchase-order/list`,
  GET_SUB_CATEGORY_LIST: `${commonPrefix}subcategory/list`,
  GET_ITEM_LIST: `${commonPrefix}item/list`,
  GET_HOD_DEPARTMENT_LIST: `${intranetService}department`,
  GET_COMPANY_LIST: `${commonPrefix}company/list`,
  GET_CURRENCY_LIST: `${commonPrefix}foreign-currency/list`,
  GET_CREATOR_LIST: `${commonPrefix}purchase-order/creator-list`,
  CANCEL_PO: `${commonPrefix}purchase-order/cancel-po/`,
  QUERY_APPROVAL_CONFIG: `${commonPrefix}approval-config`,
  APPROVAL_CONFIG_ROLE_LIST: `${commonPrefix}approval-config/config-role-list`,
  UPDATE_APPROVAL_CONFIG_BY_ID: `${commonPrefix}approval-config/`,
  DELETE_APPROVAL_CONFIG_BY_ID: `${commonPrefix}approval-config/`,
  CREATE_APPROVAL_CONFIG: `${commonPrefix}approval-config/create`,
  CREATE_FINANCE_ROLE: `${commonPrefix}users/user-role`,
  QUERY_CF_APPROVAL_MAPPINGS: `${commonPrefix}cf-approval-mapping/query-mappings`,
  UPDATE_CF_APPROVAL_MAPPING_BY_ID: `${commonPrefix}cf-approval-mapping/`,
  CREATE_CF_APPROVAL_MAPPING: `${commonPrefix}cf-approval-mapping`,
  DELETE_CF_APPROVAL_MAPPING_BY_ID: `${commonPrefix}cf-approval-mapping/`,
  BULK_APPROVE: `${commonPrefix}approval/bulk-approve`,
  BULK_RECONSIDER: `${commonPrefix}approval/bulk-reconsider`,
  BULK_REJECT: `${commonPrefix}approval/bulk-reject`,
  SMALLWARE_PO: `${commonPrefix}smallware-po/`,
  SMALLWARE_MATER_ITEM: `${commonPrefix}smallware-item/`,
  CANCEL_GRN: `${commonPrefix}grn/cancel-grn`,
  SMALLWARE_GRN: `${commonPrefix}smallware-grn/`,
  SMALLWARE_APPROVAL_CONFIG: `${commonPrefix}smallware-config/`,
  APP_DOCUMENT: `${intranetService}app-document/open-upload`,
  CHECK_MSME_VENDOR: "/v1/fininv-service/vendor/is-msme",
  UPLOAD_API: "/v1/central-service/app-document/open-upload",
  HOLD_BUDGETS: `${commonPrefix}budget/hold-all`,
};

export const redirectToRoute = "/subway-finance";
