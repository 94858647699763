import React from "react";
import { Typography } from "@mui/material";
import StageDetailsCard from "./stage-details-card";
import "./index.scss";

const ApprovalStageDetails = ({ approvalDetails }) => {
  const { stages = [], creatorName } = approvalDetails;

  const getCurrentStageStatus = (stage, index) => {
    const { status, StageNumber = 0 } = stage;
    if (status === "APPROVAL_IN_PROGRESS" && StageNumber === 0) {
      return {
        status: `Pending with  : ${stage.approverRole}`,
        stageStatusClass: "warning",
        dateLabel: "",
        creatorName: creatorName || ""
      };
    } else if (status === "APPROVAL_IN_PROGRESS") {
      return {
        status: `Pending With : ${
          approvalDetails?.pendingWith || stage.stageName
        }`,
        stageStatusClass: "warning",
        dateLabel: "",
        creatorName: creatorName || ""
      };
    } else if (status === "APPROVED") {
      return {
        status: `Approved By : ${stage.approvedByName}`,
        name: stage.approverName,
        stageStatusClass: "approved",
        dateLabel: "Approved On",
        creatorName: creatorName || "",
        comment: stage.approverComment || "",
        reconsiderComment:stage.reconsiderComment || ""
      };
    } else if (status === "REJECTED") {
      return {
        status: `Rejected By : ${stage?.rejectedByName || ""}`,
        name: stage.rejectedByName,
        stageStatusClass: "rejected",
        dateLabel: "Rejected On",
        comment: stage.rejectionComment || "",
        creatorName: creatorName || ""
      };
    } else if (status === "RECONSIDER") {
      return {
        status: `Reconsider By : ${stage.reconsideredByName}`,
        name: stage.reconsideredByName || "",
        stageStatusClass: "reconsider",
        dateLabel: "Reconsider On",
        creatorName: creatorName || "",
        reconsiderComment: stage?.reconsiderComment|| ""
      };
    }
  };

  const stageDetails = stages;
  return (
    <div className='approval-stage-details-container'>
      <div className='details-header'>
        <Typography className='title'>Approval details</Typography>
      </div>
      <div className='approval-card-list'>
        {stageDetails.map((stage, index) => {
          const updateStage = getCurrentStageStatus(stage, index);
          return (
            <StageDetailsCard
              stageDetails={{ ...stage, ...updateStage }}
              lastStage={stages.length === index + 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ApprovalStageDetails;
