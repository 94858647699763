export const createPayloadMapper = (formDetails, receivedItems, poId) => {
  let payload = {
    smPoId: poId,
    receivedItems: Object.keys(receivedItems || {}).map((key) => {
      return { smPoItemId: key, receivedQuantity: receivedItems[key] };
    }),
    inwardDate: formDetails.inwardDate,
    invoiceNumber: formDetails.invoiceNumber,
    invoiceDate: formDetails.invoiceDate
  };
  if (formDetails?.attachment?.url) {
    payload.attachment = formDetails.attachment.url;
  }

  return payload;
};

export const getGrnLineAmount = (item, receivedItems) => {
  const receivedQuantity = receivedItems[item.item] || 0;
  return item.rate * receivedQuantity;
};

export const getTotalGrnOrderAmount = (item, receivedItems) => {
  let sum = 0;
  item.forEach((element) => {
    const receivedQuantity = receivedItems[element.item] || 0;
    sum += element.rate * receivedQuantity * (1 + element.taxRate / 100);
  });

  return sum.toFixed(2);
};

export const getTotalGrnOrderTaxAmount = (item, receivedItems) => {
  let sum = 0;
  item.forEach((element) => {
    const receivedQuantity = receivedItems[element.item] || 0;
    sum += element.rate * receivedQuantity * (element.taxRate / 100);
  });

  return sum.toFixed(2);
};
