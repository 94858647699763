import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, Box, Button, Tab, Tabs, TextField } from "@mui/material";
import "./index.scss";
import CustomModal from "../../../common-utilities/core/modal";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { FINANCE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import { PO_TYPES } from "./helper";
import { isCreateAllowed, isGRNCreateAllowed } from "../../utils/common-utils";
import FileUpload from "../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../master-data/common-components/upload-template";
import { uploadTemplates } from "../master-data/constants";
import GRNList from "./grn-list";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const SmallwarePOGRN = ({ role }) => {
  const navigate = useNavigate();
  const [poModal, setPOModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("subIndex");
  const mainTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const [formDetails, setFormDetails] = useState({});
  const [poList, setPOList] = useState([]);

  const handleChange = (event, newValue) => {
    setSearchParams({
      index: mainTabIndex || 0,
      subIndex: newValue
    });
    setValue(newValue);
  };

  const onCreate = () => {
    navigate(`grn/create/${formDetails.id}`);
  };

  const loadPO = () => {
    let params = {
      limit: 100,
      page: 1,
      status: "APPROVED",
      sortBy: "-createdAt"
    };

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}`,
      null,
      params
    )
      .then((response) => {
        setPOList(response.results);
      })
      .catch((err) => {});
  };

  return (
    <div className=' rm-pt po-list'>
      <div className='tabs-section'>
        <Box>
          <Tabs variant='scrollable' value={value} onChange={handleChange}>
            <Tab label='Open GRN List' />
            <Tab label='Approved  GRN List' />
            <Tab label='Reconsider GRN List' />
            <Tab label='All' />
            <Tab label='Pending List' />
          </Tabs>
        </Box>
        <div className='left-container'>
          {isGRNCreateAllowed(role) && (
            <Button
              variant='outlined'
              onClick={() => {
                setPOModal(true);
                loadPO();
              }}
            >
              Create GRN
            </Button>
          )}
        </div>
      </div>
      <TabPanel value={value} index={0}>
        <GRNList status='APPROVAL_IN_PROGRESS' />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GRNList status='APPROVED' />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GRNList status='RECONSIDER' />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GRNList status='all' />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <GRNList status='pendingWithMe' />
      </TabPanel>

      {poModal && (
        <CustomModal
          title='Select PO form GRN'
          disabledOutsideClick={true}
          onClose={() => {
            setFormDetails({});
            setPOModal(false);
          }}
        >
          <div className='po-modal-content'>
            <Autocomplete
              onChange={(e, val) => {
                setFormDetails(val);
              }}
              value={formDetails}
              getOptionLabel={(op) => op.poNumber || ""}
              renderInput={(params) => (
                <TextField {...params} size='small' label='PO' />
              )}
              options={poList}
            />

            <Button
              variant='outlined'
              onClick={onCreate}
              disabled={!formDetails?.id}
            >
              Create Order
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default SmallwarePOGRN;
