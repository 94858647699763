import React, { useEffect, useState } from "react";
import {
  FINANCE_HOSTNAME,
  INVOICE_TRACKING_HOSTNAME
} from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig, ITEM_TYPE, UOM_LIST } from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import CustomModal from "../../../../common-utilities/core/modal";
import "./index.scss";
import { exportToExcel } from "../../../../common-utilities/utils";
import ConfirmModal from "../../../../common-utilities/core/confirm-modal";
import { toast } from "react-toastify";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../common-components/upload-template";
import { uploadTemplates } from "../constants";
import { clearProperty } from "../../../../common-utilities/utils/misc";
import { formatExcelHeaders } from "./utils";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { getOfflineData } from "../../../utils/offline-services";
import { isViewAllowed } from "../../../utils/common-utils";

const ItemList = ({}) => {
  const [budgetModal, setBudgetModal] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [budgetList, setBudgetList] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10
  });
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [inputUnitValue, setInputUnitValue] = useState("");
  const [inputTypeValue, setInputTypeValue] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendorInputValue, setVendorInputValue] = useState({});
  const userDetails = getOfflineData("user") || {};
  const role = userDetails?.role?.toUpperCase();

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (filters) => {
    let params = {
      ...filters
    };
    if (searchKey) {
      params.searchKey = searchKey;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_MATER_ITEM}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (params?.page > response?.totalPages) {
            setProperty("page", response?.totalPages, setFilters);
          }
          setBudgetList(response || []);
        }
      })
      .catch((err) => {});

    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`
    )
      .then((response) => {
        if (response.code) {
        } else {
          setVendorList(response || []);
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    const {
      uom = {},
      type = {},
      vendorEast = {},
      vendorCentral = {},
      vendorNorth = {},
      vendorSouth = {},
      vendorWest = {}
    } = formDetails;
    let payload = {
      itemCode: formDetails.itemCode,
      itemDescription: formDetails.itemDescription,
      caseSize: formDetails.caseSize,
      hsnCode: formDetails.hsnCode,
      gstRate: formDetails.gstRate,
      basePrice: formDetails.basePrice,
      fullPrice:
        formDetails.basePrice * (formDetails.gstRate || 0) * 0.01 +
        +formDetails.basePrice
    };

    if (uom?.value) {
      payload.uom = uom?.value;
    }

    if (type?.value) {
      payload.type = type?.value;
    }
    if (vendorEast?.id) {
      payload.vendorEast = vendorEast?.id;
    }
    if (vendorCentral?.id) {
      payload.vendorCentral = vendorCentral?.id;
    }
    if (vendorNorth?.id) {
      payload.vendorNorth = vendorNorth?.id;
    }
    if (vendorSouth?.id) {
      payload.vendorSouth = vendorSouth?.id;
    }

    if (vendorWest?.id) {
      payload.vendorWest = vendorWest?.id;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_MATER_ITEM}`,
      payload
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
        }
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    const {
      uom = {},
      type = {},
      vendorCentral,
      vendorEast,
      vendorNorth,
      vendorSouth,
      vendorWest
    } = formDetails;
    let payload = {
      itemCode: formDetails.itemCode,
      itemDescription: formDetails.itemDescription,
      caseSize: formDetails.caseSize,
      hsnCode: formDetails.hsnCode,
      gstRate: formDetails.gstRate,
      basePrice: formDetails.basePrice,
      fullPrice:
        formDetails.basePrice * (formDetails.gstRate || 0) * 0.01 +
        +formDetails.basePrice
    };
    if (uom?.value) {
      payload.uom = uom?.value;
    }

    if (type?.value) {
      payload.type = type?.value;
    }

    if (vendorEast?.id) {
      payload.vendorEast = vendorEast?.id;
    }
    if (vendorCentral?.id) {
      payload.vendorCentral = vendorCentral?.id;
    }
    if (vendorNorth?.id) {
      payload.vendorNorth = vendorNorth?.id;
    }
    if (vendorSouth?.id) {
      payload.vendorSouth = vendorSouth?.id;
    }

    if (vendorWest?.id) {
      payload.vendorWest = vendorWest?.id;
    }

    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_MATER_ITEM}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
          setVendorInputValue({});
        }
      })
      .catch((err) => {});
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value
    });
  };

  const editVendor = (data, type) => {
    const { vendorCentral, vendorEast, vendorNorth, vendorSouth, vendorWest } =
      data;

    const vendorCentralObj =
      vendorList.find((v) => v.id === vendorCentral) || {};
    const vendorEastObj = vendorList.find((v) => v.id === vendorEast) || {};
    const vendorNorthObj = vendorList.find((v) => v.id === vendorNorth) || {};
    const vendorSouthObj = vendorList.find((v) => v.id === vendorSouth) || {};
    const vendorWestObj = vendorList.find((v) => v.id === vendorWest) || {};
    setVendorInputValue({
      vendorCentral: vendorCentralObj.name,
      vendorEast: vendorEastObj.name,
      vendorNorth: vendorNorthObj.name,
      vendorSouth: vendorSouthObj.name,
      vendorWest: vendorWestObj.name
    });
    setBudgetModal(true);
    const uom = UOM_LIST.find((e) => e.value === data.uom) || null;
    const itemType = ITEM_TYPE.find((e) => e.value === data.type) || null;
    setInputUnitValue(uom?.name);
    setInputTypeValue(itemType?.name);
    setFormDetails(() => ({
      ...data,
      uom,
      itemType,
      vendorCentral: vendorCentralObj,
      vendorEast: vendorEastObj,
      vendorNorth: vendorNorthObj,
      vendorSouth: vendorSouthObj,
      vendorWest: vendorWestObj
    }));
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ITEMS}`,
      null,
      { page: 1, limit: budgetList.totalResults || 1000 }
    )
      .then((response) => {
        if (response.message) {
        } else {
          exportToExcel(
            formatExcelHeaders(response.results),
            "items-list",
            true
          );
        }
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_MATER_ITEM}/${deleteDetails.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        loadData(filters);
        setDeleteDetails(null);
      })
      .catch((err) => {});
  };

  return (
    <div className='item-list'>
      <div className='itl-header-section'>
        <div className='left-container'>
          <TextField
            placeholder='Search'
            value={searchKey}
            size='small'
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant='outlined'
            onClick={() =>
              loadData({
                page: 1,
                limit: 10
              })
            }
          >
            Search
          </Button>
        </div>
        {!isViewAllowed(role) && (
          <div className='left-container'>
            <FileUpload
              buttonBackground='#fff'
              buttonColor='#008938'
              mainButtonClass='subway-finance-bulk-upload'
              borderColor='rgba(0, 138, 82, 0.5)'
              uploadText='BULK UPLOAD'
              clsName='finance-master-data-uploader'
              url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_MATER_ITEM}upload`}
              callBack={() =>
                loadData({
                  page: filters.page,
                  limit: 10
                })
              }
              formDataKey='file'
            />
            <DownloadTemplateButton
              type={uploadTemplates.smallwareMaterItem}
              url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_MATER_ITEM}template`}
            />

            <Button variant='outlined' onClick={() => setBudgetModal(true)}>
              Create
            </Button>
          </div>
        )}
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editVendor, setDeleteDetails)}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page
          });
          loadData({
            ...filters,
            page
          });
        }}
      />

      {budgetModal && (
        <CustomModal
          title={formDetails.id ? "Update Item" : "Create Item"}
          disabledOutsideClick={true}
          contentClassName={{ customClass: "create-item-modal-content" }}
          onClose={() => {
            setBudgetModal(false);
            setFormDetails({});
          }}
        >
          <Stack spacing={2} direction='row' sx={{ marginY: 2 }}>
            <TextField
              value={formDetails.itemCode}
              onChange={onInputChange}
              size='small'
              name='itemCode'
              label='Item Code'
              fullWidth
            />
            <TextField
              value={formDetails.itemDescription}
              onChange={onInputChange}
              size='small'
              label='Item Description'
              name='itemDescription'
              fullWidth
            />
            <TextField
              value={formDetails.caseSize}
              onChange={onInputChange}
              size='small'
              label='Case Size'
              name='caseSize'
              fullWidth
            />
          </Stack>
          <Stack spacing={2} direction='row' sx={{ marginY: 2 }}>
            <Autocomplete
              options={vendorList}
              fullWidth
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  size='small'
                  label='Vendor North'
                />
              )}
              value={formDetails.vendorNorth || ""}
              getOptionLabel={(op) => op.name}
              inputValue={vendorInputValue.vendorNorth || ""}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setVendorInputValue({
                    ...vendorInputValue,
                    vendorNorth: newInputValue
                  });
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  vendorNorth: val
                });
              }}
            />
            <Autocomplete
              options={vendorList}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} size='small' label='Vendor South' />
              )}
              getOptionLabel={(op) => op.name}
              inputValue={vendorInputValue.vendorSouth || ""}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setVendorInputValue({
                    ...vendorInputValue,
                    vendorSouth: newInputValue
                  });
                }
              }}
              value={formDetails.vendorSouth || ""}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  vendorSouth: val
                });
              }}
            />
            <Autocomplete
              options={vendorList}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} size='small' label='Vendor East' />
              )}
              getOptionLabel={(op) => op.name}
              inputValue={vendorInputValue.vendorEast || ""}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setVendorInputValue({
                    ...vendorInputValue,
                    vendorEast: newInputValue
                  });
                }
              }}
              value={formDetails.vendorEast || ""}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  vendorEast: val
                });
              }}
            />
          </Stack>
          <Stack spacing={2} direction='row' sx={{ marginY: 2 }}>
            <Autocomplete
              options={vendorList}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} size='small' label='Vendor West' />
              )}
              getOptionLabel={(op) => op.name}
              inputValue={vendorInputValue.vendorWest || ""}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setVendorInputValue({
                    ...vendorInputValue,
                    vendorWest: newInputValue
                  });
                }
              }}
              value={formDetails.vendorWest || ""}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  vendorWest: val
                });
              }}
            />
            <Autocomplete
              options={vendorList}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} size='small' label='Vendor Center' />
              )}
              getOptionLabel={(op) => op.name}
              inputValue={vendorInputValue.vendorCentral || ""}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setVendorInputValue({
                    ...vendorInputValue,
                    vendorCentral: newInputValue
                  });
                }
              }}
              value={formDetails.vendorCentral || ""}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  vendorCentral: val
                });
              }}
            />
            <Autocomplete
              options={UOM_LIST}
              fullWidth
              value={formDetails.uom || ""}
              renderInput={(params) => (
                <TextField {...params} size='small' label='Unit' />
              )}
              inputValue={inputUnitValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setInputUnitValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  uom: val
                });
              }}
            />
          </Stack>
          <Stack spacing={2} direction='row' sx={{ marginY: 2 }}>
            <Autocomplete
              options={ITEM_TYPE}
              fullWidth
              value={formDetails.type || ""}
              renderInput={(params) => (
                <TextField {...params} size='small' label='Item Type' />
              )}
              inputValue={inputTypeValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setInputTypeValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  type: val
                });
              }}
            />
            <TextField
              value={formDetails.hsnCode}
              onChange={onInputChange}
              size='small'
              label='HSN Code'
              name='hsnCode'
              fullWidth
            />
            <TextField
              value={formDetails.gstRate}
              onChange={(e) => {
                if (!e.target.value) {
                  clearProperty("gstRate", setFormDetails);
                  return;
                }
                onInputChange(e);
              }}
              size='small'
              type='number'
              label='GST Rate(%)'
              name='gstRate'
              fullWidth
            />
          </Stack>
          <Stack spacing={2} direction='row' sx={{ marginY: 2 }}>
            <TextField
              value={formDetails.basePrice}
              onChange={(e) => {
                if (!e.target.value) {
                  clearProperty("basePrice", setFormDetails);
                  return;
                }
                onInputChange(e);
              }}
              inputProps={{}}
              size='small'
              type='number'
              label='Base Price'
              name='basePrice'
              fullWidth
            />
            <TextField
              value={
                formDetails.basePrice * (formDetails.gstRate || 0) * 0.01 +
                +formDetails.basePrice
              }
              disabled
              size='small'
              type='number'
              label='Full Price'
              name='fullPrice'
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Stack>
          <Stack>
            {formDetails.id ? (
              <Button fullWidth variant='contained' onClick={onUpdate}>
                Update
              </Button>
            ) : (
              <Button fullWidth variant='contained' onClick={onSave}>
                Create
              </Button>
            )}
          </Stack>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal onYes={onDelete} onNo={() => setDeleteDetails(null)} />
      )}
    </div>
  );
};

export default ItemList;
