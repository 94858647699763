import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, Box, Button, Tab, Tabs, TextField } from "@mui/material";
import POList from "./po-list";
import "./index.scss";
import CustomModal from "../../../common-utilities/core/modal";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { FINANCE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import { PO_TYPES } from "./helper";
import { isCreateAllowed } from "../../utils/common-utils";
import FileUpload from "../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../master-data/common-components/upload-template";
import { uploadTemplates } from "../master-data/constants";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const PurchaseOrder = ({ role }) => {
  const navigate = useNavigate();
  const [poModal, setPOModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("subIndex");
  const mainTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const [formDetails, setFormDetails] = useState({});
  const [budgetLineItems, setBudgetLineItems] = useState([]);
  const [sites, setSites] = useState([]);
  const { site = "", lineItems = [], poType = {} } = formDetails;
  const lineItemIds = lineItems.map((l) => l.id);

  const handleChange = (event, newValue) => {
    setSearchParams({
      index: mainTabIndex || 0,
      subIndex: newValue
    });
    setValue(newValue);
  };

  const onCreate = () => {
    let budgetId = "";
    if (lineItems.length > 0) {
      budgetId = lineItems[0].budgetId;
    } else {
      return;
    }

    navigate(
      `purchase-order/create/${budgetId}?poType=${
        poType.value
      }&lineItemIds=${lineItemIds.join(
        ","
      )}&index=${mainTabIndex}&subIndex=${value}`
    );
  };

  const loadBudgets = (site, budgetType) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_BUDGETS}`,
      null,
      {
        page: 1,
        status: "approved",
        site,
        limit: 1000,
        budgetType
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.totalResults > 0) {
          setBudgetLineItems(
            response.results[0].lineItems.filter((l) => l.status === "approved")
          );
        }
      })
      .catch((err) => {});
  };

  const isDisabled = () => {
    return !poType || !site || !lineItems || lineItems.length === 0;
  };

  return (
    <div className=' rm-pt po-list'>
      <div className='tabs-section'>
        <Box>
          <Tabs variant='scrollable' value={value} onChange={handleChange}>
            <Tab label='Open PO List' />
            <Tab label='Approved  PO List' />
            <Tab label='All PO List' />
          </Tabs>
        </Box>
        <div className='left-container'>
          {isCreateAllowed(role) && (
            <>
              {/* <Button
                variant='outlined'
                disabled
                onClick={() => {
                  setPOModal(true);
                }}
              >
                Create PO
              </Button> */}

              <FileUpload
                buttonBackground='#fff'
                buttonColor='#008938'
                mainButtonClass='subway-finance-bulk-upload'
                borderColor='rgba(0, 138, 82, 0.5)'
                uploadText='BULK UPLOAD'
                clsName='finance-master-data-uploader'
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}bulk-create`}
                formDataKey='file'
                slug='smallware-ordering'
                callBack={(event) => {
                  const { data = {} } = event;
                  if (event.status === 200) {
                    toast(data.message);
                  } else {
                    toast.error(data.message);
                  }
                }}
              />

              <DownloadTemplateButton
                type={uploadTemplates.smallwarePO}
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_PO}bulk/template`}
              />
            </>
          )}
        </div>
      </div>
      <TabPanel value={value} index={0}>
        <POList status='APPROVAL_IN_PROGRESS' />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <POList status='APPROVED' />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <POList status='all' />
      </TabPanel>

      {poModal && (
        <CustomModal
          title='Create Purchase Order'
          disabledOutsideClick={true}
          onClose={() => {
            setFormDetails({});
            setPOModal(false);
          }}
        >
          <div className='po-modal-content'>
            <Autocomplete
              value={formDetails.poType || ""}
              onChange={(e, val) => {
                if (val) {
                  setFormDetails({ poType: val });
                } else {
                  setFormDetails({});
                }
              }}
              renderInput={(params) => (
                <TextField {...params} size='small' label='PO Type *' />
              )}
              options={PO_TYPES}
            />
            <Autocomplete
              onChange={(e, val) => {
                if (val) {
                  setFormDetails({
                    ...formDetails,
                    site: val,
                    lineItems: []
                  });

                  loadBudgets(
                    val.id,
                    formDetails?.poType?.value === "opex_po"
                      ? "opex_budget"
                      : "capex_budget"
                  );
                } else {
                  setFormDetails({
                    ...formDetails,
                    lineItems: [],
                    site: null
                  });
                  setBudgetLineItems([]);
                }
              }}
              value={formDetails.site || {}}
              getOptionLabel={(op) =>
                `${op.code || ""} - ${op.name || ""}` || ""
              }
              renderInput={(params) => (
                <TextField {...params} size='small' label='Site Name *' />
              )}
              options={sites}
            />
            <Autocomplete
              onChange={(e, val) => {
                if (val) {
                  setFormDetails({
                    ...formDetails,
                    budgetId: val.budgetId,
                    lineItems: val
                  });
                } else {
                  setFormDetails({
                    ...formDetails,
                    lineItems: {}
                  });
                }
              }}
              multiple
              value={formDetails.lineItems || []}
              getOptionLabel={(op) => op.name || ""}
              renderInput={(params) => (
                <TextField {...params} size='small' label='Budget Category' />
              )}
              options={budgetLineItems}
            />

            <Button
              variant='outlined'
              onClick={onCreate}
              disabled={isDisabled()}
            >
              Create Order
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default PurchaseOrder;
