import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  PO_STATUS,
  budgetStatus,
  getPoCapexHeadersConfig,
  poFilters,
} from "./helper";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import {
  FINANCE_HOSTNAME,
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import { toast } from "react-toastify";
import MuiTable from "../../../common-utilities/core/mui-table";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import {
  AutoCompleteSelect,
  DateRange,
  TextFieldKeyVal,
  getLast15DaysDateRange,
} from "./utils/utils";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import ClearIcon from "@mui/icons-material/Clear";
import { exportToExcel } from "../../../common-utilities/utils";
import CustomModal from "../../../common-utilities/core/modal";
import { PO_TYPES } from "../purchase-order/helper";
import PaymentDetailsModal from "./modals/payment-details";
import { poActions } from "./utils/purchase-orders";
import { setProperty } from "../../../common-utilities/utils/misc";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../common-utilities/utils/time";
import { downloadFileFromUrl } from "../../../electricity-consumption/utils/common-utils";
import { getFinancialYears } from "../home/financial-year-dropdown";
const PoCapexReport = () => {
  const [poCapexList, setpoCapexList] = useState({});
  const [filterLabel, setFilterLabel] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [modalDetails, setModalDetails] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [creatorList, setCreatorList] = useState([]);
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
  });
  const [config, setConfig] = useState({
    showTable: true,
    approverHeaders: [],
  });

  const addParams = (params) => {
    switch (filterLabel) {
      case "creationDate":
      case "poDate":
        if (
          ![null, undefined, NaN, ""].includes(filterValue?.startDate) &&
          ![null, undefined, NaN, ""].includes(filterValue?.endDate)
        ) {
          params["startDate"] = getStartOfDay(filterValue?.startDate, true);
          params["endDate"] = getEndOfDay(filterValue?.endDate, true);
          params["dateKey"] = filterLabel;
        }

        break;

      default:
        if (filterLabel && ![null, undefined, NaN, ""].includes(filterValue)) {
          params["keyName"] = filterLabel;
          params["keyValue"] = filterValue;
        }

        break;
    }
  };
  const loadData = (filters, isDownload = false) => {
    const { startDate, endDate } = getLast15DaysDateRange();
    let params = {
      ...filters,
      // startDate,
      // endDate,
    };

    if (isDownload) {
      if (
        filters.dateKey !== "poDate" &&
        filters.dateKey !== "creationDate" &&
        !filters.keyName
      ) {
        params["dateKey"] = "creationDate";
        params["startDate"] = startDate;
        params["endDate"] = endDate;
      }

      if (filters.startDate || filters.endDate) {
        const differenceInMilliseconds =
          new Date(filters?.endDate) - new Date(filters?.startDate);
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);
        if (differenceInDays > 15) {
          toast.error("Max 15 days date range allowed", { autoClose: 2000 });
          return;
        }
      }
      params.download = true;
    }
    if (!isDownload) {
      setProperty("showTable", false, setConfig);
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REPORTS}purchase_order`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
          setProperty("showTable", true, setConfig);
          toast.error(response.message, { autoClose: 2000 });
        }
        if (isDownload) {
          // const results = response?.results || [];

          // exportToExcel(
          //   [
          //     {
          //       sheetName: "po-data",
          //       details: results?.poData || [],
          //     },
          //     {
          //       sheetName: "paymentDetails",
          //       details: results?.paymentDetails || [],
          //     },
          //   ],
          //   "PO Report",
          //   true
          // );
          downloadFileFromUrl(response.url);
        } else {
          // const approvalHeaders =
          //   response?.results?.stageLabelList?.map?.((item) => {
          //     return {
          //       label: item,
          //       id: item,
          //     };
          //   }) || [];
          const uniqueFieldNames = new Set();
          let newResult = [];
          if (response?.results?.poData) {
            response.results = [...response?.results?.poData];
            response?.results?.forEach((item) => {
              if (item?.stages) {
                Object.keys(item.stages).forEach((fieldName) => {
                  uniqueFieldNames.add(fieldName);
                });
              }
              const itemStage = item?.stages || {};
              delete item.stages;
              newResult.push({ ...item, ...itemStage });
            });
          }
          response.results = [...newResult];
          const formattedUniqueFieldNames = Array.from(uniqueFieldNames).map(
            (fieldName) => ({
              label: fieldName,
              id: fieldName,
            })
          );
          setProperty("approverHeaders", formattedUniqueFieldNames, setConfig);
          setpoCapexList(response);

          setTimeout(() => {
            setProperty("showTable", true, setConfig);
          }, 300);
        }
      })
      .catch((err) => {
        setProperty("showTable", true, setConfig);
        console.log(err);
      });
  };

  const closeModal = () => {
    setModalDetails(null);
  };

  const searchHandler = () => {
    let params = { ...filters, page: 1 };
    addParams(params);
    loadData(params);
  };
  const getCategoryList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_CATEGORY_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({ label: ele.name, value: ele.id }));
        setCategoryList(result);
      })
      .catch((err) => console.log(err));
  };
  const getVendorList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`,
      null,
      { additionalFields: "paymentTerms" }
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: `${ele?.code}- ${ele?.name}- ${ele?.gst}`,
          value: ele.code,
        }));
        setVendorList(result);
      })
      .catch((err) => console.log(err));
  };
  const getStoreList = (filter) => {
    let params = {};
    if (filter === "site") {
      params = {
        additionalFields: "gstNumber,storeId",
        poBudgetLocations: true,
        getStoreData: true,
      };
    } else {
      params = {
        additionalFields: "gstNumber,storeId",
      };
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.STORE_DATA_LIST}`,
      null,
      params
    )
      .then((res) => {
        if (filter === "site") {
          const result = res.map((ele) => ({
            label: `${ele?.code}- ${ele?.gstNumber}`,
            value: ele?._id,
          }));
          setStoreList(result);
        } else {
          const locationResult = res.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setLocationList(locationResult);
        }
      })
      .catch((err) => console.log(err));
  };
  const getSubCategoryList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_SUB_CATEGORY_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele.name,
          value: ele?._id,
        }));
        setSubCategoryList(result);
      })
      .catch((err) => console.log(err));
  };
  const getDepartmentList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${FINANCE_REST_URLS.GET_HOD_DEPARTMENT_LIST}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((res) => {
        const result = res.results.map((ele) => ({
          label: `${ele.name}- ${ele?.head?.name || ""}`,
          value: ele?.id,
        }));
        setDepartmentList(result);
      })
      .catch((err) => console.log(err));
  };

  const getItemList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_ITEM_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele.name,
          value: ele?._id,
        }));
        setItemList(result);
      })
      .catch((err) => console.log(err));
  };
  const getCurrencyList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_CURRENCY_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele?.name,
          value: ele?.id,
        }));
        setCurrencyList(result);
      })
      .catch((err) => console.log(err));
  };
  const getCreatorList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_CREATOR_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele?.name,
          value: ele?.id,
        }));
        setCreatorList(result);
      })
      .catch((err) => console.log(err));
  };
  const getFilterValue = (type) => {
    switch (type) {
      case "poNumber":
        return <TextFieldKeyVal setFilterValue={setFilterValue} />;

      case "site":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={storeList}
            filterLabel={filterLabel}
          />
        );
      case "vendorCode":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={vendorList}
            filterLabel={filterLabel}
          />
        );
      case "poType":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={PO_TYPES}
            filterLabel={filterLabel}
          />
        );
      case "status":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={PO_STATUS}
            filterLabel={filterLabel}
          />
        );
      case "poDate":
        return <DateRange setFilterValue={setFilterValue} />;
      case "itemId":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={itemList}
            filterLabel={filterLabel}
          />
        );

      case "category":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={categoryList}
            filterLabel={filterLabel}
          />
        );
      case "subCategory":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={subCategoryList}
            filterLabel={filterLabel}
          />
        );
      case "department":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={departmentList}
            filterLabel={filterLabel}
          />
        );
      case "deliveryLocationId":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={locationList}
            filterLabel={filterLabel}
          />
        );
      case "billingLocationId":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={locationList}
            filterLabel={filterLabel}
          />
        );
      case "currency":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={currencyList}
            filterLabel={filterLabel}
          />
        );
      case "createdBy":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={creatorList}
            filterLabel={filterLabel}
          />
        );
      case "financialYear":
        const { financialYears } = getFinancialYears();
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={financialYears}
          />
        );
      case "creationDate":
        return <DateRange setFilterValue={setFilterValue} />;

      default:
        break;
    }
  };
  const downloadReport = () => {
    const params = { ...filters, download: true };
    params.page = 1;
    params.limit = poCapexList.totalResults;
    addParams(params);
    loadData(params, true);
  };
  useEffect(() => {
    loadData(filters);
  }, []);
  useEffect(() => {
    if (filterLabel === "category") {
      getCategoryList();
    }
    if (
      filterLabel === "site" ||
      filterLabel == "deliveryLocationId" ||
      filterLabel === "billingLocationId"
    ) {
      getStoreList(filterLabel);
    }
    if (filterLabel === "vendorCode") {
      getVendorList();
    }
    if (filterLabel === "subCategory") {
      getSubCategoryList();
    }
    if (filterLabel === "itemId") {
      getItemList();
    }
    if (filterLabel === "department") {
      getDepartmentList();
    }
    if (filterLabel === "currency") {
      getCurrencyList();
    }
    if (filterLabel === "createdBy") {
      getCreatorList();
    }
  }, [filterLabel]);
  return (
    <div>
      <div className="reports-filter-con">
        <Grid
          container
          md={12}
          sx={{ mt: 2, display: "flex", alignItems: "center" }}
        >
          <Grid item md={2} sx={{ mr: 2 }}>
            <Autocomplete
              options={poFilters}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Select Filter" />
              )}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setFilterLabel("");
                    setFilterValue("");
                    loadData({ ...filters, page: 1 });
                  }}
                />
              }
              onChange={(e, val) => {
                if (val) {
                  setFilterValue("");
                  setFilterLabel(val.value);
                }
              }}
            />
          </Grid>

          {filterLabel && getFilterValue(filterLabel)}

          <Grid md={2} sx={{ ml: 2 }}>
            {filterLabel && (
              <Button
                variant="outlined"
                onClick={searchHandler}
                disabled={
                  !filterValue ||
                  (typeof filterValue === "object" &&
                    Object.keys(filterValue).length === 0)
                }
              >
                Search
              </Button>
            )}
          </Grid>
        </Grid>
        <DownloadBtn
          onClick={() => {
            downloadReport();
          }}
        />
      </div>

      {config.showTable && (
        <MuiTable
          columnsList={getPoCapexHeadersConfig(config.approverHeaders)}
          dataList={poCapexList.results || []}
          filters={filters}
          pageCount={poCapexList.totalPages}
          onClick={(data, type) => {
            poActions(data, type, setModalDetails);
          }}
          showAllFunc={setShowLargeText}
          maxWords={3}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            const params = {
              ...filters,
              page,
            };
            addParams(params);
            loadData(params);
          }}
        />
      )}

      {
        <PaymentDetailsModal
          showModal={modalDetails?.type === "viewPaymentDetails"}
          closeModal={closeModal}
          title={modalDetails?.title}
          data={modalDetails?.data}
        />
      }
      {showLargeText.isOpen && (
        <CustomModal
          onClose={() =>
            setShowLargeText({
              isOpen: false,
              text: "",
            })
          }
        >
          {showLargeText.text}
        </CustomModal>
      )}
    </div>
  );
};

export default PoCapexReport;
