import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  getHeaderConfig,
  getGRNOrderAmount,
  getGRNOrderTaxAmount,
  getPaymentDetails,
} from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from "../../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { getFileName } from "../../../utils/common-utils";
import { checkIsApprover, checkUserRight } from "../../../utils/utils";
import InvoiceDataPaymentDetails from "./payment-details";
import {
  getPurchaseOrderAmount,
  getPurchaseOrderTaxAmount,
  getPurchaseOrderWithoutTaxAmount,
} from "../../purchase-order/helper";
import ApprovalStageDetails from "../../approval-stage-details";
import PaymentDetailsDownload from "./payment-details-download";
import ResendAdvPaymentDetails from "./modals/resend-po-adv-payment-details";
import { getOfflineData } from "../../../utils/offline-services";

const InvoiceDataDetails = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const approvalId = searchParams.get("approvalId");
  const user = getOfflineData("user");

  const fromScreen = searchParams.get("from");
  const subIndex = searchParams.get("subIndex");
  const [approveRejectDetails, setApproveRejectionDetails] = useState(null);
  const [reconsiderDetails, setReconsiderDetails] = useState(null);
  const [invoiceDataDetails, setInvoiceDataDetails] = useState({});
  const [invoiceDataPaymentDetailsFlag, setInvoiceDataPaymentDetailsFlag] =
    useState(false);
  const [confirmHodGrn, setConfirmHodGrn] = useState(false);
  const [approvalDetails, setApprovalDetails] = useState({});
  const [filters, setFilters] = useState({
    approved: false,
    rejected: false,
  });

  const {
    invoiceUrls = [],
    additionalDocUrls = [],
    paymentDetails = [],
    grnId = {},
    invoiceType = "",
    paymentStatus = "",
  } = invoiceDataDetails;
  const purchaseOrderId = invoiceDataDetails.poId || {};
  const { budget, items = [] } = purchaseOrderId || {};
  const {
    receivedItems = [],
    inwardDate = "",
    financialyear = "",
    grnNo = "",
    msmeDeclaration = "",
  } = grnId || {};

  const { siteCode = "", siteName = "" } = budget || {};

  const getAttachmentDetails = () => {
    const data = {
      invoiceDocumentNumber: invoiceDataDetails?.invoiceDocumentNumber,
      poNumber: invoiceDataDetails?.poNumber,
      id: invoiceDataDetails.id,
      invoiceType: invoiceDataDetails.invoiceType,
      attachmentType: "Advance Payment Attachment",
    };
    if (invoiceDataDetails.invoiceType === "grn_invoice") {
      data.attachmentType = "GRN Payment Attachment";
    }
    if (invoiceDataDetails?.grnNo) {
      data.grnNo = invoiceDataDetails?.grnNo;
    }

    return data;
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    approvalId && loadApprovalDetails();
  }, [approvalId]);

  useEffect(() => {
    if (!invoiceDataDetails?.approvalId?.id || !invoiceDataDetails?.type) {
      return;
    }

    const invoiceDetailsApprovalId = parseInt(
      invoiceDataDetails?.approvalId?.id
    );
    let mainFromScreen = "ADVANCE_INVOICE";
    if (invoiceDataDetails?.type === "grn_invoice") {
      mainFromScreen = "GRN";
    }

    if (
      fromScreen === mainFromScreen &&
      invoiceDetailsApprovalId === parseInt(approvalId)
    ) {
      return;
    }

    const params = {
      fromScreen: mainFromScreen,
      approvalId: invoiceDetailsApprovalId,
    };

    if (![null, undefined].includes(subIndex)) {
      params.subIndex = subIndex;
    }

    setSearchParams(params);
  }, [approvalId, invoiceDataDetails?.approvalId?.id, fromScreen, subIndex]);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.INVOICE_DATA}`,
      null,
      {
        page: 1,
        // limit: 1,
        invoicedataId: id,
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response.results.length > 0) {
          setInvoiceDataDetails(response.results[0] || {});
        }
      })
      .catch((err) => {});
  };

  const loadApprovalDetails = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_APPROVALS}`,
      null,
      {
        _id: approvalId,
        archive: false,
      }
    )
      .then((response) => {
        if (response.length > 0) {
          setApprovalDetails(response[0]);
        }
      })
      .catch((err) => {});
  };

  const onApprove = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.APPROVE}/${approvalDetails.id}`,
      null,
      { comment: approveRejectDetails.comment || "" }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to approve");
        } else {
          loadData();
          loadApprovalDetails();
          setApproveRejectionDetails(null);
        }
      })
      .catch((err) => {});
  };

  const onReject = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REJECT}`,
      null,
      { comment: approveRejectDetails.comment, id: approvalDetails.id }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reject");
        } else {
          setApproveRejectionDetails(null);
          loadData();
          loadApprovalDetails();
        }
      })
      .catch((err) => {});
  };

  const onReconsider = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RECONSIDER}`,
      null,
      { comment: reconsiderDetails.comment, id: approvalDetails.id }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to reject");
        } else {
          setReconsiderDetails(null);
          loadData();
          loadApprovalDetails();
        }
      })
      .catch((err) => {});
  };

  const updatePaymentDetails = (payload) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ENTER_PAYMENT_DETAILS_FOR_INVOICE_DATA}/${id}`,
      { paymentDetails: [payload] }
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message || "Failed to update payment details");
        } else {
          setInvoiceDataPaymentDetailsFlag(false);
          loadData();
          loadApprovalDetails();
        }
      })
      .catch((err) => {});
  };

  const editRecord = () => {};

  const approvePayment = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.APPROVE_PAYMENT}/${id}`
    )
      .then((response) => {
        if (response.code) {
          toast.error("Failed to approve payment");
        } else {
          loadData();
          loadApprovalDetails();
        }
      })
      .catch((err) => {});
  };
  const cancelGrn = () => {
    const grnId = invoiceDataDetails?.grnId?.id;
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CANCEL_GRN}/${grnId}`
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message);
        } else {
          loadData();
          loadApprovalDetails();
        }
      })
      .catch((err) => {});
  };
  const goBack = () => {
    if (fromScreen === "GRN") {
      navigate(-1);
      // navigate(
      //   `/subway-finance?index=2&subIndex=${
      //     subIndex || checkUserRight("grn.approveReject") ? 5 : 4
      //   }`
      // );
    } else {
      navigate(-1);
      // navigate("/subway-finance?index=4");
    }
  };

  const resendForApproval = () => {
    const { approvalId = {}, grnId = {} } = invoiceDataDetails;
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.RESEND_FOR_APPROVAL}${approvalId.id}`
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to resend for approval");
        } else {
          if (invoiceDataDetails?.invoiceType === "advance_invoice") {
            navigate(`/subway-finance?index=4`);
            return;
          }
          navigate(`/subway-finance/grn-order/${grnId.id}`);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="finance-invoice-details-container">
      <div className="finance-invoice-details-header">
        <div className="finance-left-section">
          <div className="back-title">
            <IconButton onClick={goBack}>
              <ArrowBack />
            </IconButton>
            <Typography className="title">
              {convertToCamelCaseFromUnderScore(invoiceType)} Details
            </Typography>
          </div>
          <div className="header-form-container">
            <div className="info-section">
              {fromScreen === "GRN" && (
                <>
                  <div className="info-box">
                    <span>GRN Number </span>
                    <span>{grnNo}</span>
                  </div>
                  <div className="info-box">
                    <span>GRN Status </span>
                    <span>
                      {convertToCamelCaseFromUnderScore(
                        invoiceDataDetails.status
                      )}
                    </span>
                  </div>
                </>
              )}
              <div className="info-box">
                <span>PO ID </span>
                <span>{purchaseOrderId.poNumber}</span>
              </div>
              <div className="info-box">
                <span>PO Type </span>
                <span>
                  {convertToCamelCaseFromUnderScore(purchaseOrderId.poType)}
                </span>
              </div>
              <div className="info-box">
                <span> PO Date </span>
                <span>
                  {formatDate(
                    purchaseOrderId.poDate,
                    DATE_FORMATS["DD-MM-YYYY"]
                  )}
                </span>
              </div>

              <div className="info-box">
                <span>Payment status </span>
                <span>
                  {convertToCamelCaseFromUnderScore(paymentStatus) || "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="right-section">
          {invoiceDataDetails?.status === "approved" &&
            invoiceDataDetails.paymentStatus !== "payment_completed" &&
            checkUserRight("paymentDetails.upload") && (
              <div className="right-section">
                {paymentDetails.length > 0 && (
                  <Button variant="outlined" onClick={approvePayment}>
                    Complete Payment
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={() => setInvoiceDataPaymentDetailsFlag(true)}
                >
                  Enter Payment Details
                </Button>
              </div>
            )}
          {fromScreen === "GRN" &&
            ["reconsider"].includes(invoiceDataDetails?.grnId?.status) &&
            parseInt(invoiceDataDetails?.createdBy) ===
              parseInt(user?.id || user?._id) && (
              <Button variant="outlined" color="error" onClick={cancelGrn}>
                Cancel GRN
              </Button>
            )}
          {["reconsider"].includes(invoiceDataDetails.status) &&
            parseInt(invoiceDataDetails?.approvalId?.createdBy) ===
              parseInt(user?.id || user?._id) && (
              <>
                <ResendAdvPaymentDetails
                  invoiceDataDetails={invoiceDataDetails}
                  setInvoiceDataDetails={setInvoiceDataDetails}
                />
                <Button variant="outlined" onClick={() => resendForApproval()}>
                  Resend For approval
                </Button>
              </>
            )}
          {approvalId &&
            approvalDetails.id &&
            ["approval_in_progress"].includes(invoiceDataDetails.status) && (
              <>
                {/* onApprove */}
                <Button
                  variant="outlined"
                  disabled={!checkIsApprover(approvalDetails)}
                  onClick={() =>
                    setApproveRejectionDetails({
                      ...approvalDetails,
                      toApprove: true,
                    })
                  }
                >
                  Approve
                </Button>
                <Button
                  variant="outlined"
                  color="warning"
                  disabled={!checkIsApprover(approvalDetails)}
                  onClick={() => setReconsiderDetails(approvalDetails)}
                >
                  Reconsider
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={!checkIsApprover(approvalDetails)}
                  onClick={() => setApproveRejectionDetails(approvalDetails)}
                >
                  Reject
                </Button>
              </>
            )}
        </div>
      </div>
      <div
        className={
          approvalDetails.id
            ? "invoice-detail-approval-stage "
            : "invoice-detail-approval-stage no-approval"
        }
      >
        <div className="invoice-details">
          <div className="form-container">
            <div className="info-section">
              <div className="info-box">
                <span>PO Base Amt </span>
                <span>{getPurchaseOrderWithoutTaxAmount(items) || "0"}</span>
              </div>
              <div className="info-box">
                <span>PO GST Amt </span>
                <span>{getPurchaseOrderTaxAmount(items) || "0"}</span>
              </div>
              <div className="info-box">
                <span>PO Total Amt </span>
                <span>{getPurchaseOrderAmount(items) || "0"}</span>
              </div>

              <div className="info-box">
                <span> GRN Amount </span>
                <span>{getGRNOrderAmount(items, receivedItems)}</span>
              </div>
              <div className="info-box">
                <span> GRN Tax Amount </span>
                <span>{getGRNOrderTaxAmount(items, receivedItems)}</span>
              </div>
              <div className="info-box">
                <span>Site Code</span>
                <span>{siteCode || ""}</span>
              </div>
              <div className="info-box">
                <span>Site Code</span>
                <span>{siteName || ""}</span>
              </div>
              <div className="info-box">
                <span> Vendor Code </span>
                <span>{invoiceDataDetails.vendorCode}</span>
              </div>

              <div className="info-box">
                <span> Vendor Name </span>
                <span>
                  {(invoiceDataDetails.poId &&
                    invoiceDataDetails.poId.vendorName) ||
                    "-"}
                </span>
              </div>

              <div className="info-box">
                <span> Vendor GST </span>
                <span>{invoiceDataDetails.vendorGST}</span>
              </div>
              <div className="info-box">
                <span> Vendor PAN Number </span>
                <span>{invoiceDataDetails.vendorPAN}</span>
              </div>
              <div className="info-box">
                <span>
                  {invoiceType === "advance_invoice"
                    ? "Proforma invoice no"
                    : "Invoice Number"}
                </span>
                <span>
                  {(invoiceDataDetails && invoiceDataDetails.invoiceNumber) ||
                    ""}
                </span>
              </div>
              <div className="info-box">
                <span>Invoice Amount </span>
                <span>
                  {(invoiceDataDetails && invoiceDataDetails.invoiceAmount) ||
                    ""}
                </span>
              </div>
              <div className="info-box">
                <span>Invoice Date </span>
                <span>
                  {(invoiceDataDetails &&
                    formatDate(
                      invoiceDataDetails.invoiceDate,
                      DATE_FORMATS["DD-MM-YYYY"]
                    )) ||
                    ""}
                </span>
              </div>
              <div className="info-box">
                <span>Material/Service Received date </span>
                <span>
                  {(invoiceDataDetails &&
                    formatDate(inwardDate, DATE_FORMATS["DD-MM-YYYY"])) ||
                    "-"}
                </span>
              </div>
              <div className="info-box">
                <span>Financial Year </span>
                <span>
                  {invoiceDataDetails?.financialYear || financialyear || "-"}
                </span>
              </div>
              <div className="info-box">
                <span>Created By </span>
                <span>{invoiceDataDetails.creatorName || "-"}</span>
              </div>
              {msmeDeclaration && (
                <div className="info-box">
                  <span>MSME GRN Declaration</span>
                  <Chip
                    label={getFileName(msmeDeclaration)}
                    onClick={() => window.open(msmeDeclaration, "_blank")}
                    sx={{ maxWidth: "200px" }}
                  />
                </div>
              )}
              <div className="info-box">
                {invoiceUrls.length ? (
                  <>
                    <span>Invoice Amount </span>
                    <div>
                      {invoiceUrls.map((attachment) => {
                        return (
                          <Chip
                            label={getFileName(attachment)}
                            onClick={() => window.open(attachment, "_blank")}
                          />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="form-container">
            <div>
              {additionalDocUrls.map((attachment) => {
                return (
                  <Chip
                    label={getFileName(attachment)}
                    onClick={() => window.open(attachment, "_blank")}
                  />
                );
              })}
            </div>
          </div>
          <div className="form-container">
            <MuiTable
              columnsList={getHeaderConfig(receivedItems, editRecord)}
              dataList={
                items?.slice?.(
                  ((filters?.page || 1) - 1) * 10,
                  ((filters?.page || 1) - 1) * 10 + 10
                ) || []
              }
              pageCount={Math.ceil((items.length || 1) / 10)}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page,
                });
              }}
            />
          </div>
          {paymentDetails.length > 0 && (
            <>
              <div className="form-container">
                <PaymentDetailsDownload paymentDetails={paymentDetails} />
                <MuiTable
                  columnsList={getPaymentDetails()}
                  dataList={
                    paymentDetails?.slice?.(
                      ((filters?.paymentDetailsPage || 1) - 1) * 10,
                      ((filters?.paymentDetailsPage || 1) - 1) * 10 + 10
                    ) || []
                  }
                  pageCount={Math.ceil(paymentDetails.length / 10)}
                  onChange={(page) => {
                    setFilters({
                      ...filters,
                      paymentDetailsPage: page,
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
        {approvalId && (
          <div className="approval-details">
            <ApprovalStageDetails
              approvalDetails={approvalDetails}
              createdBy={invoiceDataDetails.creatorName}
            />
          </div>
        )}
      </div>
      {approveRejectDetails && (
        <CustomModal
          title={`${
            approveRejectDetails.toApprove
              ? "Approve Invoice"
              : "Reject Invoice"
          }`}
          disabledOutsideClick={true}
          onClose={() => {
            setApproveRejectionDetails(null);
          }}
        >
          <div className="b-modal-form-container">
            <TextField
              value={approveRejectDetails.comment}
              onChange={(e) =>
                setApproveRejectionDetails({
                  ...approveRejectDetails,
                  comment: e.target.value,
                })
              }
              size="small"
              name="comment"
              label="Comment"
              fullWidth
            />

            {invoiceType === "grn_invoice" && user?.role.includes("HOD") && (
              <FormGroup sx={{ width: 500 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={confirmHodGrn}
                      onChange={(event) =>
                        setConfirmHodGrn(event.target.checked)
                      }
                    />
                  }
                  label="I/We hereby confirm that we have received Material or Services as mentioned in the invoice for which GRN is submitted"
                />
              </FormGroup>
            )}
            <Button
              color={`${approveRejectDetails.toApprove ? "primary" : "error"}`}
              variant="outlined"
              onClick={() => {
                if (approveRejectDetails.toApprove) {
                  onApprove();
                  return;
                }
                onReject();
              }}
              fullWidth
              disabled={
                approveRejectDetails.toApprove && invoiceType !== "grn_invoice"
                  ? false
                  : user?.role.includes("HOD") &&
                    invoiceType === "grn_invoice" &&
                    !confirmHodGrn
                  ? true
                  : !approveRejectDetails.toApprove &&
                    !approveRejectDetails.comment
                  ? true
                  : false
              }
            >
              {approveRejectDetails.toApprove ? "Approve" : "Reject"}
            </Button>
          </div>
        </CustomModal>
      )}

      {invoiceDataPaymentDetailsFlag && (
        <CustomModal
          title="Payment Details"
          disabledOutsideClick={true}
          onClose={() => {
            setInvoiceDataPaymentDetailsFlag(false);
          }}
        >
          <InvoiceDataPaymentDetails
            attachmentData={getAttachmentDetails()}
            onSave={updatePaymentDetails}
            invoiceAmount={
              (invoiceDataDetails && invoiceDataDetails.invoiceAmount) || 0
            }
          />
        </CustomModal>
      )}

      {reconsiderDetails && (
        <CustomModal
          title="Reconsider"
          disabledOutsideClick={true}
          onClose={() => {
            setReconsiderDetails(null);
          }}
        >
          <div className="b-modal-form-container">
            <TextField
              value={reconsiderDetails.comment}
              onChange={(e) =>
                setReconsiderDetails({
                  ...reconsiderDetails,
                  comment: e.target.value,
                })
              }
              size="small"
              name="comment"
              label="comment"
              fullWidth
            />
            <Button
              color="error"
              variant="outlined"
              onClick={onReconsider}
              fullWidth
              disabled={!reconsiderDetails.comment}
            >
              Reconsider
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default InvoiceDataDetails;
