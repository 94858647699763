import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { APPROVAL_ROLES_GRN, APPROVAL_ROLES_PO } from "./helper";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import "./index.scss";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { isViewAllowed } from "../../../utils/common-utils";
import { getOfflineData } from "../../../utils/offline-services";

const ApprovalConfig = ({ docType }) => {
  const [approvalDetails, setApprovalDetails] = useState({});
  const [deleteStage, setDeleteStage] = useState(null);
  const { stages = [], id } = approvalDetails;
  const userDetails = getOfflineData("user") || {};
  const role = userDetails?.role?.toUpperCase();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_APPROVAL_CONFIG}`,
      null,
      {
        page: 1,
        limit: 20,
        docType: docType
      }
    )
      .then((response) => {
        if (response.message) {
        } else if (response?.results?.length > 0) {
          setApprovalDetails(response.results[0] || {});
        }
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_APPROVAL_CONFIG}/${id}`,
      {
        stages: approvalDetails.stages.map((s) => ({
          stageName: s.stageName,
          approverRole: s.approverRole
        }))
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          toast.success("Approval Config Updated Successfully");
          loadData();
        }
      })
      .catch((err) => {});
  };

  const onDelete = (stageNumber) => {
    let payload = {
      stages: stages
        .filter((s) => s.stageNumber !== stageNumber)
        .map((ss) => {
          return { stageName: ss.stageName, approverRole: ss.approverRole };
        })
    };

    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_APPROVAL_CONFIG}/${id}`,
      payload
    )
      .then((response) => {
        if (response.message) {
        } else {
          toast.success("Approval Config Updated Successfully");
          loadData();
        }
      })
      .catch((err) => {});
  };

  const getValue = (stage) => {
    if (docType === "SMALLWARE_PO") {
      return (
        APPROVAL_ROLES_PO.find((a) => a.value === stage.approverRole) || {}
      );
    } else {
      return (
        APPROVAL_ROLES_GRN.find((a) => a.value === stage.approverRole) || {}
      );
    }
  };

  return (
    <Box>
      <Stack direction='row' spacing={2} justifyContent='flex-end'>
        <Button
          variant='contained'
          disabled={isViewAllowed(role)}
          onClick={() => {
            setApprovalDetails((prev) => {
              prev.stages.push({
                stageNumber: prev.stages.length + 1,
                stageName: "",
                approverRole: ""
              });
              return { ...prev };
            });
          }}
        >
          Add New Stage
        </Button>
      </Stack>
      <Box sx={{ mt: 2 }}>
        {approvalDetails?.stages?.map((stage, index) => {
          return (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={1}>
                <TextField
                  fullWidth
                  size='small'
                  label='Stage Number'
                  value={stage.stageNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={
                    docType === "SMALLWARE_PO"
                      ? APPROVAL_ROLES_PO
                      : APPROVAL_ROLES_GRN
                  }
                  size='small'
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={getValue(stage)}
                  onChange={(e, val) => {
                    setApprovalDetails((prev) => {
                      prev.stages[index].approverRole = val.value;
                      return { ...prev };
                    });
                  }}
                  renderInput={(params) => (
                    <TextField size='small' {...params} label='Approver Role' />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label='Stage Name'
                  size='small'
                  value={stage.stageName}
                  onChange={(e) => {
                    setApprovalDetails((prev) => {
                      prev.stages[index].stageName = e.target.value;
                      return { ...prev };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant='outlined'
                  disabled={isViewAllowed(role)}
                  onClick={() => onDelete(stage.stageNumber)}
                  sx={{ mr: 2 }}
                >
                  Delete
                </Button>
                <Button
                  variant='outlined'
                  disabled={isViewAllowed(role)}
                  onClick={onUpdate}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Box>

      {deleteStage && (
        <CustomModal
          title='Delete Stage'
          disabledOutsideClick={true}
          onClose={() => {
            setDeleteStage(null);
          }}
        >
          <div className='b-modal-form-container'>
            <Typography variant='h5'>Are you sure ? </Typography>
            <Button variant='outlined' onClick={onDelete} fullWidth>
              Delete
            </Button>
          </div>
        </CustomModal>
      )}
    </Box>
  );
};

export default ApprovalConfig;
