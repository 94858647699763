import React, { useEffect, useState } from "react";
import {
  FINANCE_HOSTNAME,
  HOSTNAME
} from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import {
  getHeaderConfig,
  getGRNOrderAmount,
  getGRNOrderTaxAmount,
  getGRNOrderAmountWithTax,
  createUpdatePayloadMapper
} from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import {
  Button,
  Chip,
  IconButton,
  TextareaAutosize,
  TextField,
  Typography
} from "@mui/material";
import "./index.scss";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  formatDate
} from "../../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../../common-utilities/utils/constants";
import { toast } from "react-toastify";
import CustomModal from "../../../../common-utilities/core/modal";
import { hasApproveAccessNow } from "../../../utils/utils";
import ApprovalStageDetails from "../../approval-stage-details";
import FileUpload from "../../../../common-utilities/core/file-uploader";

const EditGRNDetails = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rejectionDetails, setRejectionDetails] = useState(null);
  const [reconsiderDetails, setReconsiderDetails] = useState(null);
  const [grnDetails, setGRNDetails] = useState({});
  const [poDetails, setPoDetails] = useState({});
  const [filters, setFilters] = useState({
    approved: false,
    rejected: false
  });

  const { stages = [], receivedItems = [] } = grnDetails;
  const {
    site,
    items = [],
    vendorName = "",
    vendorCode = "",
    vendorGst = "",
    vendorPancard = ""
  } = poDetails;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_GRN}${id}`,
      null
    )
      .then((response) => {
        if (response.message) {
        } else if (response.id) {
          response.inwardDate = formatDate(response.inwardDate, "YYYY-MM-DD");
          response.invoiceDate = formatDate(
            response.invoiceDate || new Date(),
            "YYYY-MM-DD"
          );

          setGRNDetails(response || {});
          setPoDetails(response.smPoId || {});
        }
      })
      .catch((err) => {});
  };

  const onApprove = () => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SMALLWARE_GRN}${id}`,
      createUpdatePayloadMapper({
        ...grnDetails,
        remarks: rejectionDetails.comment
      })
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to update");
        } else {
          toast.success("Updated details successfully");
          loadData();
          navigate(-1);
        }
      })
      .catch((err) => {});
  };

  const onChange = (event, id) => {
    const updatedItems = receivedItems.map((item) =>
      item.smPoItemId === id
        ? { ...item, receivedQuantity: event.target.value }
        : item
    );
    setGRNDetails({ ...grnDetails, receivedItems: updatedItems });
  };

  return (
    <div className='grn-details-container'>
      <div className='grn-details-header'>
        <div className='left-container'>
          <div className='back-title'>
            <IconButton
              onClick={() => {
                navigate(-1);
                // navigate("/subway-finance?index=2");
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography className='title'>GRN Details</Typography>
          </div>
          <div className='grn-form-container'>
            <div className='info-section'>
              <div className='info-box'>
                <span>GRN Number </span>
                <span>{grnDetails.grnNo}</span>
              </div>
              <div className='info-box'>
                <span>PO Number </span>
                <span>{poDetails.poNumber}</span>
              </div>
              <div className='info-box'>
                <span>Site Code</span>
                <span>{site?.code || ""}</span>
              </div>
              <div className='info-box'>
                <span>Site Name </span>
                <span>{site?.name || ""}</span>
              </div>
              {/* <div className='info-box'>
                <span>PO Type </span>
                <span>
                  {convertToCamelCaseFromUnderScore(poDetails.poType)}
                </span>
              </div> */}
              <div className='info-box'>
                <span> PO Date </span>
                <span>
                  {formatDate(poDetails.poDate, DATE_FORMATS["DD-MM-YYYY"])}
                </span>
              </div>
              <div className='info-box'>
                <span> GRN Status </span>
                <span>
                  {convertToCamelCaseFromUnderScore(grnDetails.status)}
                </span>
              </div>
            </div>
          </div>
        </div>
        {grnDetails.status === "RECONSIDER" ? (
          <div className='right-section'>
            <Button
              variant='outlined'
              onClick={() => setRejectionDetails({ status: "APPROVED" })}
            >
              Resend for Approval
            </Button>
          </div>
        ) : null}
      </div>
      <div
        className={
          hasApproveAccessNow(grnDetails)
            ? "po-detail-approval-stage "
            : "po-detail-approval-stage"
        }
      >
        <div className='po-details'>
          <div className='form-container'>
            <div className='info-section'>
              <div className='info-box'>
                <span>Vendor Code </span>
                <span>{vendorCode || ""}</span>
              </div>
              <div className='info-box'>
                <span>Vendor Name </span>
                <span>{vendorName || ""}</span>
              </div>
              <div className='info-box'>
                <span>Vendor GST </span>
                <span>{vendorGst || ""}</span>
              </div>
              <div className='info-box'>
                <span>Vendor PAN Number</span>
                <span>{vendorPancard || ""}</span>
              </div>
              <div className='info-box'>
                <span> GRN Amount </span>
                <span>
                  {getGRNOrderAmount(items, receivedItems)}
                  {poDetails?.currency?.name || "INR"}
                </span>
              </div>
              <div className='info-box'>
                <span> GRN Tax Amount </span>
                <span>
                  {getGRNOrderTaxAmount(items, receivedItems)}
                  {poDetails?.currency?.name || "INR"}
                </span>
              </div>
              <div className='info-box'>
                <span> GRN Total Amount</span>
                <span>
                  {getGRNOrderAmountWithTax(items, receivedItems)}
                  {poDetails?.currency?.name || " INR"}
                </span>
              </div>
              <div className='info-box'>
                <span>Creator Name </span>
                <span>{(grnDetails && grnDetails.creatorName) || ""}</span>
              </div>
              <div className='info-box'>
                <span>
                  <TextField
                    type='date'
                    value={grnDetails.inwardDate}
                    InputLabelProps={{ shrink: true }}
                    label='Material/Service Received date'
                    name='inwardDate'
                    onChange={(e) => {
                      setGRNDetails({
                        ...grnDetails,
                        inwardDate: e.target.value
                      });
                    }}
                  />
                </span>
              </div>
              <div className='info-box'>
                <span>
                  <TextField
                    type='date'
                    value={grnDetails.invoiceDate}
                    InputLabelProps={{ shrink: true }}
                    label='Invoice Date'
                    name='invoiceDate'
                    onChange={(e) => {
                      setGRNDetails({
                        ...grnDetails,
                        invoiceDate: e.target.value
                      });
                    }}
                  />
                </span>
              </div>

              <div className='info-box'>
                <span>
                  <TextField
                    value={grnDetails.invoiceNumber || ""}
                    label='Invoice Number'
                    InputLabelProps={{ shrink: true }}
                    name='invoiceNumber'
                    onChange={(e) => {
                      setGRNDetails({
                        ...grnDetails,
                        invoiceNumber: e.target.value
                      });
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className='form-container'>
            <div className='info-section'>
              {grnDetails.attachment ? (
                <Chip
                  label={grnDetails.attachment}
                  onDelete={() =>
                    setGRNDetails({ ...grnDetails, attachment: null })
                  }
                  onClick={() => window.open(grnDetails.attachment, "_blank")}
                />
              ) : (
                <FileUpload
                  uploadText='Upload Documents'
                  clsName='add-additional-doc'
                  id='po-items-excel'
                  url={`${HOSTNAME}${FINANCE_REST_URLS.APP_DOCUMENT}`}
                  callBack={(response) => {
                    const { data } = response;
                    setGRNDetails({ ...grnDetails, attachment: data.url });
                  }}
                  formDataKey='upload'
                />
              )}
            </div>
          </div>
          <div className='form-container'>
            <MuiTable
              columnsList={getHeaderConfig(receivedItems, onChange)}
              dataList={
                items?.slice?.(
                  ((filters?.page || 1) - 1) * 10,
                  ((filters?.page || 1) - 1) * 10 + 10
                ) || []
              }
              pageCount={Math.ceil(items.length / 10)}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page
                });
              }}
            />
          </div>
        </div>

        <div className='approval-details'>
          <ApprovalStageDetails approvalDetails={grnDetails} />
        </div>
      </div>
      {rejectionDetails && (
        <CustomModal
          title='Resend for Approval'
          disabledOutsideClick={true}
          onClose={() => {
            setRejectionDetails(null);
          }}
        >
          <div className='b-modal-form-container'>
            <TextareaAutosize
              label='Comments'
              placeholder='Enter your comments here'
              value={rejectionDetails.comment}
              onChange={(e) =>
                setRejectionDetails({
                  ...rejectionDetails,
                  comment: e.target.value
                })
              }
              minRows={4}
              fullWidth
              style={{ width: "100%", marginTop: 10 }}
            />

            <Button
              variant='outlined'
              onClick={onApprove}
              fullWidth
              // disabled={!rejectionDetails.comment}
            >
              Resend for Approval
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default EditGRNDetails;
