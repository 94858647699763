import { Button, Checkbox, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadPO } from "./utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { checkIsApprover } from "../../../utils/utils";
import { convertToCamelCaseFromUnderScore } from "../../../../common-utilities/utils";
import {
  isApprovedAllowed,
  isGRNCreateAllowed
} from "../../../utils/common-utils";
import { getOfflineData } from "../../../utils/offline-services";

export const getHeaderConfig = (
  onActionClick,
  filters,
  setFilters,
  status,
  loadData,
  bulkSelectIds,
  setBulkSelectIds
) => {
  const userDetails = getOfflineData("user") || {};
  const role = userDetails?.role?.toUpperCase() || "";
  const headers = [
    {
      label: "PO Number",
      id: "poNumber",
      headerRender: () => {
        return (
          <div className='header-column-arrow'>
            PO Number
            {filters?.sortBy === "-createdAt" ? (
              <KeyboardArrowDownIcon
                onClick={() => {
                  loadData({ ...filters, page: 1, sortBy: "createdAt" });
                  setProperty("sortBy", "createdAt", setFilters);
                }}
                className='download-icon'
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={() => {
                  loadData({ ...filters, page: 1, sortBy: "-createdAt" });
                  setProperty("sortBy", "-createdAt", setFilters);
                }}
                className='download-icon'
              />
            )}
          </div>
        );
      }
    },
    {
      label: "Site Code & Name",
      id: "siteCode",
      render: (data) => {
        const { site = {} } = data;
        return (site && `${site.code} - ${site.name}`) || "";
      }
    },
    {
      label: "Vendor code",
      id: "vendorCode",
      render: (data) => {
        const { vendorCode = "" } = data;
        return vendorCode;
      }
    },
    {
      label: "Vendor Name",
      id: "vendorName",
      render: (data) => {
        const { vendorName = "" } = data;
        return vendorName;
      }
    },
    {
      label: "Base Amt (₹)",
      id: "amount",
      render: (data) => {
        return Math.round(data.amount).toFixed(2);
      }
    },
    {
      label: "GST Amt (₹)",
      id: "taxAmount",
      render: (data) => {
        return Math.round(data.taxAmount).toFixed(2);
      }
    },
    {
      label: "Total Amt (₹)",
      id: "totalAmount",
      render: (data) => {
        return Math.round(data.totalAmount).toFixed(2);
      }
    }
  ];

  if (status === "all" || status === "APPROVAL_IN_PROGRESS") {
    headers.push(
      {
        // label: "Pending With",
        label: "Approver",
        id: "approverName"
      },
      {
        label: "Status",
        id: "status",
        render: (data) => {
          return convertToCamelCaseFromUnderScore(data.status);
        }
      }
    );
  }

  if (status === "APPROVAL_IN_PROGRESS" && isApprovedAllowed()) {
    headers.unshift({
      label: "",
      headerRender: () => {
        return (
          <Checkbox
            color='primary'
            checked={bulkSelectIds.includes("all")}
            onChange={() => {
              if (bulkSelectIds.includes("all")) {
                setBulkSelectIds(bulkSelectIds?.filter((id) => id !== "all"));
              } else {
                setBulkSelectIds(["all"]);
              }
            }}
          />
        );
      },
      id: "checknox",
      render: (data) => {
        return (
          <>
            <Checkbox
              checked={
                checkIsApprover(data)
                  ? bulkSelectIds.includes(data.id) ||
                    bulkSelectIds.includes("all")
                  : false
              }
              disabled={!checkIsApprover(data)}
              color='primary'
              onChange={() => {
                if (bulkSelectIds.includes(data.id)) {
                  setBulkSelectIds(
                    bulkSelectIds?.filter((id) => id !== data.id)
                  );
                } else {
                  setBulkSelectIds([data.id, ...bulkSelectIds]);
                }
              }}
            />
          </>
        );
      }
    });
  }

  headers.push(
    {
      label: "Created By",
      id: "creatorName"
    },
    {
      label: "View",
      id: "view",
      render: (data) => {
        return (
          <>
            <div className='po-action-btns'>
              {status === "APPROVED" && isGRNCreateAllowed(role) && (
                <Button
                  variant='text'
                  onClick={() => onActionClick(data, "GRN")}
                >
                  Crete GRN
                </Button>
              )}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  downloadPO(data);
                }}
              >
                <DownloadIcon
                  color='primary'
                  className='download-icon'
                  fontSize='medium'
                />
              </IconButton>

              <Button
                variant='text'
                onClick={() => onActionClick(data, "VIEW")}
              >
                View
              </Button>
            </div>
          </>
        );
      }
    }
  );

  return headers;
};
