import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../../routes/endpoints";

const intranetService = `/v1/central-service/`;
const technetService = "/v1/tech-support-service/";

export const REST_URLS = {
  LIST_STORES: "/v1/tech-support-service/admin/store",
  REFRESH_TOKEN: `${intranetService}/auth/refresh-tokens-app`,
  LIST_ALL_STORE_NAMES: "/v1/tech-support-service/admin/store/all-names",
  CREATE_STORE: "/v1/tech-support-service/admin/store",
  UPDATE_STORE: "/v1/tech-support-service/admin/store/",
  LIST_DEPARTMENT: "/v1/tech-support-service/admin/department",
  LIST_ALL_DEPARTMENT_NAMES:
    "/v1/tech-support-service/admin/department/all-names",
  LIST_ALL_DEPARTMENTs_STOREWISE:
    "/v1/tech-support-service/admin/department/storewise/",
  CREATE_DEPARTMENT: "/v1/tech-support-service/admin/department",
  UPDATE_DEPARTMENT: "/v1/tech-support-service/admin/department/",
  GET_USERS: "/v1/tech-support-service/users",
  GET_USERS_WITH_FILTER: "/v1/tech-support-service/users/all-users-with-filter",
  CREATE_USER: "/v1/tech-support-service/users",
  UPDATE_USER: "/v1/tech-support-service/users/",
  LOGIN: "/v1/tech-support-service/auth/login",
  TICKETS: "/v1/tech-support-service/tickets/list",
  TICKET: "/v1/tech-support-service/tickets/view",
  COMMENT: "/v1/tech-support-service/tickets/add-comment",
  UPDATETICKET: "/v1/tech-support-service/tickets/update-ticket",
  CREATECOMPLAINT: "/v1/tech-support-service/complaints",
  UPLOAD_FILES: "/v1/tech-support-service/miscellaneous/upload",
  TICKETCOMPLAINTDETAILS: "/v1/tech-support-service/tickets/complaint-Form",
  LIST_CATEGORY: "/v1/tech-support-service/admin/ticket-category",
  LIST_CATEGORY_DEPARTMENT_WISE:
    "/v1/tech-support-service/admin/ticket-category/dept/",
  LIST_ALL_CATEGORY_NAMES:
    "/v1/tech-support-service/admin/ticket-category/all-names",
  CREATE_CATEGORY: "/v1/tech-support-service/admin/ticket-category",
  UPDATE_CATEGORY: "/v1/tech-support-service/admin/ticket-category/",
  ADMINANDTECHNETUSERSLIST:
    "/v1/tech-support-service/users/alltechnetandadminusers",
  REASSIGNENGINEER: "/v1/tech-support-service/tickets/reassignEnginner",
  DASHBOARD: "/v1/tech-support-service/tickets/dashboard/base-counts",
  TICKET_REPORTS: "/v1/tech-support-service/tickets/reports/by-status",
  SLA_REPORTS: "/v1/tech-support-service/tickets/reports/by-sla",
  STORE_REPORTS: "/v1/tech-support-service/tickets/reports/by-store",
  AGENT_PRODUCTIVITY_REPORT:
    "/v1/tech-support-service/tickets/reports/by-agent-productivity",
  TICKET_CATEGORY_WITHOUT_PARENT:
    "/v1/tech-support-service/admin/ticket-category-without-parent",
  UPLOAD_ATTACHMENT_FILES:
    "/v1/tech-support-service/tickets/upload/attachment/",
  BLOG_CATEGORIES: "/v1/docu-service/blog/all-blog-categories",
  GET_BLOGS: "/v1/docu-service/blog/get-blogs",
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  SEARCH_BY_USER_NAME: `${intranetService}users/search`,
  ALLOW_TICKET_CREATION: `/v1/tech-support-service/admin/allow-ticket-creation`,
  GET_ALL_STORES: `${intranetService}stores`,
  DOCUMENT_UPLOAD: `${technetService}tickets/upload-attachment`,
  GET_SALES_DATA: `${technetService}sales-integration/sales-data`,
  CREATE_EXCEPTION: `${technetService}sales-integration/exception`,
  GET_STORE_LIST: `${technetService}sales-integration/list-stores`,
  GET_TAG_LIST: `${technetService}ticket-tag/list`,
  TAGS: `${technetService}ticket-tag`,
  REGION_USER_MASTER: `${technetService}rwasg`,
  GET_CLIENT_SECRET: `${technetService}external-ticket/client-secret`,
  UPDATE_CLIENT_SECRET: `${technetService}external-ticket/secret`,
  ADD_CLIENT_SECRET: `${technetService}external-ticket/add-secret`,
  AM_STORE_USER_LIST: `${technetService}tickets/get-store-users`,
};

export const technetEndPoint = getProjectEndPoint(endPointsForProject.technet);
