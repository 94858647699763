import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import {
  ALPHA_NUMERIC,
  GST_STATUS,
  formatPayload,
  isFormValid,
} from "./helper";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import {
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { VENDOR_ONBOARD_REST_URLS } from "../../../common-utilities/utils/end-points/vendor-onboard";
import { toast } from "react-toastify";
import { ArrowBack, CloseOutlined, RemoveRedEye } from "@mui/icons-material";
import SiFileUpload from "../../../invoice-tracking/core/file-uploader";
import CustomRadioGroup from "../../../common-utilities/core/radio-group";
import {
  CREDIT_PERIOD,
  INDIA_STATE_LIST,
  TYPE_OF_MSME,
  TYPE_OF_VENDOR,
  VENDOR_TENURE,
} from "../../../common-utilities/utils/constants";
import { VENDOR_DOCUMENT_TYPES } from "../vendor-request-details/helper";
import { APPROVED_BANK_LIST } from "../vendor-onboard-form/helper";

const VendorOnboardEditForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [panCard, setPanCard] = useState("");
  const [gstDocument, setGSTDocument] = useState("");
  const [msmeDocument, setMSMEDocument] = useState("");
  const [departments, setDepartments] = useState([]);
  const [attachment, setAttachment] = useState("");
  const [gstDeclaration, setGstDeclaration] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [stateInputValue, setStateInputValue] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (typeof formDetails.department === "number") {
      updateDepartment(formDetails);
    }
  }, [formDetails]);

  const updateDepartment = (details) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${VENDOR_ONBOARD_REST_URLS.GET_DEPARTMENTS}`
    )
      .then((response) => {
        if (response && response.length > 0) {
          const formatted = response.map((d) => {
            return { label: d.name, value: d._id };
          });
          setDepartments(formatted);
          setFormDetails({
            ...details,
            department: formatted.find((d) => d.value === details.department),
          });
          setInputValue(details.bankName);
        } else {
          toast.error(response.message || "Failed to send request");
        }
      })
      .catch((err) => {});
  };

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_ONBOARD}/form/${id}`,
      null
    )
      .then((response) => {
        if (response.id) {
          const {
            address = {},
            bankDetails = {},
            attachments = [],
            gst,
          } = response;
          const { state = "" } = address;
          const { bankName = "" } = bankDetails;
          setFormDetails({
            ...response,
            ...address,
            ...bankDetails,
            state: INDIA_STATE_LIST.find((a) => a.label === state) || "",
            bankName: bankName,
            gst: (gst && gst?.slice(gst?.length - 3, gst?.length)) || "",
          });
          setStateInputValue(state);
          if (attachments.length > 0) {
            setGSTDocument(
              getAttachment(attachments, VENDOR_DOCUMENT_TYPES.GST_DOC)
            );
            setGstDeclaration(
              getAttachment(attachments, VENDOR_DOCUMENT_TYPES.GST_DECLARATION)
            );
            setPanCard(
              getAttachment(attachments, VENDOR_DOCUMENT_TYPES.PAN_CARD)
            );
            setMSMEDocument(
              getAttachment(attachments, VENDOR_DOCUMENT_TYPES.MSME_DOC)
            );
            setAttachment(
              getAttachment(attachments, VENDOR_DOCUMENT_TYPES.ATTACHMENT)
            );
          }
        }
      })
      .catch((err) => {});
  };

  const getAttachment = (attachments, type) => {
    const doc = attachments.find((a) => a.type === type);
    if (doc) {
      return doc.url;
    } else {
      return "";
    }
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onMobileInputChange = (event) => {
    if (event.target.value.length > 10) {
      return;
    }
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onGSTChange = (event) => {
    const gstText = `${(formDetails.state && formDetails.state.code) || ""}${
      formDetails.pan || ""
    }`;
    if (gstText.length < 12) {
      return;
    }
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onInputChangeAlphaNumeric = (event) => {
    if (!event.target.value.match(ALPHA_NUMERIC)) {
      return;
    }

    setFormDetails({
      ...formDetails,

      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onSubmit = () => {
    let errors = isFormValid({
      ...formDetails,
      panCard,
      msmeDocument,
      gstDocument,
      gstDeclaration,
    });
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    let payload = formatPayload({
      ...formDetails,
      panCard,
      msmeDocument,
      gstDocument,
      attachment,
      gstDeclaration,
    });

    invokeApi(
      HTTP_METHODS.PUT,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_ONBOARD}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response.id) {
          toast.info("Details updated");
        } else {
          toast.error(response.message || "Failed to send request");
        }
      })
      .catch((err) => {});
  };

  const uploadAttachments = (payload) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.ADD_ATTACHMENTS_TO_FORM}/${id}`,
      payload
    )
      .then((response) => {
        if (response.message === "Updated") {
          if (payload.type === VENDOR_DOCUMENT_TYPES.PAN_CARD) {
            setPanCard(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.GST_DOC) {
            setGSTDocument(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.GST_DECLARATION) {
            setGstDeclaration(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.MSME_DOC) {
            setMSMEDocument(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.ATTACHMENT) {
            setAttachment(payload.url);
          }
          if (payload.type === VENDOR_DOCUMENT_TYPES.CANCEL_CHEQUE) {
            setFormDetails({
              ...formDetails,
              cancelledCheque: payload.url,
            });
          }
        } else {
          toast.error("Failed to add attachment");
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="vendor-onboard-form-edit-container">
      <div className="header-section">
        <div>
          <IconButton
            variant="contained"
            onClick={() => navigate("/vendor-onboard")}
          >
            <ArrowBack />
          </IconButton>
          <span className="title">Vendor Form Details </span>
        </div>
      </div>
      <div className="vendor-onboard-form-details">
        <Card>
          <CardContent>
            <div className="form-row">
              <TextField
                label="Vendor Name *"
                name="nameOfLegalEntity"
                size="small"
                fullWidth
                value={formDetails.nameOfLegalEntity || ""}
                onChange={onInputChange}
                error={formErrors.nameOfLegalEntity}
                helperText={formErrors.nameOfLegalEntity ? "Required" : ""}
              />
              <TextField
                label="Address Line 1 *"
                name="line"
                size="small"
                fullWidth
                value={formDetails.line || ""}
                onChange={onInputChange}
                error={formErrors.line}
                helperText={formErrors.line ? "Required" : ""}
              />
              <TextField
                label="Address Line 2"
                name="street"
                size="small"
                fullWidth
                value={formDetails.street || ""}
                onChange={onInputChange}
              />
              <TextField
                label="PIN No *"
                name="pincode"
                size="small"
                type="number"
                fullWidth
                value={formDetails.pincode || ""}
                onChange={onInputChange}
                error={formErrors.pincode}
                helperText={
                  formErrors.pincode
                    ? "Required,Not allowed special characters(!,@,#,$,%...)"
                    : ""
                }
              />
              <TextField
                label="City *"
                name="city"
                size="small"
                fullWidth
                value={formDetails.city || ""}
                onChange={onInputChangeAlphaNumeric}
                error={formErrors.city}
                helperText={
                  formErrors.city
                    ? "Required,Not allowed special characters(!,@,#,$,%...)"
                    : ""
                }
              />

              <Autocomplete
                options={INDIA_STATE_LIST}
                className="form-autocomplete"
                size="small"
                value={formDetails.state || {}}
                inputValue={stateInputValue}
                onInputChange={(event, newInputValue) => {
                  if (event) {
                    setStateInputValue(newInputValue);
                  }
                }}
                onChange={(event, values) => {
                  onInputChange({
                    target: {
                      value: values || "",
                      name: "state",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="State *"
                    error={formErrors.city}
                    helperText={formErrors.city ? "Required" : ""}
                  />
                )}
              />
              <TextField
                label="Country *"
                name="country"
                size="small"
                fullWidth
                value={formDetails.country || ""}
                onChange={onInputChangeAlphaNumeric}
                error={formErrors.country}
                helperText={
                  formErrors.country
                    ? "Required,Not allowed special characters(!,@,#,$,%...)"
                    : ""
                }
              />

              <TextField
                label="PAN No *"
                name="pan"
                size="small"
                fullWidth
                value={formDetails.pan || ""}
                onChange={onInputChange}
                error={formErrors.pan}
                helperText={formErrors.pan ? "Format should be ABCDE1234A" : ""}
              />
              <TextField
                label="Contact Person *"
                name="focalPointOfContact"
                size="small"
                fullWidth
                value={formDetails.focalPointOfContact || ""}
                onChange={onInputChangeAlphaNumeric}
                error={formErrors.focalPointOfContact}
                helperText={formErrors.focalPointOfContact ? "Required" : ""}
              />
              <TextField
                label="Mobile Number *"
                name="mobile"
                size="small"
                type="number"
                fullWidth
                value={formDetails.mobile || ""}
                onChange={onMobileInputChange}
                error={formErrors.mobile}
                helperText={
                  formErrors.mobile
                    ? "Mobile  should be number and equals to 10"
                    : ""
                }
              />
              <TextField
                label="Alternative Number"
                name="alternativeMobile"
                size="small"
                type="number"
                fullWidth
                value={formDetails.alternativeMobile || ""}
                onChange={onInputChange}
                error={formErrors.alternativeMobile}
                helperText={
                  formErrors.alternativeMobile
                    ? "Mobile  should be number and equals to 10"
                    : ""
                }
              />
              <TextField
                label="Email *"
                name="email"
                size="small"
                fullWidth
                value={formDetails.email || ""}
                onChange={onInputChange}
                error={formErrors.email}
                helperText="email should be example@example.com"
              />
            </div>
            <div className="form-row">
              <Autocomplete
                options={TYPE_OF_VENDOR}
                className="form-autocomplete"
                value={formDetails.typeOfVendor || ""}
                inputValue={formDetails.typeOfVendor || ""}
                size="small"
                onChange={(event, values) => {
                  onInputChange({
                    target: {
                      value: values || "",
                      name: "typeOfVendor",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Type Of Vendor"
                    error={formErrors.typeOfVendor}
                    helperText={formErrors.typeOfVendor ? "Required" : ""}
                  />
                )}
              />
              <Autocomplete
                options={departments}
                className="form-autocomplete"
                value={formDetails.department || {}}
                disableClearable={true}
                inputValue={
                  (formDetails.department && formDetails.department.label) || ""
                }
                size="small"
                onChange={(event, values) => {
                  onInputChange({
                    target: {
                      value: values || "",
                      name: "department",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Department *"
                    error={formErrors.department}
                    helperText={formErrors.department ? "Required" : ""}
                  />
                )}
              />

              <Autocomplete
                options={CREDIT_PERIOD}
                className="form-autocomplete"
                size="small"
                value={formDetails.creditPeriod || ""}
                inputValue={formDetails.creditPeriod || ""}
                onChange={(event, values) => {
                  onInputChange({
                    target: {
                      value: values || "",
                      name: "creditPeriod",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Credit Period *"
                    error={formErrors.creditPeriod}
                    helperText={formErrors.creditPeriod ? "Required" : ""}
                  />
                )}
              />

              <Autocomplete
                options={VENDOR_TENURE}
                className="form-autocomplete"
                size="small"
                value={formDetails.vendorTenure || ""}
                inputValue={formDetails.vendorTenure || ""}
                onChange={(event, values) => {
                  onInputChange({
                    target: {
                      value: values || "",
                      name: "vendorTenure",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Vendor Tenure *"
                    error={formErrors.vendorTenure}
                    helperText={formErrors.vendorTenure ? "Required" : ""}
                  />
                )}
              />

              <TextField
                label="Nature of transaction in detail *"
                name="natureTransaction"
                size="small"
                fullWidth
                value={formDetails.natureTransaction || ""}
                onChange={onInputChange}
                error={formErrors.natureTransaction}
                helperText={
                  formErrors.natureTransaction
                    ? "Minimum character should be 3, Otherwise wright NA."
                    : ""
                }
              />
            </div>

            <div className="form-row gst-status">
              <CustomRadioGroup
                title="GST Status"
                options={GST_STATUS}
                value={formDetails.gstStatus || ""}
                onChange={(event) => {
                  if (
                    formDetails.state &&
                    formDetails.state.code &&
                    formDetails.pan &&
                    formDetails.pan.length === 10
                  ) {
                    if (event.target.value === "Yes") {
                      setGstDeclaration("");
                      setFormDetails({
                        ...formDetails,
                        gstStatus: event.target.value,
                      });
                    } else {
                      setFormDetails({
                        ...formDetails,
                        gstStatus: event.target.value,
                        gst: "",
                      });
                      setGSTDocument("");
                    }
                  } else {
                    toast.error(
                      "Please state and enter valid PAN details to add GST"
                    );
                  }
                }}
              />
              {formDetails.gstStatus === "Yes" && (
                <TextField
                  label="GST NO"
                  name="gst"
                  size="small"
                  fullWidth
                  value={formDetails.gst || ""}
                  onChange={onGSTChange}
                  error={formErrors.gst}
                  helperText="Please enter last 3 digits"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {`${
                          (formDetails.state && formDetails.state.code) || ""
                        }${formDetails.pan || ""}`}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {formDetails.gstStatus === "Yes" && (
                <div>
                  {gstDocument ? (
                    <div>
                      <span>GST Document</span>
                      <IconButton onClick={() => setGSTDocument("")}>
                        <CloseOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          window.open(gstDocument, "_blank").focus()
                        }
                      >
                        <RemoveRedEye color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <SiFileUpload
                      id="gst-doc"
                      title="GST Document*"
                      callBack={(value) =>
                        uploadAttachments({
                          type: VENDOR_DOCUMENT_TYPES.GST_DOC,
                          url: value[0].url,
                        })
                      }
                    />
                  )}
                  <span style={{ color: "red" }}>
                    {formErrors.gstDoc ? "GST Document Required" : ""}
                  </span>
                </div>
              )}
              {formDetails.gstStatus === "No" && (
                <div>
                  {gstDeclaration ? (
                    <div>
                      <span>GST Declaration</span>
                      <IconButton onClick={() => setGstDeclaration("")}>
                        <CloseOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          window.open(gstDeclaration, "_blank").focus()
                        }
                      >
                        <RemoveRedEye color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <SiFileUpload
                      id="gst-declaration"
                      title="GST Declaration*"
                      callBack={(value) => {
                        uploadAttachments({
                          type: VENDOR_DOCUMENT_TYPES.GST_DECLARATION,
                          url: value[0].url,
                        });
                        delete formErrors?.gstDeclaration;
                      }}
                    />
                  )}
                  <span style={{ color: "red" }}>
                    {formErrors.gstDeclaration
                      ? "GST Declaration Required"
                      : ""}
                  </span>
                </div>
              )}
            </div>
            <div className="form-row msme-status">
              <CustomRadioGroup
                title="MSME available"
                options={GST_STATUS}
                value={formDetails.msme || ""}
                onChange={(event) => {
                  if (event.target.value === "Yes") {
                    setFormDetails({
                      ...formDetails,
                      msme: event.target.value,
                    });
                  } else {
                    setFormDetails({
                      ...formDetails,
                      msme: event.target.value,
                      msmeNumber: "",
                      msmeType: "",
                    });
                    setMSMEDocument("");
                  }
                }}
              />
              {formDetails.msme === "Yes" && (
                <TextField
                  label="MSME NO"
                  name="msmeNumber"
                  size="small"
                  fullWidth
                  value={formDetails.msmeNumber || ""}
                  onChange={onInputChange}
                  error={formErrors.msmeNumber}
                  helperText={formErrors.msmeNumber ? "Required" : ""}
                />
              )}
              {formDetails.msme === "Yes" && (
                <Autocomplete
                  options={TYPE_OF_MSME}
                  className="form-autocomplete"
                  value={formDetails.msmeType || ""}
                  inputValue={formDetails.msmeType || ""}
                  size="small"
                  onChange={(event, values) => {
                    onInputChange({
                      target: {
                        value: values || "",
                        name: "msmeType",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="MSME Type"
                      error={formErrors.msmeType}
                      helperText={formErrors.msmeType ? "Required" : ""}
                    />
                  )}
                />
              )}
              {formDetails.msme === "Yes" && (
                <div>
                  {msmeDocument ? (
                    <div>
                      <span>MSME Document*</span>
                      <IconButton onClick={() => setMSMEDocument("")}>
                        <CloseOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          window.open(msmeDocument, "_blank").focus()
                        }
                      >
                        <RemoveRedEye color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <SiFileUpload
                      id="gst-doc"
                      title="MSME Document"
                      callBack={(value) =>
                        uploadAttachments({
                          type: VENDOR_DOCUMENT_TYPES.MSME_DOC,
                          url: value[0].url,
                        })
                      }
                    />
                  )}
                  <span style={{ color: "red" }}>
                    {formErrors.msmeDocument ? "MSME Document Required" : ""}
                  </span>
                </div>
              )}
            </div>
            <div className="sub-heading">Bank Details</div>
            <div className="form-row">
              <Autocomplete
                options={APPROVED_BANK_LIST.map((b) => b.value)}
                className="form-autocomplete"
                size="small"
                value={formDetails.bankName}
                inputValue={inputValue || ""}
                onInputChange={(event, newInputValue) => {
                  if (event) {
                    setInputValue(newInputValue);
                  }
                }}
                onChange={(event, values) => {
                  onInputChange({
                    target: {
                      value: values || "",
                      name: "bankName",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Bank Name *"
                    error={formErrors.bankName}
                    helperText={formErrors.bankName ? "Required" : ""}
                  />
                )}
              />
              <TextField
                label="Account Number *"
                name="accountNumber"
                size="small"
                fullWidth
                value={formDetails.accountNumber || ""}
                onChange={onInputChangeAlphaNumeric}
              />
              <TextField
                label="IFSC code *"
                name="ifsc"
                size="small"
                fullWidth
                value={formDetails.ifsc || ""}
                onChange={(e) => {
                  if (e?.target?.value?.length > 11) {
                    return;
                  }
                  onInputChange(e);
                }}
                error={formErrors.ifsc}
                helperText={
                  formErrors.ifsc ? "Should be 11 digit and alpha numeric" : ""
                }
              />
            </div>
            <div className="form-row">
              <div>
                {formDetails.cancelledCheque ? (
                  <div>
                    <span>Cancelled Cheque</span>
                    <IconButton
                      onClick={() =>
                        setFormDetails({
                          ...formDetails,
                          cancelledCheque: "",
                        })
                      }
                    >
                      <CloseOutlined color="primary" />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        window
                          .open(formDetails.cancelledCheque, "_blank")
                          .focus()
                      }
                    >
                      <RemoveRedEye color="primary" />
                    </IconButton>
                  </div>
                ) : (
                  <SiFileUpload
                    id="cancelled-cheque"
                    title="Cancelled Cheque*"
                    callBack={(value) =>
                      uploadAttachments({
                        type: VENDOR_DOCUMENT_TYPES.CANCEL_CHEQUE,
                        url: value[0].url,
                      })
                    }
                  />
                )}
              </div>
              <div>
                {panCard ? (
                  <div>
                    <span>Pan Card</span>
                    <IconButton onClick={() => setPanCard("")}>
                      <CloseOutlined color="primary" />
                    </IconButton>
                    <IconButton
                      onClick={() => window.open(panCard, "_blank").focus()}
                    >
                      <RemoveRedEye color="primary" />
                    </IconButton>
                  </div>
                ) : (
                  <SiFileUpload
                    id="pan-card"
                    title="PAN card*"
                    callBack={(value) =>
                      uploadAttachments({
                        type: VENDOR_DOCUMENT_TYPES.PAN_CARD,
                        url: value[0].url,
                      })
                    }
                  />
                )}
              </div>

              <div>
                {attachment ? (
                  <div>
                    <span>Attachment</span>
                    <IconButton onClick={() => setAttachment("")}>
                      <CloseOutlined color="primary" />
                    </IconButton>
                    <IconButton
                      onClick={() => window.open(attachment, "_blank").focus()}
                    >
                      <RemoveRedEye color="primary" />
                    </IconButton>
                  </div>
                ) : (
                  <SiFileUpload
                    id="attachment"
                    title="Attachment"
                    callBack={(value) =>
                      uploadAttachments({
                        type: VENDOR_DOCUMENT_TYPES.ATTACHMENT,
                        url: value[0].url,
                      })
                    }
                  />
                )}
              </div>
            </div>

            <div className="footer-buttons">
              <Button variant="contained" onClick={onSubmit}>
                Update Details
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default VendorOnboardEditForm;
