import React from "react";
import CustomModal from "../../../../common-utilities/core/modal";
import { Button, Box, Typography, TextareaAutosize } from "@mui/material";

const ConfirmApprove = ({
  bulkApprove,
  approvedPODetails,
  setIsConfirmModalOpen
}) => {
  const [comments, setComments] = React.useState("");
  return (
    <div>
      <CustomModal
        title='Confirmation'
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <Typography>
          Number of PO's : {approvedPODetails.totalResults}
        </Typography>
        <Typography>
          Total approval amount : ₹{approvedPODetails.totalSum}
        </Typography>
        <TextareaAutosize
          label='Comments'
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          minRows={4}
          fullWidth
          style={{ width: "100%", marginTop: 10 }}
        />
        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button variant='outlined' onClick={() => bulkApprove(comments)}>
            Submit
          </Button>
        </Box>
      </CustomModal>
    </div>
  );
};

export default ConfirmApprove;
